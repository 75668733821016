import React from 'react';
import classnames from 'classnames/bind';
import styles from './passwordValidation.module.scss';
const bStyles = classnames.bind(styles);
export default function PasswordValidation(props) {
  const { isNewPassword, newPassword, confirmPassword } = props;
  return (
    <div className={styles.pwRequirements}>
      <ul>
        {isNewPassword ? (
          <>
            {' '}
            <li
              className={bStyles({
                passes: /[A-Z]/.test(newPassword) && newPassword?.length
              })}
            >
              One capital letter
            </li>
            <li
              className={bStyles({
                passes: /[a-z]/.test(newPassword) && newPassword?.length
              })}
            >
              One lowercase letter
            </li>
            <li
              className={bStyles({
                passes: /\d/.test(newPassword) && newPassword?.length
              })}
            >
              One number
            </li>
            <li
              className={bStyles({
                passes: /[!@#$%^]/.test(newPassword) && newPassword?.length
              })}
            >
              One special character: !@$%*#
            </li>
            <li
              className={bStyles({
                passes: newPassword?.length > 7 && !newPassword.includes(' ')
              })}
            >
              Be at least 8 characters long and contain no spaces
            </li>
          </>
        ) : (
          <li
            className={bStyles({
              passes: newPassword?.length && newPassword === confirmPassword
            })}
          >
            Passwords must match
          </li>
        )}
      </ul>
    </div>
  );
}
