import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchEmployee,
  createClaim,
  updateClaim,
  deleteClaim,
  fetchClaimById,
  printClaim,
  getLocationCodes
} from '../../../api/v4';
import config from '../../../config/config';
import history from '../../../history';
import { setActiveIncidentRequest } from '../../../actions/incidents';
import { getActiveIncidentSelector } from '../../../selectors/incidents';
import { getActiveOshaLogSelector } from '../../../selectors/oshaLogs';
import { getMyUserId, getAllUsers } from '../../../selectors/users';
import { useActiveHeirarchy } from '../../../utils/useActiveHeirarchy';
import customToast from '../../../utils/customToast';
import { normalizePhone } from '../../../utils/formValidation';
import {
  TYPE_OF_MEDICAL_TREATMENT,
  EMPLOYEMENT_STATUS
} from '../../../constants/travelerMapping';
import ClaimCard from '../../../components/claims/Cards/travelers';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import InstructionCard from '../../../components/claims/InstructionCard';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';
import Modal from '../../../components/Modal';
import LeftNav from '../../../components/LeftNav';
import { SubmitSaveForLaterCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';

import styles from '../claimCreate.module.scss';

export default function TravelersWcClaim() {
  const activeIncident = useSelector(getActiveIncidentSelector);
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const activeOshaLog = useSelector(getActiveOshaLogSelector);
  const myUserId = useSelector(getMyUserId);
  const users = useSelector(getAllUsers);
  const employeeId =
    activeIncident?.answers?.[config.staticIds.froiInjuredEmployeeQuestion];
  let { claimId } = useParams();
  const dispatch = useDispatch();

  const [id, setId] = useState(null);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [injuryInfo, setInjuryInfo] = useState({});
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [accidentInfo, setAccidentInfo] = useState({});
  const [oshaInfo, setOshaInfo] = useState([]);
  const [witnessInfo, setWitnessInfo] = useState([]);
  const [overviewInfo, setOverviewInfo] = useState({});
  const [creating, setCreating] = useState(true);
  const [employeeEditing, setEmployeeEditing] = useState(false);
  const [injuryEditing, setInjuryEditing] = useState(false);
  const [accidentEditing, setAccidentEditing] = useState(false);
  const [oshaEditing, setOshaEditing] = useState(false);
  const [witnessEditing, setWitnessEditing] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveChangesModal, setSaveChangesModal] = useState(false);
  const [sumbitModal, setSubmitModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [incidentId, setIncidentId] = useState();
  const [locationCodes, setLocationCodes] = useState();

  useEffect(() => {
    if (!claimId) {
      setCreating(true);
      fetchEmployee(employeeId[0]?.value ?? employeeId[0]).then(employee => {
        setEmployeeInfo({
          employeeId: employeeId,
          employeeFirstName: employee?.firstName,
          employeeLastName: employee?.lastName,
          employeeGender: employee?.gender,
          employeePhone: normalizePhone(employee?.phoneNumber),
          employeeEmail: employee.email,
          streetAddress: employee?.address?.streetAddress,
          city: employee?.address?.city,
          state: employee?.address?.stateName,
          zip: employee?.address?.zip,
          employeeOccupation: employee?.jobTitle,
          employeeDateOfHire: employee?.dateOfHire,
          employeeDateOfBirth: employee?.dateOfBirth,
          supervisorId: answers[config.staticIds.incidentSupervisor]?.[0],
          employmentStatus: EMPLOYEMENT_STATUS.find(
            e => e.ireportOption === employee.status
          )?.value
        });
      });

      let oshaAnswers = activeOshaLog[0]?.questions?.reverse();
      const answers = activeIncident?.answers;
      if (answers) {
        let incidentLocation =
          answers[config.staticIds.incidentLocation] &&
          !answers?.[config.staticIds.incidentLocation].includes(
            'Not Applicable'
          )
            ? answers?.[config.staticIds.incidentLocation]?.split(', ')
            : '';

        if (!incidentLocation) {
          const location = activeCompany.groups.find(
            g => g._id === activeIncident.groupId
          );
          incidentLocation = [
            location.streetAddress,
            location.city,
            location.state,
            location.zip
          ];
        }

        const witnesses = answers[config.staticIds.addtionalWitnesses];
        if (
          witnesses?.length &&
          !answers?.[config.staticIds.addtionalWitnesses].includes(
            'Not Applicable'
          )
        ) {
          const emps = users.filter(u => witnesses.includes(u._id));
          setWitnessInfo({
            areThereWitnesses: 'yes',
            numberOfThirdPartyPedestriansInvolved: parseInt(
              witnesses.length,
              10
            ),
            thirdPartyPedestrians: emps.map(e => ({
              firstName: e.firstName,
              lastName: e.lastName,
              phoneNumber: e.phoneNumber
            }))
          });
        } else {
          setWitnessInfo({ areThereWitnesses: 'no' });
        }

        let isRecordable;
        let isPrivacy;
        let timeStartedWork;
        let activityDesc;
        let locationDesc;
        if (oshaAnswers) {
          isRecordable = oshaAnswers.find(o => o.key === 'isNotApplicable');
          isPrivacy = oshaAnswers.find(o => o.key === 'privacyConcern');
          timeStartedWork = oshaAnswers.find(
            o => o.key === 'timeOfDayWorkStarted'
          )?.value;
          activityDesc = oshaAnswers.find(
            o => o.key === 'howInjuryOccurred'
          )?.value;
          locationDesc = oshaAnswers.find(o => o.key === 'description')?.value;
        }

        setAccidentInfo({
          incidentDate: answers[config.staticIds.timeIncidentOccured],
          streetAddress: incidentLocation?.[0],
          city: incidentLocation?.[1],
          state: incidentLocation?.[2],
          zip: incidentLocation?.[3] === 'USA' ? null : incidentLocation?.[3],
          groupId: activeIncident.groupId,
          projectId: activeIncident.projectId,
          claimNotificationOther: activeCompany.defaultClaimResponsibility,
          incidentDescription: answers[config.staticIds.incidentDescription]
        });

        setInjuryInfo({
          typeOfMedicalTreatment: mapIreportTreatmentToTravelerTreatment(
            answers[config.staticIds.froiTypeOfMedicalTreatment]
          ),
          returnToWorkDuties: 'NONFATAL'
        });

        setOshaInfo({
          isOshaRecordable: isRecordable ? 'Y' : null,
          isPrivacyCase: isPrivacy ? 'Y' : null,
          oshaTimeWorkerStarted: timeStartedWork,
          oshaActivityDesc: activityDesc,
          oshaLocationDesc: locationDesc
        });
      }
    } else {
      fetchClaimById(claimId).then(setClaimInfo);
      setCreating(false);
    }

    if (activeProject) {
      setAccidentInfo({
        projectId: activeProject._id,
        groupId: activeGroup._id
      });
      setPermissions(activeCompany.claimPermissions);
    } else if (activeGroup) {
      setAccidentInfo({ groupId: activeGroup._id });
      setPermissions(activeCompany.claimPermissions);
    } else if (activeCompany) {
      setPermissions(activeCompany.claimPermissions);
    }
    getLocationCodes().then(setLocationCodes);
  }, [
    activeIncident,
    activeOshaLog,
    claimId,
    activeCompany,
    activeGroup,
    activeProject,
    employeeId,
    myUserId,
    users
  ]);

  const mapIreportTreatmentToTravelerTreatment = incidentAnswer => {
    incidentAnswer = incidentAnswer.sort((a, b) => b.value - a.value);

    return TYPE_OF_MEDICAL_TREATMENT.find(
      t => t.ireportOption === incidentAnswer[0]?.value
    )?.value;
  };

  const setClaimInfo = claim => {
    setId(claim._id);
    setIsSubmitted(claim.isSubmitted);
    setIncidentId(claim.incidentId);
    setOverviewInfo({
      claimNumber: claim.claimNumber,
      previousClaims: claim.previousClaims,
      submissionDate: claim.submissionDate,
      insuredReportNumber: claim.insuredReportNumber
    });
    setEmployeeInfo({
      employeeId: claim.employeeId,
      employeeFirstName: claim.employeeFirstName,
      employeeLastName: claim.employeeLastName,
      employeeGender: claim.employeeGender,
      employeePhone: claim.employeePhone,
      employeeEmail: claim.employeeEmail,
      employeeSSN: claim.employeeSSN,
      streetAddress: claim?.employeeAddress?.streetAddress,
      city: claim?.employeeAddress?.city,
      state: claim?.employeeAddress?.state,
      zip: claim?.employeeAddress?.zip,
      employeeLanguage: claim.employeeLanguage,
      employeeOccupation: claim.employeeOccupation,
      employeeDateOfHire: claim.employeeDateOfHire,
      employeeDateOfBirth: claim.employeeDateOfBirth,
      employeeSupervisor: claim.employeeSupervisor,
      employmentStatus: claim.employmentStatus,
      numberOfDaysWorkedPerWeek: claim.numberOfDaysWorkedPerWeek,
      numberOfHoursWorkedPerWeek: claim.numberOfHoursWorkedPerWeek,
      supervisorId: claim.supervisorId
    });
    setAccidentInfo({
      groupId: claim.groupId,
      projectId: claim.projectId,
      incidentDate: claim.incidentDate,
      streetAddress: claim.incidentLocation?.incidentStreetAddress,
      city: claim.incidentLocation?.incidentCity,
      state: claim.incidentLocation?.incidentState,
      zip: claim.incidentLocation?.incidentZip,
      isCompanyAddress: claim.isCompanyAddress ? 'yes' : 'no',
      incidentDescription: claim.incidentDescription,
      incidentLocationCounty: claim.incidentLocationCounty,
      claimNotificationOther: claim.claimNotificationOther
    });
    setInjuryInfo({
      bodyPartAffected: claim.bodyPartAffected,
      natureOfInjury: claim.natureOfInjury,
      sourceOfInjury: claim.sourceOfInjury,
      typeOfMedicalTreatment: claim.typeOfMedicalTreatment,
      firstDayOfTreatment: claim.firstDayOfTreatment,
      oshaTreatmentDesc: claim.oshaTreatmentDesc,
      futureMedicalTreatment: claim.futureMedicalTreatment,
      typeOfExpectedMedicalTreatment: claim.typeOfExpectedMedicalTreatment,
      returnToWorkDuties: claim.returnToWorkDuties,
      employeeDeathDate: claim.employeeDeathDate,
      anticipatedReturnToWorkDate: claim.anticipatedReturnToWorkDate,
      isOnLightModifiedDuty: claim.isOnLightModifiedDuty,
      returnToWorkDate: claim.returnToWorkDate
    });
    setOshaInfo({
      isOshaRecordable: claim.isOshaRecordable,
      isPrivacyCase: claim.isPrivacyCase,
      isSharpsInjury: claim.isSharpsInjury,
      sharpsDeviceType: claim.sharpsDeviceType,
      oshaActivityDesc: claim.oshaActivityDesc,
      oshaLocationDesc: claim.oshaLocationDesc,
      oshaTimeWorkerStarted: claim.oshaTimeWorkerStarted
    });
    setWitnessInfo({
      areThereWitnesses: claim.areThereWitnesses ? 'yes' : 'no',
      numberOfThirdPartyPedestriansInvolved:
        claim.numberOfThirdPartyPedestriansInvolved,
      thirdPartyPedestrians: claim.thirdPartyPedestrians
    });
  };

  const handleSubmit = isSubmitted => {
    const object = {
      type: 'wc',
      ...employeeInfo,
      employeeAddress: {
        streetAddress: employeeInfo.streetAddress,
        city: employeeInfo.city,
        state: employeeInfo.state,
        zip: employeeInfo.zip
      },
      ...injuryInfo,
      isCompanyAddress: accidentInfo.isCompanyAddress === 'yes' ? true : false,
      ...accidentInfo,
      incidentLocation: {
        incidentStreetAddress: accidentInfo.streetAddress,
        incidentCity: accidentInfo.city,
        incidentState: accidentInfo.state,
        incidentZip: accidentInfo.zip
      },
      ...oshaInfo,
      ...witnessInfo,
      areThereWitnesses: witnessInfo.areThereWitnesses === 'yes' ? true : false,
      incidentId: activeIncident._id,
      oshaFormId: activeOshaLog?.[0]?._id,
      permissions: activeCompany.claimPermissions,
      isSubmitted
    };

    if (!id) {
      createClaim(object)
        .then(claim => {
          setClaimInfo(claim);
          setId(claim._id);
          setSubmitModal(false);
          setUnsavedChanges(false);
          setCreating(false);
          if (isSubmitted) {
            customToast('Submitted Successfully!', 'success');
          } else {
            customToast('Saved Successfully!', 'success');
          }
        })
        .catch(error => {
          customToast(error.data, 'error');
          setSubmitModal(false);
        });
    } else {
      updateClaim({
        _id: id,
        ...object
      })
        .then(claim => {
          setEditingFalse();
          setClaimInfo(claim);
          setSubmitModal(false);
          if (claim.isSubmitted) {
            customToast('Submitted Successfully!', 'success');
          } else {
            customToast('Saved Successfully!', 'success');
          }
        })
        .catch(error => {
          customToast(error.data, 'error');
          setSubmitModal(false);
        });
    }
  };

  const setEditingFalse = () => {
    setAccidentEditing(false);
    setInjuryEditing(false);
    setEmployeeEditing(false);
    setWitnessEditing(false);
    setUnsavedChanges(false);
  };

  const handleDelete = () => {
    deleteClaim(id).then(() => history.goBack());
  };

  const handleCancel = goBack => {
    if (goBack) {
      history.goBack();
      return;
    }
    if (
      id &&
      (accidentEditing || injuryEditing || employeeEditing || witnessEditing)
    ) {
      setEditingFalse();
    } else if (unsavedChanges && !saveChangesModal) {
      setSaveChangesModal(true);
    } else {
      history.goBack();
    }
  };

  const print = () => {
    printClaim(id).then(response => window.open(response, '_blank'));
  };

  const employeeInfoComplete = !(
    !employeeInfo.employeeGender ||
    !employeeInfo.employeeSSN ||
    !employeeInfo.employeePhone ||
    !employeeInfo.streetAddress ||
    !employeeInfo.city ||
    !employeeInfo.state ||
    !employeeInfo.zip ||
    !employeeInfo.employeeDateOfHire ||
    !employeeInfo.employeeOccupation ||
    !employeeInfo.employeeLanguage ||
    !employeeInfo.employmentStatus ||
    !employeeInfo.supervisorId ||
    !employeeInfo.employeeEmail
  );

  const injuryInfoComplete = !(
    !injuryInfo.bodyPartAffected ||
    !injuryInfo.natureOfInjury ||
    !injuryInfo.typeOfMedicalTreatment ||
    !injuryInfo.futureMedicalTreatment ||
    !injuryInfo.returnToWorkDuties
  );

  const accidentInfoComplete = !(!accidentInfo.groupId ||
  !accidentInfo.incidentDate ||
  !accidentInfo.claimNotificationOther ||
  !accidentInfo.incidentDescription ||
  accidentInfo.isCompanyAddress === 'no'
    ? !accidentInfo.streetAddress ||
      !accidentInfo.city ||
      !accidentInfo.state ||
      !accidentInfo.zip
    : !accidentInfo.isCompanyAddress || !accidentInfo.incidentLocationCounty);

  const witnessInfoComplete =
    witnessInfo.areThereWitnesses === 'yes'
      ? !(
          !witnessInfo.numberOfThirdPartyPedestriansInvolved ||
          !witnessInfo?.thirdPartyPedestrians?.every(
            t => t.firstName && t.lastName && t.phoneNumber
          )
        )
      : !!witnessInfo.areThereWitnesses;

  const oshaInfoComplete = !!oshaInfo.isOshaRecordable;

  const canSubmit =
    employeeInfoComplete &&
    injuryInfoComplete &&
    accidentInfoComplete &&
    witnessInfoComplete &&
    oshaInfoComplete;

  const isReadOnly = permissions?.find(p => p.userId === myUserId)?.readOnly;

  const header = (
    <Header
      title={activeIncident?.incidentNumber}
      section="WC Claim"
      pageActionOptions={[
        {
          label: 'Permissions',
          visible: !creating && !isReadOnly,
          color: 'blueOutline',
          onClick: () => history.push(`/app/permissions/Claim/${id}`)
        },
        {
          label: 'Return to Incident',
          visible: true,
          color: 'blueOutline',
          onClick: () =>
            dispatch(setActiveIncidentRequest(incidentId ?? activeIncident._id))
        },
        {
          label: 'Download Claim',
          visible: isSubmitted,
          color: 'greenOutline',
          onClick: print
        },
        {
          label: 'Delete Claim',
          visible: !creating && isSubmitted && !isReadOnly,
          color: 'redOutline',
          onClick: handleDelete
        }
      ]}
      clickBack={() =>
        unsavedChanges ? setSaveChangesModal(true) : history.goBack()
      }
      needsSaved={unsavedChanges}
    />
  );

  const footer = (
    <SubmitSaveForLaterCancelFooter
      submitButtonClick={() =>
        canSubmit ? setSubmitModal(true) : setMissingModalOpen(true)
      }
      saveButtonClick={() => handleSubmit(false)}
      cancelButtonClick={handleCancel}
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  const left = (
    <LeftNav
      items={[
        {
          value: 0,
          label: isSubmitted ? 'Overview' : 'Instructions',
          complete: true
        },
        {
          value: 1,
          label: 'Employee Information',
          complete: employeeInfoComplete
        },
        {
          value: 2,
          label: 'Accident Information',
          complete: accidentInfoComplete
        },
        {
          value: 3,
          label: 'Injury Information',
          complete: injuryInfoComplete
        },
        {
          value: 4,
          label: 'OSHA Information',
          complete: oshaInfoComplete
        },
        {
          value: 5,
          label: 'Witness Information',
          complete: witnessInfoComplete
        }
      ]}
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      Left={left}
      showFooter={!isSubmitted && !isReadOnly}
    >
      {isSubmitted ? (
        <ClaimCard
          name={0}
          claimSection={overviewInfo}
          title="Overview"
          viewOnly
        />
      ) : (
        <InstructionCard name={0} />
      )}
      <ClaimCard
        name={1}
        editing={creating || employeeEditing}
        viewOnly={isReadOnly}
        claimSection={employeeInfo}
        onChange={values => {
          setEmployeeInfo(values);
          setUnsavedChanges(true);
        }}
        title="Employee Information"
        openEdit={setEmployeeEditing}
        isSubmitted={isSubmitted}
        missingRequired={missingRequired}
      />
      <ClaimCard
        name={2}
        editing={creating || accidentEditing}
        viewOnly={isReadOnly}
        claimSection={accidentInfo}
        onChange={values => {
          setAccidentInfo(values);
          setUnsavedChanges(true);
        }}
        title="Accident Information"
        openEdit={setAccidentEditing}
        isSubmitted={isSubmitted}
        missingRequired={missingRequired}
        permissions={users.filter(u =>
          permissions.map(p => p.userId).includes(u._id)
        )}
        type="wc"
        locationCodes={locationCodes}
      />
      <ClaimCard
        name={3}
        editing={creating || injuryEditing}
        viewOnly={isReadOnly}
        claimSection={injuryInfo}
        onChange={values => {
          setInjuryInfo(values);
          setUnsavedChanges(true);
        }}
        title="Injury Information"
        openEdit={setInjuryEditing}
        isSubmitted={isSubmitted}
        missingRequired={missingRequired}
      />
      <ClaimCard
        name={4}
        editing={creating || oshaEditing}
        viewOnly={isReadOnly}
        claimSection={oshaInfo}
        onChange={values => {
          setOshaInfo(values);
          setUnsavedChanges(true);
        }}
        title="OSHA Information"
        openEdit={setOshaEditing}
        isSubmitted={isSubmitted}
        missingRequired={missingRequired}
      />
      <ClaimCard
        name={5}
        editing={creating || witnessEditing}
        viewOnly={isReadOnly}
        claimSection={witnessInfo}
        onChange={values => {
          setWitnessInfo(values);
          setUnsavedChanges(true);
        }}
        title="Witness Information"
        openEdit={setWitnessEditing}
        isSubmitted={isSubmitted}
        missingRequired={missingRequired}
      />
      <SaveChangesModal
        onRequestClose={() => setSaveChangesModal(false)}
        isOpen={saveChangesModal}
        submitActions={() => handleCancel(true)}
      />
      <DeleteItemModal
        onRequestClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        submitActions={handleDelete}
        hideCustomTemplate
        what="Claim"
      />
      <Modal
        title="Submit Claim"
        titleClassName="blueHeader"
        isOpen={sumbitModal}
        submitButtonColor="blue"
        submitButtonText="Submit"
        onRequestClose={() => setSubmitModal(false)}
        submitActions={() => handleSubmit(true)}
      >
        <div className={styles.text}>
          Once you submit the claim the data will no longer update within the
          incident or for your insurance.
        </div>
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="WC Claim"
      />
    </HeaderAndFooter>
  );
}
