import React, { Component } from 'react';

import Dropdown from '../Dropdown';

export default class DateRangePicker extends Component {
  render() {
    let options = [];
    if (this.props.isBulkPicker) {
      options.push(
        {
          label: 'Last week',
          value: 'lastWeek'
        },
        {
          label: 'Last month',
          value: 'lastMonth'
        },
        {
          label: 'Last quarter',
          value: 'lastQuarter'
        }
      );
    }
    options.push(
      {
        label: 'Today',
        value: 'today'
      },
      {
        label: 'This week',
        value: 'thisWeek'
      },
      {
        label: 'This month',
        value: 'thisMonth'
      },
      {
        label: 'This quarter',
        value: 'thisQuarter'
      },
      {
        label: 'This year',
        value: 'thisYear'
      }
    );

    if (this.props.customRange) {
      options.push({
        label: 'Custom Range',
        value: 'absolute'
      });
    }
    if (this.props.customPeriod) {
      options.push({
        label: 'Custom Period',
        value: 'relative'
      });
    }

    if (this.props.allowAll) {
      options.unshift({ label: 'All', value: 'all' });
    }

    return <Dropdown {...this.props} options={options} />;
  }
}
