import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useSocket } from '../../utils/withSocket';
import history from '../../history';
import {
  createDocument,
  deleteDocument,
  fetchDocumentById,
  fetchCustomTemplate,
  updateDocument,
  fetchDocumentFoldersByType,
  updateCustomDocumentName
} from '../../api/v4';
import { deleteAttachment, duplicateAttachments } from '../../api/v4';
import {
  addUploadedAttachmentsRequest,
  clearUploadedAttachments,
  addUploadedAttachmentsResponse
} from '../../actions/attachments';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { getAccessLevel, getLoggedInUser } from '../../selectors/users';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import customToast from '../../utils/customToast';
import DeleteItemModal from '../../components/Modal/deleteItemModal';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Header from '../../components/Header';
import { SaveCancelFooter } from '../../components/Footer';
import CustomDocumentGenerator from '../../components/documentCards/CustomDocumentGenerator';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

export default function CustomDocumentContainer() {
  const { templateId, documentId, duplicating } = useParams();
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const dispatch = useDispatch();
  const fromScreen = useLocation().state?.fromScreen;
  const socket = useSocket();
  const accessLevel = useSelector(getAccessLevel);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);
  const user = useSelector(getLoggedInUser);

  const [fields, setFields] = useState([]);
  const [document, setDocument] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [folders, setFolders] = useState([]);

  const allAttachments = [...attachments, ...addedAttachments];

  const canEdit =
    user.accessLevel > 100 || document?.auditLog?.[0]?.user === user._id;

  useEffect(() => {
    if (duplicating) {
      fetchDocumentById(documentId).then(response => {
        const updatedFields = response?.documentData?.fields?.map(f => {
          if (f.type === 'signature') {
            f.answer = '';
          }
          return f;
        });
        let updateDocumentData = {
          ...response.documentData,
          fields: updatedFields
        };
        setFields(updatedFields);
        setDocument({
          documentType: response.documentType,
          label: response.label,
          shouldHaveExpiredDate: response.documentData?.shouldHaveExpiredDate,
          isConfidential: response?.isConfidential,
          duplicated: true,
          documentData: updateDocumentData,
          expires: response.expires,
          hasInvolvedEmployee: response.hasInvolvedEmployee,
          customDocumentNumber: '',
          involvedEmployee: response.involvedEmployee,
          groupIds: response.groupIds,
          projectIds: response.projectIds
        });
        let docAttachments = response?.attachments
          ?.filter(a => !a.isSignature)
          ?.map(a => {
            delete a.auditLog;
            delete a.ownerId;
            delete a.createdByUserId;
            delete a.created_by;
            delete a.createdAt;
            delete a.updatedAt;
            a.duplicated = true;
            return a;
          });

        setAttachments(docAttachments);
        setIsCreating(true);
      });
    } else if (documentId) {
      fetchDocumentById(documentId).then(response => {
        setFields(response.documentData?.fields);
        setDocument({
          ...response,
          shouldHaveExpiredDate: response.documentData?.shouldHaveExpiredDate
        });
        setAttachments(response.attachments);
        if (!socket) return;
        socket.emit('subscribeCustomDoc', response._id);
        if (!socket) return;
        socket.on('customdocPdf', value => {
          setGenerated(value);
        });
        socket.on('printFail', error => {
          setGenerated(null);
          customToast(
            `Could not generate Custom Document PDF: ${error}`,
            'error'
          );
        });
      });
    } else {
      setIsCreating(true);
      fetchCustomTemplate(templateId).then(response => {
        setDocument({
          label: response?.label,
          shouldHaveExpiredDate: response?.shouldHaveExpiredDate,
          isConfidential: response.isConfidential,
          groupIds: activeGroup ? [activeGroup._id] : [],
          projectIds: activeProject ? [activeProject._id] : [],
          hasInvolvedEmployee: response.hasInvolvedEmployee
        });
        setFields(response?.fields);
      });
    }
    fetchDocumentFoldersByType('CustomDocument').then(response =>
      setFolders(response?.map(df => ({ value: df?._id, label: df?.name })))
    );
  }, [
    templateId,
    documentId,
    socket,
    dispatch,
    duplicating,
    activeGroup,
    activeProject
  ]);

  const handleCustomSubmit = () => {
    const attachmentIds = allAttachments?.map(a => a._id);
    fields.forEach(f => {
      if (f.type === 'signature' && f?.answer?._id) {
        f.answer = f.answer.source_url;
      }
    });
    let payload = {
      documentType: 'CustomDocument',
      ...document,
      documentData: {
        fields,
        shouldHaveExpiredDate: document?.shouldHaveExpiredDate
      },
      attachments: attachmentIds
    };

    if (documentId && !duplicating) {
      updateDocument({
        ...payload,
        _id: documentId
      }).then(handleGoBack());
    } else {
      if (duplicating) {
        const attachmentsToDuplicate = allAttachments
          .filter(a => a.duplicated)
          ?.map(a => {
            delete a._id;
            return a;
          });
        const uploadedAttachments = allAttachments.filter(a => a._id);
        duplicateAttachments({
          userId: user._id,
          attachments: attachmentsToDuplicate
        }).then(response => {
          payload.attachments = [
            ...uploadedAttachments?.map(a => a._id),
            ...response
          ];

          createDocument(payload).then(() => {
            customToast('Successfully Created!', 'success');
            handleGoBack(true);
          });
        });
      } else {
        createDocument(payload).then(() => {
          customToast('Successfully Created!', 'success');
          handleGoBack(true);
        });
      }
    }
  };

  const handleCustomDelete = () => {
    deleteDocument(documentId).then(handleGoBack);
  };

  const handleDeleteAttachment = attachment => {
    let updatedAttachments = attachments?.filter(
      a => a._id !== attachment?._id
    );
    let updatedAddedAttachments = addedAttachments?.filter(
      a => a._id !== attachment?._id
    );

    dispatch(addUploadedAttachmentsResponse(updatedAddedAttachments));
    if (!duplicating) {
      deleteAttachment(attachment?._id).then(response => {
        customToast('Successfully Deleted!', 'success');
      });
    }
    setAttachments(updatedAttachments);
  };

  const handleGoBack = (fromCreate = false) => {
    dispatch(clearUploadedAttachments());
    if (duplicating) {
      setHasUnsavedChanges(false);
      setOpenUnsavedChangesModal(false);
      setIsCreating(false);
      fromCreate
        ? history.push('/app/CustomDocumentList')
        : history.push(`/app/customDocumentContainer/null/${documentId}`);
    } else {
      history.push(fromScreen ?? '/app/CustomDocumentList');
    }
  };

  const handleDuplication = () => {
    dispatch(clearUploadedAttachments());
    history.push(`/app/customDocumentContainer/null/${documentId}/true`);
  };

  const handleArchive = () => {
    updateDocument({
      ...document,
      isArchived: !document?.isArchived
    }).then(() => {
      if (!document.isArchived) handleGoBack();
      else fetchDocumentById(document._id).then(setDocument);
    });
  };

  const submitCustomDocName = newName => {
    setDocument({
      ...document,
      customDocumentNumber: newName
    });

    updateCustomDocumentName(document._id, newName);
  };

  const requiredNotAnswered = fields?.filter(
    question =>
      question.required &&
      !question.legacy &&
      question.type !== 'blockOfText' &&
      (Array.isArray(question.answer)
        ? question.type === 'table'
          ? !question.answer?.some(row => row?.some(text => text))
          : question.answer.length === 0
        : !question.answer && question.answer !== 0)
  );

  const canSubmit =
    !hasUnsavedChanges && !addedAttachments?.length && !duplicating
      ? true
      : fields?.length &&
          !requiredNotAnswered?.length &&
          (!document.hasInvolvedEmployee || document.involvedEmployee)
        ? true
        : false;

  const generatedReport = generated && generated.url;

  const header = (
    <Header
      title="Custom Document"
      section={
        duplicating
          ? 'Duplicate Document'
          : !isEditing && !isCreating
            ? `${document.customDocumentNumber ?? 'View Document'}`
            : isCreating
              ? 'Create New Document'
              : 'Edit Document'
      }
      needsSaved={hasUnsavedChanges}
      clickBack={() =>
        hasUnsavedChanges
          ? setOpenUnsavedChangesModal(true)
          : isEditing
            ? setIsEditing(false)
            : handleGoBack()
      }
      editName={isCreating ? null : submitCustomDocName}
      pageActionOptions={
        isCreating
          ? null
          : [
              {
                label: generatedReport ? 'Regenerate PDF' : 'Generate PDF',
                onClick: () => {
                  socket.emit('generateCustomDocPdf', documentId);
                  setGenerated('Loading');
                },
                color: 'blueOutline',
                visible: !isEditing && (!generated || generatedReport)
              },
              {
                label: 'Generating PDF',
                color: 'greyOutline',
                onClick: () => {},
                visible: !isEditing && generated && !generated.url
              },
              {
                label: 'Download PDF',
                onClick: () => {
                  window.open(generated.url, '_blank');
                },
                color: 'greenOutline',
                visible: generatedReport && !isEditing
              },
              {
                visible: canEdit,
                label:
                  isEditing || document?.isArchived
                    ? 'Delete Document'
                    : 'Edit Document',
                onClick: () =>
                  isEditing || document?.isArchived
                    ? setOpenDeleteModal(true)
                    : setIsEditing(true),
                color:
                  isEditing || document?.isArchived
                    ? 'redOutline'
                    : 'blueOutline'
              },
              {
                visible: !document?.isArchived,
                label: 'Duplicate Document',
                onClick: () => handleDuplication(),
                color: 'blueOutline'
              },
              {
                label: `${document?.isArchived ? 'Un-' : ''}Archive Document`,
                color: 'blueOutline',
                onClick: () => handleArchive(),
                visible: accessLevel > 400
              }
            ]
      }
      hasNoName={!document.customDocumentNumber}
    />
  );

  const footer =
    isEditing || isCreating ? (
      <SaveCancelFooter
        saveButtonClick={() =>
          !canSubmit || !document.groupIds?.length
            ? setMissingModalOpen(true)
            : handleCustomSubmit()
        }
        cancelButtonClick={() =>
          hasUnsavedChanges
            ? setOpenUnsavedChangesModal(true)
            : isEditing
              ? setIsEditing(false)
              : handleGoBack()
        }
        editing={isEditing}
        onMouseEnter={() => setMissingRequired(true)}
      />
    ) : null;

  return (
    <>
      <HeaderAndFooter Header={header} Footer={footer}>
        <CustomDocumentGenerator
          answerQuestion={fields => {
            setFields([...fields]);
            setHasUnsavedChanges(true);
          }}
          fields={fields}
          disabled={!isEditing && !isCreating}
          activeCustomDoc={document}
          handleExpires={values => {
            setHasUnsavedChanges(true);
            setDocument({ ...document, expires: values });
          }}
          addAttachment={attachment =>
            dispatch(addUploadedAttachmentsRequest(attachment))
          }
          deleteAttachment={handleDeleteAttachment}
          activeCompanyId={activeCompany?._id}
          hasAccess={accessLevel !== 100}
          attachments={allAttachments}
          setDocument={values => {
            setDocument({ ...document, ...values });
            setHasUnsavedChanges(true);
          }}
          missingRequired={missingRequired}
          folders={folders}
        />
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={openUnsavedChangesModal}
        onRequestClose={() => setOpenUnsavedChangesModal(false)}
        submitActions={handleGoBack}
        savingWhat="a document"
      />
      <DeleteItemModal
        isOpen={openDeleteModal}
        onRequestClose={() => setOpenDeleteModal(false)}
        submitActions={handleCustomDelete}
        deletingWhat="Document"
      />
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="Document"
        isEditing={isEditing}
      />
    </>
  );
}
