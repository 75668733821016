import config from '../config/config';

const featureToggles = config.featureToggles;

export const EMPLOYEE_BULK_UPLOAD_TEMPLATE =
  'https://ireportsource-cdn.s3.us-east-2.amazonaws.com/world/EmployeeUploadTemplate_2025_03_14.csv';

export const CSV_LRR_TEMPLATE =
  'https://s3.us-east-2.amazonaws.com/ireportsource-cdn/dashboard/companies/5b21105fa64d4e00197ffba4/401404e1-b882-46b8-9ed4-6c83c586afad/loss_run_template-table 1.csv';

export const FROI_BULK_UPLOAD_TEMPLATE =
  'https://ireportsource-cdn.s3.us-east-2.amazonaws.com/world/FROIUploadTemplate04_09_2024.csv';

export const VEHICLE_UPLOAD_TEMPLATE =
  'https://ireportsource-cdn.s3.us-east-2.amazonaws.com/world/FROIVehicleTemplate04_05_2024.csv';

export const EQUIPMENT_BULK_UPLOAD_TEMPLATE =
  'https://ireportsource-cdn.s3.us-east-2.amazonaws.com/world/EquipmentUploadTemplate_12_05_2023.csv';

export const TRAINING_BULK_UPLOAD_TEMPLATE =
  'https://ireportsource-cdn.s3.us-east-2.amazonaws.com/world/TrainingUpload_10-22-24.csv';

// must keep this updte to date with BE. This is for chromebook/tablet users
export const MOBILE_MIN_VERSION = '4.8.0';

export const COMBINED_EMPLOYEE_STATUS = [
  { label: 'Contractor', value: 3 },
  { label: 'Intern', value: 10 },
  { label: 'Seasonal', value: 14 },
  { label: 'Temporary Worker', value: 15 },
  { label: 'Full Time', value: 1 },
  { label: 'Part Time', value: 2 },
  { label: 'Workers Comp', value: 7 },
  { label: 'Retired', value: 8 },
  { label: 'Terminated', value: 17 },
  { label: 'Resigned', value: 18 },
  { label: 'Laid Off', value: 19 }
];

export const EMPLOYEE_STATUSES = [
  { label: 'Full Time', value: 1 },
  { label: 'Part Time', value: 2 },
  { label: 'Workers Comp', value: 7 }
];

export const CONTRACTOR_STATUSES = [
  { label: 'Contractor', value: 3 },
  { label: 'Terminated', value: 17 }
];

export const OTHER_STATUSES = [
  { label: 'Retired', value: 8 },
  { label: 'Terminated', value: 17 },
  { label: 'Intern', value: 10 },
  { label: 'Seasonal', value: 14 },
  { label: 'Temporary Worker', value: 15 },
  { label: 'Resigned', value: 18 },
  { label: 'Laid Off', value: 19 }
];

export const PRIORITY_DROPDOWN = [
  { value: 1, label: 'Low' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'High' }
];

export const SEVERITY_DROPDOWN = [
  { value: 0, label: '0 - Immediately Corrected' },
  {
    value: 1,
    label:
      '1 - Advisory (Small hazards easily handled by typical day to day procedures)'
  },
  {
    value: 2,
    label:
      '2 - Minor (Hazards that may or have resulted in on-site first aid treatment)'
  },
  {
    value: 3,
    label:
      '3 - Moderate (Hazards that may or have required off-site non-emergency medical treatment)'
  },
  {
    value: 4,
    label:
      '4 - Serious (Major hazards that may result in emergency medical treatment)'
  },
  {
    value: 5,
    label:
      '5 - Critical (Grievous hazards that may cause death or permanent disability)'
  }
];

export const PROBABILITY_DROPDOWN = [
  {
    value: 1,
    label:
      'Frequent (Immediate danger to health and safety of the public, staff or property and resource)'
  },
  {
    value: 2,
    label:
      'Likely (Probably will occur in time if not corrected, or probably will occur one or more times)'
  },
  {
    value: 3,
    label: 'Occasional (Possible to occur in time if not corrected)'
  },
  {
    value: 4,
    label: 'Rarely (Unlikely to occur may assume to exposure)'
  }
];

export const SDS_PHYSICAL_HAZARD_DROPDOWN = [
  { label: 'Fire', value: 1 },
  { label: 'Sudden release of pressure', value: 2 },
  { label: 'Reactivity', value: 3 },
  { label: 'Immediate/Acute', value: 4 },
  { label: 'Delayed', value: 5 },
  { label: 'Chronic', value: 6 }
];

export const SDS_STORAGE_STATE_DROPDOWN = [
  { value: 1, label: 'Gas' },
  { value: 2, label: 'Liquid' },
  { value: 3, label: 'Solid' },
  { value: 4, label: 'Mix' },
  { value: 5, label: 'Pure' }
];

export const DAILY_OPTIONS = [
  { label: 'M-F', value: '1' },
  { label: 'Every Day', value: '2' }
];

export const DAY_OF_WEEK_DROPDOWN = [
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 7 }
];

export const GENDER_DROPDOWN = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
  { label: 'Non-binary', value: 3 },
  { label: 'Other', value: 4 },
  { label: 'Prefer not to say', value: 5 }
];

export const TASK_CATEGORY_DROPDOWN = [
  { value: 1, label: 'Safety' },
  { value: 2, label: 'Quality' },
  { value: 3, label: 'Productivity' },
  { value: 4, label: 'Cost' },
  { value: 5, label: 'Morale' },
  { value: 6, label: 'Process' },
  { value: 7, label: 'Compliance with initial job-ready requirements' },
  { value: 8, label: 'Corrective Action' },
  { value: 9, label: 'Follow-up' },
  { value: 10, label: 'Compliance with material inspection and tests' },
  { value: 11, label: 'Compliance with work in progress' },
  {
    value: 12,
    label: 'Compliance with task completion inspection requirements'
  },
  { value: 13, label: 'Compliance with employee corrective action' },
  { value: 14, label: 'Correction of Near Miss' },
  { value: 15, label: 'Correction of a Safety Suggestion' },
  { value: 16, label: 'Other' },
  { value: 17, label: 'Environmental' }
];

export const EQUIPMENT_STATUS_DROPDOWN = [
  { value: 1, label: 'Operational' },
  { value: 2, label: 'Service' },
  { value: 3, label: 'Not operational' }
];

export const OSHA_PERRP_DROPDOWN = [
  { value: 1, label: 'OSHA' },
  { value: 2, label: 'PERRP' }
];

export const REPORTER_ROLE =
  'Reporters will only have the ability to create the initial report and see reports that they have been involved with. They will also be able to see the company SDS and Safety Instructions.';

export const SUPERVISOR_ROLE =
  'Supervisors can Create Reports and edit the Incidents Basics, Supervisor Investigation and Witness Statements for Reports already created. They can perform RAs assigned to them and see Documents entailed within the locations they have permissions.';

export const COLLABORATOR_ROLE =
  'Collaborators can view all or specific Group(s) / Establishment(s) from one company. They are able to create, edit and delete Reports and Documents, as well as handle the OSHA Forms.';

export const ADMIN_ROLE = `Administrator is the most powerful user role. Administrators can Create, Edit and Delete Companies, Groups / Establishments, Areas, Users, Employees, Contractors, Documents and Reports. They are able to view the Analytics and OSHA Compliance features as well.`;

export const DEACTIVATED_ROLE =
  'A Deactivated user is someone who will not be able to log in until they are made an active role.';

export const WEATHER_CONDITIONS = [
  { value: 0, label: 'Clear' },
  { value: 1, label: 'Raining' },
  { value: 2, label: 'Fog' },
  { value: 3, label: 'Snow' },
  { value: 4, label: 'Other' }
];

export const WORK_DELAYED = [
  { value: 0, label: 'Yes, material shortage' },
  { value: 1, label: 'Yes, contractor issue' },
  { value: 2, label: 'Yes, attendence issue' },
  { value: 3, label: 'Yes, issue with permit' },
  { value: 4, label: 'Yes, issue with plans' },
  { value: 5, label: 'No Delays' },
  { value: 6, label: 'Other' }
];

export const YES_NO_DROPDOWN = [
  { value: 0, label: 'Yes' },
  { value: 1, label: 'No' },
  { value: 2, label: 'Other' }
];

export const USER_ROLES = [
  { value: 900, label: 'Admin' },
  { value: 500, label: 'Collaborator' },
  { value: 400, label: 'Supervisor' },
  { value: 100, label: 'Reporter' },
  { value: 0, label: 'Deactivated' }
];

export const REACTIVE_REPORT_TYPES = [
  { label: 'Employee Injury', value: 2, svg: 'froi' },
  { label: 'Near Miss', value: 7, svg: 'nearMiss' },
  { label: 'Vehicle Incident', svg: 'vehicle', value: 1 },
  {
    label: 'Third Party Injury',
    svg: 'thirdParty',
    value: 5
  },
  { label: 'Property Was Damaged', svg: 'property-damage', value: 3 },
  { label: 'Theft', svg: 'theft', value: 4 },
  { label: 'Aircraft', svg: 'aircraft', value: 6, isAircraft: true },
  { label: 'Spill-Misfuel', svg: 'misfuel', value: 16, isAircraft: true },
  { label: 'Violence / Harassment', svg: 'violence', value: 17 },
  { label: 'Spill', svg: 'spill', value: 18 },
  { label: 'General Incident', svg: 'general', value: 19 }
];

export const PROACTIVE_REPORT_TYPES = [
  { label: 'Safety Audit', value: 8 },
  { label: 'Behavior Observation', value: 12 },
  { label: 'Safety Walk', value: 10 }
];
export const ALL_REPORT_TYPES = [
  ...REACTIVE_REPORT_TYPES,
  ...PROACTIVE_REPORT_TYPES,
  { label: 'See Something Say Something', value: 11 }
];

export const PROACTIVE_KEYS = [10, 8, 9, 14, 12];
export const REACTIVE_KEYS = [2, 3, 7, 1, 5, 4, 6, 16, 17, 18, 19];

export const PERRP_ENTITY_CODES = [
  { value: '110', label: 'ODNR' },
  { value: '120', label: 'ODOT' },
  { value: '130', label: 'ODRC' },
  { value: '140', label: 'Other state agency' },
  { value: '210', label: 'County Commissioner' },
  { value: '220', label: 'County Engineer' },
  { value: '230', label: 'County Home (care center)' },
  { value: '240', label: 'County MRDD' },
  { value: '250', label: 'Other county agency' },
  { value: '310', label: 'City' },
  { value: '410', label: 'Township' },
  { value: '510', label: 'Village' },
  { value: '610', label: 'Comm./Tech College' },
  { value: '620', label: 'College Branch' },
  { value: '630', label: 'Joint vocational district' },
  { value: '640', label: 'School district' },
  { value: '650', label: 'Educational service center' },
  { value: '660', label: 'University' },
  { value: '670', label: 'University Branch' },
  { value: '710', label: 'Special District' }
];

export const PUBLIC_ENTITY_CODES = [
  '110',
  '120',
  '130',
  '140',
  '210',
  '220',
  '230',
  '240',
  '250',
  '310',
  '410',
  '510',
  '710'
];

export const SCHOOL_ENTITY_CODES = [
  '610',
  '620',
  '630',
  '640',
  '650',
  '660',
  '670'
];

export const CENSORED_TAGS = [
  'Death',
  'Graphic',
  'Injury',
  'Privacy Concern',
  'Sensitive Info',
  'Violence'
];

export const DEFAULT_TAGS = [
  'Behavior',
  'Caused Harm',
  'Damaged',
  'Equipment',
  'Hazard',
  'HAZMAT',
  'Incident Location',
  'Stolen',
  'Vehicle',
  'Weapon',
  'Witness'
];

export const INCIDENT_STAGES = [
  'Investigation',
  'Action/Learning',
  'Closure',
  'Done'
];
export const TRAINING_CREATION_STAGES = ['Basics', 'Details', 'Scheduling'];

export const TRAINING_TYPES = [
  { label: 'Digital', value: 1 },
  { label: 'In-Person', value: 2 }
];

export const REPORT_ACTION_ITEMS = [
  { value: 0, label: 'Activity Log' },
  { value: 1, label: 'Attachments' },
  { value: 2, label: 'Comments' },
  { value: 3, label: 'Open Tasks' },
  { value: 4, label: 'Private Log' }
];

export const INVESTIGATION_COMPONENTS = [
  'Initial Report',
  'Photo Evidence',
  'Diagrams',
  'Witness Statements',
  'Supervisor Summary',
  'Injured Person Statement'
];

export const ACTION_COMPONENTS = [
  'Root Cause',
  'Non-Conformance',
  'Corrective Action',
  'Corrective Actions',
  'Preventative Action'
];

export const CLOSURE_COMPONENTS = [
  'OSHA Compliance',
  'Follow-Up',
  'Return to Work',
  'Closure Checklist'
];

export const INVESTIGATION_COMPONENTS_PRINT = [
  { label: 'Initial Report', value: 'initialReport' },
  { label: 'Photo Evidence', value: 'photoEvidence' },
  { label: 'Diagrams', value: 'diagrams' },
  { label: 'Witness Statements', value: 'witnessStatements' },
  { label: 'Supervisor Summary', value: 'supervisorSummary' }
];

export const ACTION_COMPONENTS_PRINT = [
  { label: 'Root Cause', value: 'rootCause' },
  { label: 'Corrective Action', value: 'correctiveAction' },
  { label: 'Corrective Actions', value: 'correctiveActions' },
  { label: 'Non-Conformance', value: 'nonConformanceAction' },
  { label: 'Preventative Action', value: 'preventativeAction' }
];

export const CLOSURE_COMPONENTS_PRINT = [
  { label: 'Follow-Up', value: 'followUp' },
  { label: 'Return to Work', value: 'returnToWork' },
  { label: 'Closure Checklist', value: 'closureChecklist' }
];

export const DOWNLOAD_REPORTS_OPTIONS = [
  { label: 'Include Comments', value: 'includeComments' },
  { label: 'Include Attachments', value: 'includeAttachments' },
  { label: 'Include Tasks', value: 'includeTasks' },
  {
    label: 'Employee Involved Information',
    value: 'employeeInvolvedInformation'
  }
];

export const CLAIM_STAGES = [
  'New Report',
  'Incident Analysis',
  'Medical Treatment',
  'Return to Work',
  'Litigation',
  'Closed'
];

export const HAZARD_STAGES = [
  'New Observation',
  'Hazard Analysis',
  'Training',
  'Closed'
];

export const RECURRING_OPTIONS = [
  { value: 'Same Day', label: 'Same Day' },
  { value: 'Next Day', label: 'Next Day' },
  { value: '2 Days Later', label: '2 Days Later' },
  { value: '3 Days Later', label: '3 Days Later' },
  { value: '4 Days Later', label: '4 Days Later' },
  { value: '5 Days Later', label: '5 Days Later' },
  { value: '6 Days Later', label: '6 Days Later' },
  { value: '7 Days Later', label: '7 Days Later' },
  { value: '2 Weeks Later', label: '2 Weeks Later' },
  { value: '1 Month Later', label: '1 Month Later' }
];

export const FREQUENCY_OPTIONS = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  { value: 'yearly', label: 'Yearly' }
];

export const MONTHLY_FREQUENCY_OPTIONS = [
  { value: '1stofthemonth', label: '1st of the month' },
  { value: '15thofthemonth', label: '15th of the month' },
  { value: 'lastdayofthemonth', label: 'Last day of the month' }
];

export const YEARLY_FREQUENCY_OPTIONS = [
  { value: 'january', label: '1st of January' },
  { value: 'february', label: '1st of Februray' },
  { value: 'march', label: '1st of March' },
  { value: 'april', label: '1st of April' },
  { value: 'may', label: '1st of May' },
  { value: 'june', label: '1st of June' },
  { value: 'july', label: '1st of July' },
  { value: 'august', label: '1st of August' },
  { value: 'september', label: '1st of September' },
  { value: 'october', label: '1st of October' },
  { value: 'november', label: '1st of November' },
  { value: 'december', label: '1st of December' }
];
export const QUARTERLY_FREQUENCY_TEXT = '1st of Jan, Apr, Jul, Oct';
export const DAILY_FREQUENCY_TEXT = 'Every day';

export const ANALYTICS_QUARTERLY_FREQUENCY_OPTIONS = [
  { value: '1stMonthOfQuarter', label: '1st Month of Quarter' },
  { value: '2ndMonthOfQuarter', label: '2nd Month of Quarter' },
  { value: '3rdMonthOfQuarter', label: '3rd Month of Quarter' }
];

export const REPORT_FILE_TYPES = [
  { value: 'pdf', label: '.pdf' },
  { value: 'csv', label: '.csv' },
  { value: 'json', label: '.json' }
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const CAUSAL_FACTORS = [
  {
    value: 'environmental',
    label:
      'Environment: (weather, housekeeping, lighting, noise, temperature, etc.)'
  },
  {
    value: 'personal',
    label:
      'Human Factor: (experience, training,physical capability, health, fatigue, stress, etc.)'
  },
  {
    value: 'task',
    label:
      'Task: (ergonomics, condition changes, work process, safe work procedures, etc.)'
  },
  {
    value: 'process',
    label:
      'Management / Process: (safety policies, enforcement, supervision, hazard correction, preventative maintenance, etc.)'
  },
  {
    value: 'equipment',
    label:
      'Material / Equipment: (equipment failure, design, guarding, hazardous substances, etc.)'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

export const HAZARD_CLASS = [
  {
    value: 'biological',
    label: 'Biological (biological waste, pathogens, poisonous flora/fauna)'
  },
  {
    value: 'chemical',
    label: 'Chemical (exposure to chemical preparations)'
  },
  {
    value: 'ergonomic',
    label: 'Ergonomic (working conditions that put strain on the body)'
  },
  {
    value: 'physical',
    label:
      'Physical (factors within the environment that can cause harm without touching it)'
  },
  {
    value: 'psychological',
    label:
      'Psychological (stress, workload, dealing with the public, harassment, threat of danger, violence, etc.)'
  },
  {
    value: 'safety',
    label: 'Safety (unsafe conditions that can cause injury, illness, or death)'
  }
];

export const HAZARD_CLASSIFICATION = [
  'Chemicals / Substances',
  'Confined Spaces',
  'Cuts / Lacerations',
  'Electrical / Arc Flash',
  'Excavation / Trenching',
  'Eye / Face Protection',
  'Fall from Heights',
  'Falling Object',
  'Fire / Explosion',
  'Floor or wall opening',
  'Forklift / Mobile Equipment',
  'Grinding / Polishing',
  'Hand / Finger Protection',
  'Housekeeping / Material Storage',
  'Line Break',
  'LOTO',
  'Mobile Elevated Work Platforms',
  'Pinch Points / Struck by / Caught in',
  'Respiratory Problems',
  'Rigging / Crane Use',
  'Scaffolds / Ladders / Stairways',
  'Slips / Trips / Falls (same surface)',
  'Welding / Cutting / Burning',
  'Other'
];

export const BRAVO_CLASSIFICATION = [
  'Compliance - Emergency Preparedness',
  'Compliance - Equipment Handling',
  'Compliance - Hazard Communication',
  'Compliance - Training Participation',
  'Compliance - Use of Stop Work Authority',
  'Good Catch - Adherence to Safety Procedures',
  'Good Catch - Positive Attitude Towards Safety',
  'Good Catch - Safe Use of PPE',
  'Good Catch - Teamwork and Support',
  'Good Catch - Work Area Organization',
  'Other'
];

export const SAFETY_WALKS_STAGES = [
  { value: 'severityReview', label: 'Severity Review' },
  { value: 'correctiveAction', label: 'Corrective Action' },
  { value: 'pastDue', label: 'Past Due' },
  { value: 'closed', label: 'Closed' }
];

export const SECURITY_QUESTIONS_OPTIONS_SET_ONE = [
  {
    value: 1,
    label: 'What was the house number and street name you lived in as a child?'
  },
  {
    value: 2,
    label: 'What were the last four digits of your childhood telephone number?'
  },
  {
    value: 3,
    label: 'What primary school did you attend?'
  },
  {
    value: 4,
    label: 'In what town or city was your first full time job?'
  },
  {
    value: 5,
    label: 'In what town or city did you meet your spouse/partner?'
  },
  {
    value: 6,
    label: 'What is the middle name of your oldest child?'
  }
];

export const SECURITY_QUESTIONS_OPTIONS_SET_TWO = [
  {
    value: 7,
    label: `What are the last five digits of your driver's licence number?`
  },
  {
    value: 8,
    label: `What is your grandmother's (on your mother's side) maiden name?`
  },
  {
    value: 9,
    label: `What is your spouse or partner's mother's maiden name?`
  },
  {
    value: 10,
    label: 'In what town or city did your mother and father meet?'
  },
  {
    value: 11,
    label: 'What time of the day were you born? (hh:mm)'
  },
  {
    value: 12,
    label: 'What time of the day was your first child born? (hh:mm)'
  }
];

export const REPORT_COMPONENTS_CUSTOMIZABLE =
  featureToggles.customInitialReports
    ? [
        {
          label: 'Report Basics',
          value: 'reportBasic',
          reports: [1, 2, 3, 4, 5, 7, 11, 12, 6, 16, 17, 18, 19]
        },
        {
          label: 'Supervisor Summary',
          value: 'supervisorSummary',
          reports: [1, 2, 3, 4, 5, 7, 11, 12, 6, 16, 17, 18, 19]
        },
        {
          label: 'Preventative Action',
          value: 'preventativeAction',
          reports: [11, 12]
        },
        {
          label: 'Follow-Up',
          value: 'followUp',
          reports: [1, 2, 3, 4, 5, 7, 11, 12, 6, 16, 17, 18, 19]
        },
        {
          label: 'Closure Checklist',
          value: 'closureChecklist',
          reports: [1, 2, 3, 4, 5, 7, 11, 12, 6, 16, 17, 18, 19]
        }
      ]
    : [
        {
          label: 'Supervisor Summary',
          value: 'supervisorSummary',
          reports: [1, 2, 3, 4, 5, 7, 11, 12]
        },
        {
          label: 'Root Cause',
          value: 'rootCause',
          reports: [1, 2, 3, 4, 5, 7, 11, 12]
        },

        {
          label: 'Preventative Action',
          value: 'preventativeAction',
          reports: [11, 12]
        },
        {
          label: 'Follow-Up',
          value: 'followUp',
          reports: [1, 2, 3, 4, 5, 7, 11, 12]
        },
        {
          label: 'Closure Checklist',
          value: 'closureChecklist',
          reports: [1, 2, 3, 4, 5, 7, 11, 12]
        }
      ];

export const TRANSITIONAL_INFORMATION = [
  'The Transitional Return to Work Program (TRWP) is designed to provide you with a suitable temporary work assignment while you complete your recovery. All parties agree that during your participation in the program, you will not be required to perform any task or duties that are not compatible with the temporary restrictions that your physician has provided.',
  'The program may continue as long as there is medical documentation of need and the division is able to accommodate the temporary restrictions. You will be paid your regular rate of pay for hours worked while participating in the program. You will be expected to follow all established personnel policies and procedures. If you are required to attend physical therapy or doctor appointments, you are expected to use appropriate injury leave or sick leave as specified in your collective bargaining agreement and are encouraged to schedule these appointments during non-working hours whenever possible. Your signature on this form indicates that you understand the requirements for participation in the Transitional Return to Work Program and that you will abide by the restrictions placed upon you by your physician.'
];

export const TYPE_NAMES = {
  2: 'Employee Injury',
  1: 'Vehicle Incident',
  3: 'Property Damage',
  7: 'Near Miss',
  4: 'Theft',
  5: 'Third Party Injury',
  6: 'Aircraft',
  16: 'Spill/Misfuel',
  12: 'Behavior Observations',
  17: 'Violence/Harassment',
  18: 'Spill',
  19: 'General Incident'
};

export const REPORT_TYPES_CUSTOMIZABLE = [
  { label: 'Employee Injury', value: 2, svg: 'froi' },
  { label: 'Near Miss', value: 7, svg: 'nearMiss' },
  { label: 'Vehicle Incident', svg: 'vehicle', value: 1 },
  {
    label: 'Third Party Injury',
    svg: 'thirdParty',
    value: 5
  },
  { label: 'Property Was Damaged', svg: 'property-damage', value: 3 },
  { label: 'Theft', svg: 'theft', value: 4 },
  { label: 'Aircraft', svg: 'aircraft', value: 6 },
  { label: 'Spill-Misfuel', svg: 'misfuel', value: 16 }
];

export const FIELD_TYPES_CUSTOMIZABLE = [
  { label: 'Dropdown', value: 'dropdown', hasOptions: true },
  { label: 'Multiselect', value: 'multiselect', hasOptions: true },
  { label: 'Radio Button', value: 'radiobuttons', hasOptions: true },
  { label: 'Checkbox', value: 'checkbox', hasOptions: true },
  { label: 'Google Address', value: 'googleAddress' },
  { label: 'Text Field', value: 'textbox' },
  { label: 'Text Area', value: 'textarea' },
  { label: 'Date', value: 'datePicker' },
  { label: 'Date/Time', value: 'dateTimePicker' },
  { label: 'Signature', value: 'signature' },
  { label: 'Employee List', value: 'multiselectPersonnel' }
];

export const FIELD_TYPES_OPTIONS = [
  'dropdown',
  'multiselect',
  'radiobuttons',
  'checkbox'
];

export const DOCUMENT_TYPES_CUSTOMIZABLE = [
  { label: 'Dropdown', value: 'dropdown', hasOptions: true },
  { label: 'Multiselect', value: 'multiselect', hasOptions: true },
  { label: 'Radio Button', value: 'radiobuttons', hasOptions: true },
  { label: 'Checkbox', value: 'checkbox', hasOptions: true },
  { label: 'Text Field', value: 'textbox' },
  { label: 'Text Area', value: 'textarea' },
  { label: 'Date', value: 'datePicker' },
  { label: 'Date/Time', value: 'dateTimePicker' },
  { label: 'Block of Text', value: 'blockOfText' },
  { label: 'Table', value: 'table' },
  { label: 'Employee List', value: 'multiselectPersonnel' },
  { label: 'Signature', value: 'signature' }
];

export const DOCUMENT_TYPES_CUSTOMIZABLE_PLUS = [
  ...DOCUMENT_TYPES_CUSTOMIZABLE,
  { label: 'QR Signature', value: 'qrSign' }
];

export const DIGITAL_OPTIONS = [
  { value: 1, label: 'Immediately' },
  { value: 2, label: 'Repeating' },
  { value: 3, label: 'Scan on Demand' }
];

export const INPERSON_OPTIONS = [
  { value: 1, label: "Assign to the trainer's To-Do list" },
  { value: 2, label: 'Track attendance of a completed training' }
];

//".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
export const TRAINING_FILE_TYPES_ACCEPTED = [
  '.doc',
  '.docx',
  'image/png',
  'image/jpeg',
  'image/jpg',
  '.mp4',
  '.mov',
  '.pdf',
  '.xlsx',
  '.xls',
  '.ppt',
  '.pptx',
  '.txt'
];

export const TASK_FILE_TYPES_ACCEPTED = [
  '.doc',
  '.docx',
  '.mp4',
  '.mov',
  '.pdf',
  '.xlsx',
  '.xls',
  '.ppt',
  '.pptx',
  '.txt'
];

export const ATTACHMENT_TYPES_ACCEPTED = [
  'image/png',
  'image/jpeg',
  'image/jpg'
];

export const EQUIPMENT_FILE_TYPES_ACCEPTED = ['.mp4', '.mov'];

export const ANNOUNCEMENT_FILE_TYPES_ACCEPTED = [
  '.doc',
  '.docx',
  '.pdf',
  '.ppt',
  '.pptx',
  '.txt'
];

export const REPORT_NOTIFICATION_TYPES = [
  { label: 'Vehicle Accident', value: 'autoAccident' },
  {
    label: 'Near Miss',
    value: 'nearMiss'
  },
  {
    label: 'Third Party',
    value: 'thirdParty'
  },
  {
    label: 'Property Was Damaged',
    value: 'propertyDamage'
  },
  { label: 'Theft', value: 'theft' },
  { label: 'Aircraft', value: 'aircraft', showAircraft: true },
  { label: 'Spill/Misfuel', value: 'fuelRelated', showAircraft: true },
  { label: 'Spill', value: 'spill', showAircraft: false },
  { label: 'General Incident', value: 'general' },
  { label: 'Violence/Harassment', value: 'violence' }
];

export const FROI_NOTIFICATION_OPTIONS = [
  { label: 'Created', value: 'incidentCreated', minAccessLevel: 400 },
  {
    label: 'Marked OSHA Recordable',
    value: 'incidentMarkedOshaRecordable',
    minAccessLevel: 500
  },
  {
    label: 'Component Updated',
    value: 'incidentComponentUpdated',
    minAccessLevel: 400
  },
  {
    label: 'Component Completed',
    value: 'incidentComponentCompleted',
    minAccessLevel: 400
  },
  {
    label: 'Report Stage Changed',
    value: 'incidentStageChanged',
    minAccessLevel: 400
  },
  {
    label: 'Comment Created',
    value: 'incidentCommentCreated',
    minAccessLevel: 400
  },
  {
    label: 'External Witness Created',
    value: 'externalWitnessCreated',
    minAccessLevel: 400
  },
  {
    label: 'External Witness Submitted',
    value: 'externalWitnessCompleted',
    minAccessLevel: 400
  },
  { label: 'Closed', value: 'incidentClosed', minAccessLevel: 400 }
];

export const REPORT_NOTIFICATION_OPTIONS = [
  { label: 'Created', value: 'incidentCreated' },
  {
    label: 'Component Updated',
    value: 'incidentComponentUpdated'
  },
  {
    label: 'Component Completed',
    value: 'incidentComponentCompleted'
  },
  {
    label: 'Report Stage Changed',
    value: 'incidentStageChanged'
  },
  {
    label: 'Comment Created',
    value: 'incidentCommentCreated',
    minAccessLevel: 400
  },
  {
    label: 'External Witness Created',
    value: 'externalWitnessCreated',
    minAccessLevel: 400
  },
  {
    label: 'External Witness Submitted',
    value: 'externalWitnessCompleted',
    minAccessLevel: 400
  },
  { label: 'Closed', value: 'incidentClosed' }
];

export const RISK_ASSESSMENT_NOTIFICATION_OPTIONS = [
  // doesn't look like there are any emails assoicated with this
  { label: 'Created', value: 'safetyAuditCreated' },
  {
    label: '24h before Safety Audit Due',
    value: 'safetyAuditDueIn24h'
  },
  {
    label: 'Without Unacceptable Items',
    value: 'safetyAuditWithoutUnacceptableItems'
  },
  {
    label: 'With Unacceptable Items',
    value: 'safetyAuditWithUnacceptableItems'
  },
  {
    label: '24h Before Corrective Action Due',
    value: 'safetyAuditCADueIn24h'
  },
  // doesn't look like there are any emails assoicated with this
  // {
  //   label: 'Corrective Action Created',
  //   value: 'safetyAuditCAAdded'
  // },
  {
    label: 'Corrective Action Past Due',
    value: 'safetyAuditCAPastDue'
  },
  {
    label: 'Corrective Action Completed',
    value: 'safetyAuditCACompleted'
  }
  // doesn't look like there are any emails assoicated with this
  //{ label: 'Closed', value: 'safetyAuditClosed' }
];

export const QUALITY_AUDIT_NOTIFICATION_OPTIONS = [
  // doesn't look like there are any emails assoicated with this
  { label: 'Created', value: 'qualityAuditCreated' },
  {
    label: '24h before Quality Audit Due',
    value: 'qualityAuditDueIn24h'
  },
  {
    label: 'Without Unacceptable Items',
    value: 'qualityAuditWithoutUnacceptableItems'
  },
  {
    label: 'With Unacceptable Items',
    value: 'qualityAuditWithUnacceptableItems'
  },
  {
    label: '24h Before Corrective Action Due',
    value: 'qualityAuditCADueIn24h'
  },
  // doesn't look like there are any emails assoicated with this
  // {
  //   label: 'Corrective Action Created',
  //   value: 'qualityAuditCAAdded'
  // },
  {
    label: 'Corrective Action Past Due',
    value: 'qualityAuditCAPastDue'
  },
  {
    label: 'Corrective Action Completed',
    value: 'qualityAuditCACompleted'
  }
  // doesn't look like there are any emails assoicated with this
  //{ label: 'Closed', value: 'qualityAuditClosed' }
];

export const LOCKOUT_TAGOUT_NOTIFICATION_OPTIONS = [
  {
    label: 'Created',
    value: 'lotoAuditCreated'
  },
  {
    label: '24h before Lockout/Tagout Due',
    value: 'lotoAuditDueIn24h'
  },
  {
    label: 'Without Unacceptable Items',
    value: 'lotoAuditWithoutUnacceptableItems'
  },
  {
    label: 'With Unacceptable Items',
    value: 'lotoAuditWithUnacceptableItems'
  },
  {
    label: '24h Before Corrective Action Due',
    value: 'lotoAuditCADuein24h'
  },
  {
    label: 'Corrective Action Past Due',
    value: 'lotoAuditCAPastDue'
  },
  {
    label: 'Corrective Action Completed',
    value: 'lotoAuditCACompleted'
  }
];

export const SAFETY_WALK_NOTIFICATION_OPTIONS = [
  { label: 'Created', value: 'safetyWalkCreated' },
  {
    label: '24h Before Corrective Action Due',
    value: 'safetyWalk24hBeforeCADue'
  },
  { label: 'Corrective Action Created', value: 'safetyWalkCAAdded' },
  { label: 'Corrective Action Past Due', value: 'safetyWalkCAPastDue' },
  { label: 'Corrective Action Completed', value: 'safetyWalkCACompleted' },
  { label: 'Stage Changed', value: 'safetyWalkStageChanged' },
  { label: 'Closed', value: 'safetyWalkClosed' }
];

export const CLAIMS_NOTIFICATIONS = [
  { label: 'Employee Injury Claim Submitted', value: 'wcClaimSubmitted' },
  { label: 'Vehicle Claim Submitted', value: 'autoClaimSubmitted' },
  { label: 'Property Damage Submitted', value: 'propClaimSubmitted' }
];

export const TRAINING_NOTIFICATION_OPTIONS = [
  { label: 'Created', value: 'trainingCreated' },
  { label: 'Quiz Failed', value: 'trainingQuizFailed' },
  { label: '24h before Training Due', value: 'trainingDueIn24h' },
  {
    label: 'Training Due Date Passed',
    value: 'trainingAssignmentPassed'
  },
  { label: 'Approved', value: 'trainingApproved' },
  { label: 'Expiring Within 90 Days', value: 'trainingExpiringWithin90' },
  { label: 'Expiring Within 60 Days', value: 'trainingExpiringWithin60' },
  { label: 'Expiring Within 30 Days', value: 'trainingExpiringWithin30' },
  { label: 'Expired', value: 'trainingExpired' }
];

export const DOCUMENT_NOTIFICATION_OPTIONS = [
  //  getting rid of until we add an email for this
  // { label: 'Created', value: 'documentCreated' },
  { label: 'Expiring Within 90 Days', value: 'documentExpiringWithin90' },
  { label: 'Expiring Within 60 Days', value: 'documentExpiringWithin60' },
  { label: 'Expiring Within 30 Days', value: 'documentExpiringWithin30' }
  //  getting rid of until we add an email for this
  // { label: 'Expired', value: 'documentExpired' }
];

export const TASK_NOTIFICATION_OPTIONS = [
  { label: 'Assigned', value: 'taskAssigned' },
  { label: 'Expiring in 24h', value: 'taskExpiringIn24h' },
  { label: 'Past Due', value: 'taskPastDue' },
  { label: 'Completed', value: 'taskCompleted' }
];

export const TRAINING_CATEGORY_DROPDOWN = [
  { value: 1, label: 'OSHA Required' },
  { value: 2, label: 'Compliance Training' },
  { value: 3, label: 'Optional Training' },
  { value: 4, label: 'Other' }
];
export const TRAINING_STATUSES = [
  'Assigned',
  'In-Progress',
  'Completed',
  'Awaiting Approval',
  'Expired',
  'Past Due',
  'Future Assignment Scheduled'
];

export const BEHAVIOR_OBSERVATION_NOTIFICATION_OPTIONS = [
  {
    label: '24h before Observation Due',
    value: 'behaviorObservationDueIn24h'
  },
  {
    label: '24h Before Corrective Action Due',
    value: 'behaviorObservationCADueIn24h'
  },
  {
    label: 'Corrective Action Created',
    value: 'behaviorObservationCAAdded'
  },
  {
    label: 'Corrective Action Past Due',
    value: 'behaviorObservationCAPastDue'
  },
  {
    label: 'Corrective Action Completed',
    value: 'behaviorObservationCACompleted'
  },
  { label: 'Submitted', value: 'behaviorObservationSubmitted' }
];

export const SAFETY_SUGGESTION_NOTIFICATION_OPTIONS = [
  {
    label: 'Safety Suggestion Submitted',
    value: 'suggestionSubmitted'
  },
  {
    label: 'Safety Suggestion Completed',
    value: 'suggestionCompleted'
  },
  {
    label: 'Safety Suggestion Task Created',
    value: 'suggestionTaskCreated'
  },
  {
    label: 'Safety Suggestion Task Completed',
    value: 'suggestionTaskCompleted'
  }
];

export const ALL_NOTIFICATION_OPTIONS = [
  ...REPORT_NOTIFICATION_OPTIONS,
  ...FROI_NOTIFICATION_OPTIONS,
  ...RISK_ASSESSMENT_NOTIFICATION_OPTIONS,
  ...SAFETY_WALK_NOTIFICATION_OPTIONS,
  ...TRAINING_NOTIFICATION_OPTIONS,
  ...DOCUMENT_NOTIFICATION_OPTIONS,
  ...TASK_NOTIFICATION_OPTIONS,
  ...REPORT_NOTIFICATION_TYPES,
  ...BEHAVIOR_OBSERVATION_NOTIFICATION_OPTIONS
];

export const POLICY_PROVIDERS_OPTIONS = [
  { label: 'Liberty Mutual', value: 'libertyMutual' }
];

export const EXCEPTION_REPORTING_FREQUENCY_OPTIONS = [
  { value: 'today', label: 'Today' },
  { value: 'thisWeek', label: 'This Week' },
  { value: 'thisMonth', label: 'This Month' },
  { value: 'thisQuarter', label: 'This Quarter' },
  { value: 'thisYear', label: 'This Year' },
  { value: 'customRange', label: 'Custom Range' }
];

export const LEADERBOARD_CATEGORIES = [
  { value: 'osha', label: 'OSHA', filterEmps: false },
  { value: 'reports', label: 'Reports', filterEmps: false },
  { value: 'safetyAudits', label: 'Safety Audits', filterEmps: true },
  { value: 'qualityAudits', label: 'Quality Audits', filterEmps: true },
  {
    value: 'lotoAudits',
    label: 'Lockout/Tagout Procedures',
    filterEmps: true
  },
  { value: 'safetyWalks', label: 'Safety Walks', filterEmps: true },
  { value: 'tasks', label: 'Tasks', filterEmps: false },
  { value: 'trainings', label: 'Trainings', filterEmps: true },
  {
    value: 'behaviorObservations',
    label: 'Behavior Observations',
    filterEmps: true
  }
  // commenting out until we figure out what goes for its subcategories
  // { category: 'workersCompSpend', value: 'workersCompSpend', label: 'Workers Comp Spend' }
];

export const LEADERBOARD_SUB_CATEGORIES = [
  {
    category: 'osha',
    value: 'Number of Recordables',
    label: 'Number of Recordables',
    columnName: 'Recordables'
  },
  {
    category: 'osha',
    value: 'Number of Lost Time Cases',
    label: 'Number of Lost Time Cases',
    columnName: 'Lost Time'
  },
  {
    category: 'osha',
    value: 'Risk Rate',
    label: 'Risk Rate',
    columnName: 'Risk Rate',
    showPercentage: true
  },
  {
    category: 'reports',
    value: 'Incident Reporting Lag Time',
    label: 'Incident Reporting Lag Time',
    columnName: 'Lag Time'
  },
  {
    category: 'reports',
    value: 'Number of Near Misses',
    label: 'Number of Near Misses',
    columnName: 'Near Misses'
  },
  {
    category: 'reports',
    value: 'Vehicle Accidents by Driver',
    label: 'Vehicle Accidents by Driver',
    columnName: 'Vehicle Accidents'
  },
  {
    category: 'reports',
    value: 'Property Damage by Involved Employee',
    label: 'Property Damage by Involved Employee',
    columnName: 'Property Damage'
  },
  {
    category: 'safetyAudits',
    value: 'Safety Audit Completion',
    label: 'Safety Audit Completion',
    columnName: 'Safety Audits'
  },
  {
    category: 'safetyAudits',
    value: 'Safety Audit On-Time Completion',
    label: 'Safety Audit On-Time Completion',
    columnName: 'Safety Audits'
  },
  {
    category: 'safetyAudits',
    value: 'Safety Audit Scores',
    label: 'Safety Audit Scores',
    columnName: 'Audit Score',
    showPercentage: true
  },
  {
    category: 'qualityAudits',
    value: 'Quality Audit Completion',
    label: 'Quality Audit Completion',
    columnName: 'Quality Audits'
  },
  {
    category: 'qualityAudits',
    value: 'Quality Audit On-Time Completion',
    label: 'Quality Audit On-Time Completion',
    columnName: 'Quality Audits'
  },
  {
    category: 'qualityAudits',
    value: 'Quality Audit Scores',
    label: 'Quality Audit Scores',
    columnName: 'Audit Score',
    showPercentage: true
  },
  {
    category: 'lotoAudits',
    value: 'Lockout/Tagout Procedure Completion',
    label: 'Lockout/Tagout Procedure Completion',
    columnName: 'Procedures'
  },
  {
    category: 'lotoAudits',
    value: 'Lockout/Tagout Procedure On-Time Completion',
    label: 'Lockout/Tagout Procedure On-Time Completion',
    columnName: 'Procedures'
  },
  {
    category: 'lotoAudits',
    value: 'Lockout/Tagout Procedure Scores',
    label: 'Lockout/Tagout Procedure Scores',
    columnName: 'Procedure Scores',
    showPercentage: true
  },
  {
    category: 'safetyWalks',
    value: 'Safety Walks Performed',
    label: 'Safety Walks Performed',
    columnName: 'Safety Walks'
  },
  {
    category: 'safetyWalks',
    value: 'Hazards Identified',
    label: 'Hazards Identified',
    columnName: 'Hazards'
  },
  {
    category: 'safetyWalks',
    value: 'Hazards Given Vs Completed',
    label: 'Hazards Given Vs Completed',
    columnName: 'Completion',
    showPercentage: true
  },
  {
    category: 'safetyWalks',
    value: 'Bravos Received',
    label: 'Bravos Received',
    columnName: 'Bravos'
  },
  {
    category: 'safetyWalks',
    value: 'Bravos Given',
    label: 'Bravos Given',
    columnName: 'Bravos'
  },
  {
    category: 'tasks',
    value: 'Task Completion',
    label: 'Task Completion',
    columnName: 'Tasks'
  },
  {
    category: 'tasks',
    value: 'Tasks On Time Completion',
    label: 'Tasks On Time Completion',
    columnName: 'Tasks'
  },
  {
    category: 'trainings',
    value: 'Number of Trainings Completed',
    label: 'Number of Trainings Completed',
    columnName: 'Trainings'
  },
  {
    category: 'trainings',
    value: 'Most Missed Quiz Questions',
    label: 'Most Missed Quiz Questions',
    columnName: 'Trainings'
  },
  {
    category: 'behaviorObservations',
    value: 'Number of Observations',
    label: 'Number of Observations',
    columnName: 'Observations'
  }
  // commenting out until we figure out what goes here
  // { category: 'workersCompSpend', value: 'Workers Comp Spendd', label: 'Workers Comp Spend' }
];

export const LEADERBOARD_SUB_CATEGORIES_TOOLTIPS = [
  {
    value: 'The number of OSHAs who are completed',
    label: 'Number of Recordable OSHA Cases'
  },
  {
    value:
      'The number of OSHAs who have days away from work or days on job restriction',
    label: 'Number of Lost Time Cases'
  },
  {
    value:
      'The percentage of the number of Yes answers for Regulatory Classification or the Would this incident have resulted in an OSHA Violation',
    label: 'Risk Rate'
  },
  {
    value: 'How many days it took to report the incident',
    label: 'Incident Reporting Lag Time'
  },
  {
    value:
      'The locations or employees who have at least 1 safety audit completed',
    label: 'Safety Audit Completion'
  },
  {
    value:
      'The locations or employees who have at least 1 safety audit completed on time',
    label: 'Safety Audit On-Time Completion'
  },
  {
    value:
      'The locations or employees who have at least 1 safety audit completed',
    label: 'Safety Audit Scores'
  },
  {
    value:
      'The locations or employees who have at least 1 quality audit completed',
    label: 'Quality Audit Completion'
  },
  {
    value:
      'The locations or employees who have at least 1 quality audit completed on time',
    label: 'Quality Audit On-Time Completion'
  },
  {
    value:
      'The locations or employees who have at least 1 quality audit completed',
    label: 'Quality Audit Scores'
  },
  {
    value:
      'The locations or employees who have at least 1 lockout/tagout procedure completed',
    label: 'Lockout/Tagout Procedure Completion'
  },
  {
    value:
      'The locations or employees who have at least 1 lockout/tagout procedure completed on time',
    label: 'Lockout/Tagout Procedure Completion'
  },
  {
    value:
      'The locations or employees who have at least 1 lockout/tagout procedure completed',
    label: 'Lockout/Tagout Procedure Scores'
  },
  {
    value: 'The percentage of hazards completed versus hazards indentified',
    label: 'Hazards Given Vs Completed'
  },
  {
    value: 'The locations or employees who have at least 1 task completed',
    label: 'Task Completion'
  },
  {
    value:
      'The locations or employees who have at least 1 task completed on time',
    label: 'Tasks On Time Completion'
  },
  {
    value:
      'The number of individual trainings marked as complete and approved.',
    label: 'Number of Trainings Completed'
  },
  {
    value:
      'The locations or supervisor who have completed at least one behavior observation',
    label: 'Number of Observations'
  }
  // commenting out until we figure out what goes here
  // {  value: 'Workers Comp Spendd', label: 'Workers Comp Spend' }
];

export const AUTOSAVE_TIME = 120000;

export const SAFETY_AUDIT_STAGES = [
  {
    value: 0,
    label: 'Assigned'
  },
  {
    value: 1,
    label: 'In Progress'
  },
  {
    value: 2,
    label: 'Severity Review'
  },
  {
    value: 3,
    label: 'Closed'
  }
];

export const SAFETY_AUDIT_QUESTION_TYPES = [
  { label: 'Yes/No', value: 'yesNo' },
  { label: 'Dropdown', value: 'dropdown', hasOptions: true },
  { label: 'Multiselect', value: 'multiselect', hasOptions: true },
  { label: 'Text Field', value: 'textbox' },
  { label: 'Employee List', value: 'multiselectPersonnel' },
  { label: 'Table', value: 'table' },
  { label: 'Signature', value: 'signature' }
];

export const PRE_MADE_DOCUMENTS = [
  { label: 'Recurrence', svg: 'sds', value: 'rec' },
  { label: 'Transitional', svg: 'sds', value: 'trans' },
  { label: 'Personal Exposure', svg: 'sds', value: 'exposure' }
];

export const DOCUMENT_TYPES = [
  { label: 'Premade Document', svg: 'sds', value: 'premade' },
  { label: 'Upload Document', svg: 'upload', value: 'upload' }
];

export const SAFETY_AUDIT_EXPORT_OPTIONS = [
  { label: 'Summary', value: 'Summary' },
  { label: 'Attachments', value: 'Attachments' },
  { label: 'Notes', value: 'Notes' },
  { label: 'Corrective Action Status', value: 'Corrective Action Status' }
];
