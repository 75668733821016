import React from 'react';
import { BRAVO_CLASSIFICATION } from '../../../constants/constants';
import {
  Dropdown,
  EmployeeDropdown,
  Textbox
} from '../../../components/inputs';

function BravoInfoCard({
  description = '',
  bravosGiven = [],
  bravoClassification,
  otherBravoClassification = '',
  updateBravo,
  editing,
  missingRequired
}) {
  return (
    <>
      <Dropdown
        options={BRAVO_CLASSIFICATION?.map(o => ({ label: o, value: o }))}
        searchable
        isRequired
        fieldLabel="Bravo Classification"
        currentValue={bravoClassification}
        bareValues
        onChange={v => updateBravo({ bravoClassification: v })}
        disabled={!editing}
        touched={missingRequired && !bravoClassification}
      />
      {bravoClassification === 'Other' ? (
        <Textbox
          fieldLabel="Name of other bravo classification"
          isRequired
          onChange={v => updateBravo({ otherBravoClassification: v })}
          currentValue={otherBravoClassification}
          disabled={!editing}
          touched={missingRequired && !otherBravoClassification?.trim()}
        />
      ) : null}
      <Textbox
        type="textarea"
        fieldLabel="Description"
        placeholder="Describe this Bravo. Be sure to include any important details."
        isRequired
        onChange={v => updateBravo({ description: v })}
        currentValue={description}
        disabled={!editing}
        touched={missingRequired && !description}
        error
      />
      <EmployeeDropdown
        multi
        fieldLabel="Assign Bravo"
        currentValue={bravosGiven}
        onChange={v => updateBravo({ bravosGiven: v })}
        disabled={!editing}
        searchable
        selectButtons
        name="multiEmployees"
        filterChoices={[
          {
            label: 'Include Former Employees',
            value: 'inactive'
          }
        ]}
      />
    </>
  );
}

export default BravoInfoCard;
