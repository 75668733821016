import React, { isValidElement } from 'react';
import classnames from 'classnames/bind';
import { Tooltip } from 'react-tooltip';
import { noBubble } from '../../../utils/events';
import Input from '../';

import styles from './buttonSelector.module.scss';
const bStyles = classnames.bind(styles);

export const ButtonSelector = ({
  options,
  className,
  noSpacing,
  currentValue,
  onClick,
  touched,
  ...props
}) => {
  const selected = options.find(o => o.value === currentValue);
  let classes = bStyles(className, {
    container: true,
    noSpacing
  });
  return (
    <Input className={classes} {...props}>
      <div>
        {options.map(o => {
          if ('visible' in o && !o.visible) return <></>;
          let image = isValidElement(o.image) ? (
            o.image
          ) : (
            <img src={o.image} alt={o.text} />
          );
          let buttonClasses = bStyles(
            o.color || 'blue',
            o.value === currentValue && 'active',
            {
              error: touched
            }
          );
          return (
            <>
              {o?.tooltipMessage ? (
                <Tooltip className="customTheme" id={o.value.toString()}>
                  {o?.tooltipMessage}
                </Tooltip>
              ) : null}
              <span data-tooltip-id={o.value.toString()}>
                <button
                  key={o.value}
                  className={buttonClasses}
                  onClick={e => {
                    noBubble(e);
                    if (onClick) onClick(o.value);
                  }}
                  disabled={o.disabled}
                  type="button"
                >
                  <div>
                    {image}
                    <span>{o.text}</span>
                  </div>
                </button>
              </span>
            </>
          );
        })}
        {selected?.description && (
          <span className={styles.bold}>{selected.description}</span>
        )}
      </div>
    </Input>
  );
};

export default ButtonSelector;
