import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { fetchCompanyUsers, getJobList } from '../../../../../api/v4';
import {
  canSubmitCompletedInPerson,
  canSubmitToDoInPerson,
  getValidAssignees
} from '../../../../../utils/trainingHelper';

import {
  Button,
  Dropdown,
  EmployeeDropdown,
  TwoColumn
} from '../../../../inputs';
import Card from '../../../../Card';
import HierarchySelector from '../../../../HierarchySelector';
import { IReportDatePicker } from '../../../../inputs/DateTimePicker';
import RadioButtons from '../../../../inputs/RadioButtons';

import styles from '../scheduleCard.module.scss';

export default function ScheduleInPersonCard(props) {
  const {
    training,
    onAnswer,
    setCanSubmit,
    company,
    disabled,
    hideWarnings = false,
    editingAssignees,
    missingRequired
  } = props;
  const [jobList, setJobList] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (training.assignBy === 1) {
      getJobList().then(r => {
        setJobList(r?.map(job => ({ value: job, label: job })));
      });
    }
  }, [training.assignBy]);

  const setSubmit = useCallback(() => {
    if (!setCanSubmit) return;

    // trainers to do
    if (training.schedule === 1) setCanSubmit(canSubmitToDoInPerson(training));
    // track attendence
    else setCanSubmit(canSubmitCompletedInPerson(training));
  }, [setCanSubmit, training]);

  useEffect(() => {
    setSubmit();
  }, [training, setSubmit]);

  useEffect(() => {
    fetchCompanyUsers().then(setEmployees);
  }, []);

  const renderByJobTitle = () => {
    const groupsSelected = training?.groupAssigneePairs?.map(g => g.groupId);
    return disabled ? (
      <>
        <HierarchySelector
          groupId={training?.groupIds}
          groupDisabled
          showProject={false}
          noTwoColumn
          alwaysShowGroupDropdown
        />
        <Dropdown
          selectButtons
          fieldLabel="Job Title(s)"
          options={jobList}
          searchable
          currentValue={training?.assignByJobTitles}
          isRequired
          multi
          disabled
        />
        <EmployeeDropdown
          fieldLabel="Assignee(s)"
          currentValue={training?.assignedTo}
          searchable
          name="dropdownEmployees"
          isRequired
          multi
          disabled
          filterChoices={
            disabled
              ? null
              : [
                  {
                    label: 'Include Former Employees',
                    value: 'inactive'
                  }
                ]
          }
        />
      </>
    ) : (
      <>
        {training.groupAssigneePairs?.map((group, index) => {
          return (
            <TwoColumn className={styles.specialGrid}>
              <TwoColumn>
                <HierarchySelector
                  groupId={group?.groupId}
                  onGroupChange={groupId => {
                    let newGroup = {
                      ...training.groupAssigneePairs[index],
                      groupId
                    };
                    training.groupAssigneePairs[index] = newGroup;
                    onAnswer({
                      groupAssigneePairs: training.groupAssigneePairs
                    });
                  }}
                  groupDisabled={disabled || training.equipmentId}
                  showProject={false}
                  noTwoColumn
                  groupTouched={missingRequired && !group?.groupId}
                  excludeGroups={groupsSelected?.filter(
                    g => g !== group.groupId
                  )}
                  alwaysShowGroupDropdown
                />

                <Dropdown
                  selectButtons
                  fieldLabel="Job Title(s)"
                  options={jobList}
                  searchable
                  onChange={jobTitles => {
                    let newTitles = {
                      ...training.groupAssigneePairs[index],
                      jobTitles
                    };
                    training.groupAssigneePairs[index] = { ...newTitles };
                    onAnswer({
                      groupAssigneePairs: training.groupAssigneePairs
                    });
                  }}
                  currentValue={group.jobTitles}
                  isRequired
                  multi
                  disabled={disabled}
                  touched={missingRequired && !group?.jobTitles?.length}
                />
              </TwoColumn>
              {training.groupAssigneePairs?.length > 1 && (
                <img
                  type="button"
                  src={require('../../../../../assets/images/remove.png')}
                  alt="delete group"
                  onClick={() => {
                    training.groupAssigneePairs.splice(index, 1);
                    onAnswer({
                      groupAssigneePairs: training.groupAssigneePairs
                    });
                  }}
                  className={styles.removeImage}
                  data-cy="removeButton"
                />
              )}
            </TwoColumn>
          );
        })}
        <Button
          text="Add Group"
          color={
            missingRequired && !training?.groupAssigneePairs?.length
              ? 'red'
              : 'blue'
          }
          onClick={() =>
            onAnswer({
              groupAssigneePairs: (training.groupAssigneePairs ?? []).concat({})
            })
          }
          inputClassName={styles.button}
          disabled={disabled}
        />
      </>
    );
  };

  const renderByEmployee = () => {
    const groupsSelected = training?.groupAssigneePairs?.map(g => g.groupId);
    return disabled ? (
      <>
        <HierarchySelector
          groupId={training?.groupIds}
          groupDisabled
          showProject={false}
          noTwoColumn
          alwaysShowGroupDropdown
        />
        <EmployeeDropdown
          fieldLabel="Assignee(s)"
          currentValue={training?.assignedTo}
          searchable
          name="dropdownEmployees"
          isRequired
          multi
          disabled
        />
      </>
    ) : (
      <>
        {training?.schedule === 2 ? null : (
          <div className={styles.adminContainer}>
            <span className={styles.adminInfo}>
              One training will be created for each group/establishment selected
            </span>
          </div>
        )}
        {training.groupAssigneePairs?.map((group, index) => {
          return (
            <TwoColumn className={styles.specialGrid}>
              <TwoColumn>
                <HierarchySelector
                  groupId={group?.groupId ?? group?.groupIds}
                  onGroupChange={groupId => {
                    let newGroup = {
                      ...training.groupAssigneePairs[index],
                      groupId
                    };
                    newGroup.assignees = getValidAssignees(newGroup, employees);
                    training.groupAssigneePairs[index] = newGroup;
                    onAnswer({
                      groupAssigneePairs: training.groupAssigneePairs
                    });
                  }}
                  groupDisabled={disabled}
                  showProject={false}
                  noTwoColumn
                  groupTouched={missingRequired && !group?.groupId}
                  excludeGroups={groupsSelected?.filter(
                    g => g !== group.groupId
                  )}
                  alwaysShowGroupDropdown
                />

                <EmployeeDropdown
                  fieldLabel="Assignee(s)"
                  currentValue={group.assignees}
                  onChange={assignees => {
                    let newAdmin = {
                      ...training.groupAssigneePairs[index],
                      assignees
                    };
                    training.groupAssigneePairs[index] = { ...newAdmin };
                    onAnswer({
                      groupAssigneePairs: training.groupAssigneePairs
                    });
                  }}
                  searchable
                  name="dropdownEmployees"
                  isRequired
                  multi
                  disabled={disabled}
                  touched={missingRequired && !group?.assignees?.length}
                  group={group?.groupId}
                  selectButtons
                  filterChoices={[
                    {
                      label: 'Include Former Employees',
                      value: 'inactive'
                    }
                  ]}
                />
              </TwoColumn>
              {training.groupAssigneePairs?.length > 1 && (
                <img
                  type="button"
                  src={require('../../../../../assets/images/remove.png')}
                  alt="delete group"
                  onClick={() => {
                    training.groupAssigneePairs.splice(index, 1);
                    onAnswer({
                      groupAssigneePairs: training.groupAssigneePairs
                    });
                  }}
                  className={styles.removeImage}
                  data-cy="removeButton"
                />
              )}
            </TwoColumn>
          );
        })}
        {training?.schedule === 2 ? null : (
          <Button
            text="Add Group"
            color={
              missingRequired && !training?.groupAssigneePairs?.length
                ? 'red'
                : 'blue'
            }
            onClick={() =>
              onAnswer({
                groupAssigneePairs: (training.groupAssigneePairs ?? []).concat(
                  {}
                )
              })
            }
            inputClassName={styles.button}
            disabled={disabled}
          />
        )}
      </>
    );
  };

  return (
    <Card showHeader title="Schedule" name="schedule">
      {training?.schedule === 2 ? (
        <>
          <RadioButtons
            options={[
              { value: 1, label: 'Job Title' },
              { value: 2, label: 'Employee' }
            ]}
            currentValue={training.assignBy}
            onChange={v =>
              onAnswer({
                assignBy: v,
                groupAssigneePairs: training?.schedule === 1 ? [] : [{}]
              })
            }
            fieldLabel="Assign By"
            needSeparateLines
            isRequired
            disabled={disabled && !editingAssignees}
            touched={missingRequired && !training.assignBy}
          />
          {disabled ? null : (
            <div className={styles.adminContainer}>
              <span className={styles.adminInfo}>
                One training will be created for each group/establishment
                selected
              </span>
            </div>
          )}
          {training?.assignBy === 1 ? renderByJobTitle() : renderByEmployee()}
          <IReportDatePicker
            fieldLabel="Completed On"
            isRequired
            onChange={v => onAnswer({ completionDate: v })}
            currentValue={training.completionDate}
            touched={missingRequired && !training.completionDate}
            name="completionDate"
            disabled={disabled}
            maxDate={new Date()}
          />
        </>
      ) : (
        <>
          <IReportDatePicker
            fieldLabel="Assign On"
            isRequired
            onChange={v =>
              onAnswer({
                assignOn: v,
                dueDate: moment(v).isAfter(training.dueDate)
                  ? null
                  : training.dueDate
              })
            }
            currentValue={training.assignOn}
            touched={missingRequired && !training.assignOn}
            name="assignOn"
            disabled={disabled}
            minDate={new Date()}
          />
          <IReportDatePicker
            fieldLabel="Due By"
            isRequired
            onChange={v => onAnswer({ dueDate: v })}
            currentValue={training.dueDate}
            touched={
              !hideWarnings &&
              missingRequired &&
              training.assignOn &&
              !training.dueDate
            }
            name="dueBy"
            disabled={(disabled || !training.assignOn) && !editingAssignees}
            minDate={new Date(moment(training.assignOn).add(1, 'days'))}
          />
          {training?.quizMasterId || training?.collectSignatures ? (
            <RadioButtons
              options={[
                { value: 1, label: 'On To Do List' },
                { value: 2, label: 'As a QR Code' }
              ]}
              currentValue={training.releaseType}
              onChange={v => onAnswer({ releaseType: v })}
              fieldLabel={`${
                training.quizMasterId && training.collectSignatures
                  ? 'Quiz Release and Signature Collection'
                  : training.collectSignatures
                    ? 'Signature Collection'
                    : 'Quiz Release'
              }`}
              needSeparateLines
              isRequired
              disabled={disabled}
              touched={missingRequired && !training.releaseType}
            />
          ) : null}
          {training.isAdministered || training.isCompleted ? (
            <EmployeeDropdown
              fieldLabel="Attendees"
              permissions={[100, 400, 500, 900]}
              onChange={v =>
                onAnswer({
                  assignedTo: v
                })
              }
              disabled
              multi
              group={training.groupIds?.length ? training.groupIds : null}
              currentValue={training.assignedTo}
              isRequired
              touched={missingRequired && !training.assignedTo?.length}
            />
          ) : null}
          <>
            <div className={styles.adminContainer}>
              <span className={styles.adminInfo}>
                One training will be created for each group/establishment
                selected
              </span>
            </div>
            {training.admins?.map((admin, index) => {
              return (
                <TwoColumn className={styles.specialGrid}>
                  <TwoColumn>
                    <EmployeeDropdown
                      permissions={[400, 500, 900]}
                      fieldLabel="Administrator"
                      currentValue={admin.adminId}
                      exclude={[
                        ...training.admins
                          .filter(a => a.adminId !== admin.adminId)
                          .map(a => a.adminId)
                      ]}
                      onChange={adminId => {
                        let newAdmin = { ...training.admins[index], adminId };
                        training.admins[index] = { ...newAdmin };
                        onAnswer({ admins: training.admins });
                      }}
                      searchable
                      name="dropdownEmployees"
                      isRequired
                      disabled={disabled}
                      touched={missingRequired && !admin.adminId}
                    />
                    <Dropdown
                      multi
                      selectButtons
                      searchable
                      options={company.groups
                        .filter(g => {
                          const adminGroups = employees.find(
                            e => e._id === admin.adminId
                          )?.groupIds;
                          return adminGroups?.includes(g._id);
                        })
                        ?.map(g => ({
                          value: g._id,
                          label: g.name
                        }))}
                      fieldLabel="Group or Establishment"
                      currentValue={admin.groupIds}
                      onChange={groupIds => {
                        let newGroup = { ...training.admins[index], groupIds };
                        training.admins[index] = newGroup;
                        onAnswer({ admins: training.admins });
                      }}
                      isRequired
                      disabled={disabled || !admin.adminId}
                      touched={
                        missingRequired &&
                        (!disabled || admin.adminId) &&
                        !admin.groupIds
                      }
                    />
                  </TwoColumn>
                  {training.admins?.length > 1 && (
                    <img
                      type="button"
                      src={require('../../../../../assets/images/remove.png')}
                      alt="delete admin"
                      onClick={() => {
                        training.admins.splice(index, 1);
                        onAnswer({ admins: training.admins });
                      }}
                      className={styles.removeImage}
                      data-cy="removeButton"
                    />
                  )}
                </TwoColumn>
              );
            })}
            <Button
              text="Add Administrator"
              color={
                missingRequired && !training.admins?.length ? 'red' : 'blue'
              }
              onClick={() =>
                onAnswer({ admins: (training.admins ?? []).concat({}) })
              }
              inputClassName={styles.button}
              disabled={disabled}
            />
          </>
        </>
      )}
    </Card>
  );
}
