import React from 'react';
import { Textbox } from '../../components/inputs';
import PasswordValidation from '../../components/PasswordValidation';

export default function ChangePasswordForm(props) {
  const {
    newPassword,
    confirmPassword,
    setNewPassword,
    setConfirmPassword,
    missingRequired
  } = props;

  return (
    <>
      <Textbox
        fieldLabel="New Password"
        onChange={setNewPassword}
        currentValue={newPassword}
        isRequired
        type="password"
        error
        touched={missingRequired && !newPassword?.trim().length}
      />
      <PasswordValidation
        isNewPassword
        newPassword={newPassword}
        confirmPassword={confirmPassword}
      />
      <Textbox
        fieldLabel="Confirm New Password"
        onChange={setConfirmPassword}
        currentValue={confirmPassword}
        isRequired
        type="password"
        error
        touched={missingRequired && !confirmPassword?.trim().length}
      />
      <PasswordValidation
        newPassword={newPassword}
        confirmPassword={confirmPassword}
      />
    </>
  );
}
