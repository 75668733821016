import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { uploadAttachment, deleteAttachment } from '../../../api';
import {
  fetchEmployee,
  createClaim,
  updateClaim,
  deleteClaim,
  fetchClaimById,
  printClaim
} from '../../../api/v4';
import config from '../../../config/config';
import history from '../../../history';
import { setActiveIncidentRequest } from '../../../actions/incidents';
import { getActiveIncidentSelector } from '../../../selectors/incidents';
import { getActiveOshaLogSelector } from '../../../selectors/oshaLogs';
import { getMyUserId } from '../../../selectors/users';
// import { formatFiles } from '../../../utils/attachmentSelector';
import { normalizeLibertyMutualPhone } from '../../../utils/formValidation';
import { useActiveHeirarchy } from '../../../utils/useActiveHeirarchy';
import customToast from '../../../utils/customToast';
// import AttachmentUploadCard from '../../../components/AttachmentUploadCard';
import ClaimCard from '../../../components/claims/Cards/libertyMutual';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import InstructionCard from '../../../components/claims/InstructionCard';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';
import Modal from '../../../components/Modal';
import LeftNav from '../../../components/LeftNav';
import { SubmitSaveForLaterCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';

import styles from '../claimCreate.module.scss';

export default function WcClaim() {
  const activeIncident = useSelector(getActiveIncidentSelector);
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const activeOshaLog = useSelector(getActiveOshaLogSelector);
  const myUserId = useSelector(getMyUserId);
  const employeeId =
    activeIncident?.answers?.[config.staticIds.froiInjuredEmployeeQuestion];
  let { claimId } = useParams();
  const dispatch = useDispatch();

  const [id, setId] = useState(null);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [injuryInfo, setInjuryInfo] = useState({});
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [accidentInfo, setAccidentInfo] = useState({});
  const [policyInfo, setPolicyInfo] = useState([]);
  const [overviewInfo, setOverviewInfo] = useState({});
  const [creating, setCreating] = useState(false);
  const [employeeEditing, setEmployeeEditing] = useState(false);
  const [injuryEditing, setInjuryEditing] = useState(false);
  const [accidentEditing, setAccidentEditing] = useState(false);
  const [policyEditing, setPolicyEditing] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveChangesModal, setSaveChangesModal] = useState(false);
  const [sumbitModal, setSubmitModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [incidentId, setIncidentId] = useState();
  const [availablePolicies, setAvailablePolicies] = useState();
  const [attachments, setAttachments] = useState([]);
  useEffect(() => {
    if (!claimId) {
      setCreating(true);
      fetchEmployee(employeeId[0]?.value ?? employeeId[0]).then(employee => {
        const oshaAnswers = activeOshaLog[0]?.questions;
        let deathDate;
        if (oshaAnswers) {
          deathDate = oshaAnswers?.filter(o => o.key === 'dateOfDeath')?.value;
        }

        setEmployeeInfo({
          employeeId: employeeId,
          employeeFirstName: employee?.firstName,
          employeeLastName: employee?.lastName,
          employeeGender: employee?.gender,
          employeePhone: normalizeLibertyMutualPhone(employee?.phoneNumber),
          streetAddress: employee?.address?.streetAddress,
          city: employee?.address?.city,
          state: employee?.address?.stateName,
          zip: employee?.address?.zip,
          employeeOccupation: employee?.jobTitle,
          employeeDateOfHire: employee?.dateOfHire,
          employeeDeathDate: deathDate,
          dateOfBirth: employee?.dateOfBirth
        });
      });

      if (activeProject) {
        setAccidentInfo({
          projectId: activeProject._id,
          groupId: activeGroup._id
        });
        setAvailablePolicies(activeCompany.policies);
        setPermissions(activeCompany.claimPermissions);
      } else if (activeGroup) {
        setAccidentInfo({ groupId: activeGroup._id });
        setAvailablePolicies(activeCompany.policies);
        setPermissions(activeCompany.claimPermissions);
      } else if (activeCompany) {
        setAvailablePolicies(activeCompany.policies);
        setPermissions(activeCompany.claimPermissions);
      }

      const answers = activeIncident?.answers;
      if (answers) {
        let incidentLocation =
          answers[config.staticIds.incidentLocation] &&
          !answers?.[config.staticIds.incidentLocation].includes(
            'Not Applicable'
          )
            ? answers?.[config.staticIds.incidentLocation]?.split(', ')
            : '';

        if (!incidentLocation) {
          const location = activeCompany.groups.find(
            g => g._id === activeIncident.groupId
          );
          incidentLocation = [
            location.streetAddress,
            location.city,
            location.state,
            location.zip
          ];
        }
        setAccidentInfo({
          incidentDate: answers[config.staticIds.timeIncidentOccured],
          incidentDescription: answers[config.staticIds.incidentDescription],
          streetAddress: incidentLocation?.[0],
          city: incidentLocation?.[1],
          state: incidentLocation?.[2],
          zip: incidentLocation?.[3] === 'USA' ? null : incidentLocation?.[3],
          groupId: activeIncident.groupId,
          projectId: activeIncident.projectId
        });

        setInjuryInfo({
          sourceOfInjury: answers[config.staticIds.froiObjectSubstance],
          injuryDescription: answers[config.staticIds.froiWorkerDoing]
        });
      }
    } else {
      fetchClaimById(claimId).then(claim => {
        setClaimInfo(claim);
      });
      setAvailablePolicies(activeCompany.policies);
      setPermissions(activeCompany.claimPermissions);
    }
  }, [
    activeIncident,
    activeOshaLog,
    claimId,
    activeCompany,
    employeeId,
    activeGroup,
    activeProject
  ]);

  const setClaimInfo = claim => {
    setId(claim._id);
    setIsSubmitted(claim.isSubmitted);
    setIncidentId(claim.incidentId);
    setOverviewInfo({
      claimNumber: claim.claimNumber,
      previousClaims: claim.previousClaims,
      submissionDate: claim.submissionDate,
      insuredReportNumber: claim.insuredReportNumber
    });
    setEmployeeInfo({
      employeeId: claim.employeeId,
      employeeFirstName: claim.employeeFirstName,
      employeeLastName: claim.employeeLastName,
      employeeGender: claim.employeeGender,
      employeePhone: claim.employeePhone,
      employeeSSN: claim?.employeeSSN,
      streetAddress: claim.employeeAddress?.streetAddress,
      city: claim.employeeAddress?.city,
      state: claim.employeeAddress?.state,
      zip: claim.employeeAddress?.zip,
      employeeOccupation: claim.employeeOccupation,
      employeeDateOfHire: claim.employeeDateOfHire,
      employeeDeathDate: claim.employeeDeathDate,
      employeeMaritalStatus: claim.employeeMaritalStatus,
      employmentStatus: claim.employmentStatus,
      numberOfDaysWorkedPerWeek: claim.numberOfDaysWorkedPerWeek,
      numberOfHoursWorkedPerWeek: claim.numberOfHoursWorkedPerWeek,
      dateOfBirth: claim.employeeDateOfBirth
    });
    setAccidentInfo({
      groupId: claim.groupId,
      projectId: claim.projectId,
      incidentDate: claim.incidentDate,
      streetAddress: claim.incidentLocation?.incidentStreetAddress,
      city: claim.incidentLocation?.incidentCity,
      state: claim.incidentLocation?.incidentState,
      zip: claim.incidentLocation?.incidentZip,
      incidentLocationCounty: claim.incidentLocationCounty,
      incidentLocationDescription: claim.incidentLocationDescription,
      incidentDescription: claim.incidentDescription,
      claimReportedByTitle: claim.claimReportedByTitle,
      claimJurisdictionCode: claim.claimJurisdictionCode,
      claimNotificationOther: claim.claimNotificationOther,
      reportingClaimAsRecordOnlyIndicator:
        claim.reportingClaimAsRecordOnlyIndicator ? 'yes' : 'no'
    });
    setInjuryInfo({
      bodyPartAffected: claim.bodyPartAffected,
      natureOfInjury: claim.natureOfInjury,
      injuryCauseCode: claim.injuryCauseCode,
      sourceOfInjury: claim.sourceOfInjury,
      injuryDescription: claim.injuryDescription,
      initialTreatment: claim.initialTreatment,
      wagesPaidForDateOfInjury: claim.wagesPaidForDateOfInjury,
      absenceFromWorkDueToInjury: claim.absenceFromWorkDueToInjury,
      lastDayWorkedDate: claim.lastDayWorkedDate,
      firstDayAbsentDate: claim.firstDayAbsentDate,
      wagesContinuedWhileAbsent: claim.wagesContinuedWhileAbsent ? 'yes' : 'no',
      returnToWorkDate: claim.returnToWorkDate,
      returedToWorkDuties: claim.returnToWorkDuties,
      averageWeeklyWage: claim.averageWeeklyWage
    });
    setPolicyInfo({
      policyEffectiveDate: claim.policyId?.policyEffectiveDate,
      policyEndDate: claim.policyId?.policyExpirationDate,
      displayPolicyNumber: claim.policyId?.displayPolicyNumber,
      lineOfBusinessCode: claim.policyId?.lineOfBusinessCode,
      policyId: claim.policyId?._id
    });
    setPermissions(claim.permissions);
    setAttachments(claim.attachments);
  };

  const handleSubmit = isSubmitted => {
    const object = {
      type: 'wc',
      ...employeeInfo,
      employeeAddress: {
        streetAddress: employeeInfo.streetAddress,
        city: employeeInfo.city,
        state: employeeInfo.state,
        zip: employeeInfo.zip
      },
      ...injuryInfo,
      wagesContinuedWhileAbsent: injuryInfo.wagesContinuedWhileAbsent === 'yes',
      ...accidentInfo,
      incidentLocation: {
        incidentStreetAddress: accidentInfo.streetAddress,
        incidentCity: accidentInfo.city,
        incidentState: accidentInfo.state,
        incidentZip: accidentInfo.zip
      },
      reportingClaimAsRecordOnlyIndicator:
        accidentInfo.reportingClaimAsRecordOnlyIndicator === 'yes',
      ...policyInfo,
      incidentId: activeIncident._id,
      oshaFormId: activeOshaLog?.[0]?._id,
      isSubmitted,
      permissions: activeCompany.claimPermissions,
      attachments
    };
    if (!id) {
      createClaim(object).then(claim => {
        setClaimInfo(claim);
        setId(claim._id);
        setSubmitModal(false);
        setUnsavedChanges(false);
        setCreating(false);
        if (claim.isSubmitted) {
          customToast('Submitted Successfully!', 'success');
        } else {
          customToast('Saved Successfully!', 'success');
        }
      });
    } else {
      updateClaim({
        _id: id,
        ...object
      }).then(claim => {
        setEditingFalse();
        setClaimInfo(claim);
        setSubmitModal(false);
        if (claim.isSubmitted) {
          customToast('Submitted Successfully!', 'success');
        } else {
          customToast('Saved Successfully!', 'success');
        }
      });
    }
  };

  const setEditingFalse = () => {
    setAccidentEditing(false);
    setInjuryEditing(false);
    setEmployeeEditing(false);
    setPolicyEditing(false);
    setUnsavedChanges(false);
  };

  const handleDelete = () => {
    deleteClaim(id).then(() => history.goBack());
  };

  const handleCancel = goBack => {
    if (goBack) history.goBack();
    if (
      id &&
      (accidentEditing || injuryEditing || employeeEditing || policyEditing)
    ) {
      setEditingFalse();
    } else if (unsavedChanges && !saveChangesModal) {
      setSaveChangesModal(true);
    } else {
      history.goBack();
    }
  };

  const print = () => {
    printClaim(id).then(response => window.open(response, '_blank'));
  };

  const employeeInfoComplete = !(
    !employeeInfo.employeeGender ||
    !employeeInfo.employeeSSN ||
    !employeeInfo.employeePhone ||
    !employeeInfo.streetAddress ||
    !employeeInfo.city ||
    !employeeInfo.state ||
    !employeeInfo.zip ||
    !employeeInfo.employeeDateOfHire ||
    !employeeInfo.employeeOccupation ||
    !employeeInfo.employeeMaritalStatus ||
    !employeeInfo.employmentStatus ||
    !employeeInfo.numberOfDaysWorkedPerWeek ||
    employeeInfo.numberOfDaysWorkedPerWeek > 7 ||
    employeeInfo.numberOfDaysWorkedPerWeek < 0 ||
    !employeeInfo.numberOfHoursWorkedPerWeek
  );

  const injuryInfoComplete = !(
    !injuryInfo.bodyPartAffected ||
    !injuryInfo.natureOfInjury ||
    !injuryInfo.injuryCauseCode ||
    !injuryInfo.sourceOfInjury ||
    !injuryInfo.injuryDescription ||
    !injuryInfo.initialTreatment ||
    !injuryInfo.wagesPaidForDateOfInjury ||
    !injuryInfo.absenceFromWorkDueToInjury
  );

  const accidentInfoComplete = !(
    !accidentInfo.groupId ||
    !accidentInfo.incidentDate ||
    !accidentInfo.incidentDescription ||
    !accidentInfo.streetAddress ||
    !accidentInfo.city ||
    !accidentInfo.state ||
    !accidentInfo.zip ||
    !accidentInfo.incidentLocationCounty ||
    !accidentInfo.incidentLocationDescription ||
    !accidentInfo.claimReportedByTitle ||
    !accidentInfo.claimJurisdictionCode
  );

  const policyInfoComplete = !(
    !policyInfo.displayPolicyNumber ||
    !policyInfo.policyEffectiveDate ||
    !policyInfo.policyEndDate ||
    !policyInfo.lineOfBusinessCode
  );

  const canSubmit =
    employeeInfoComplete &&
    injuryInfoComplete &&
    accidentInfoComplete &&
    policyInfoComplete;

  const isReadOnly = permissions?.find(p => p.userId === myUserId)?.readOnly;

  // don't need until we add auto and GC
  // const addAttachments = attachment => {
  //   if (!attachment.length > 0) return;
  //   let data = formatFiles(attachment);
  //   uploadAttachment(data, {
  //     ownerType: 'claim',
  //     isSignature: false,
  //     isPhotoEvidence: false
  //   }).then(response => {
  //     setAttachments([...attachments, ...response]);
  //   });
  // };

  // const deleteAtt = attachment => {
  //   deleteAttachment(attachment._id).then(() => {
  //     let a = attachments.filter(a => a !== attachment);
  //     setAttachments([...a]);
  //     if (claimId) {
  //       handleSubmit(isSubmitted);
  //     }
  //   });
  // };

  const header = (
    <Header
      title={activeIncident?.incidentNumber}
      section="WC Claim"
      pageActionOptions={[
        {
          label: 'Permissions',
          visible: !creating && !isReadOnly,
          color: 'blueOutline',
          onClick: () => history.push(`/app/permissions/Claim/${id}`)
        },
        {
          label: 'Return to Incident',
          visible: true,
          color: 'blueOutline',
          onClick: () =>
            dispatch(setActiveIncidentRequest(incidentId ?? activeIncident._id))
        },
        {
          label: 'Download Claim',
          visible: isSubmitted,
          color: 'greenOutline',
          onClick: print
        },
        {
          label: 'Delete Claim',
          visible: !creating && isSubmitted && !isReadOnly,
          color: 'redOutline',
          onClick: handleDelete
        }
      ]}
      clickBack={() =>
        unsavedChanges ? setSaveChangesModal(true) : history.goBack()
      }
      needsSaved={unsavedChanges}
    />
  );

  const footer = (
    <SubmitSaveForLaterCancelFooter
      submitButtonClick={() =>
        canSubmit ? setSubmitModal(true) : setMissingModalOpen(true)
      }
      saveButtonClick={() => handleSubmit(false)}
      cancelButtonClick={handleCancel}
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  const left = (
    <LeftNav
      items={[
        {
          value: 0,
          label: isSubmitted ? 'Overview' : 'Instructions',
          complete: true
        },
        {
          value: 1,
          label: 'Employee Information',
          complete: employeeInfoComplete
        },
        {
          value: 2,
          label: 'Injury Information',
          complete: injuryInfoComplete
        },
        {
          value: 3,
          label: 'Accident Information',
          complete: accidentInfoComplete
        },
        {
          value: 4,
          label: 'Policy Information',
          complete: policyInfoComplete
        }
        // { value: 5, label: 'Claim Attachments' }
      ]}
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      Left={left}
      showFooter={!isSubmitted && !isReadOnly}
    >
      {isSubmitted ? (
        <ClaimCard
          name={0}
          claimSection={overviewInfo}
          title="Overview"
          viewOnly
        />
      ) : (
        <InstructionCard name={0} />
      )}
      <ClaimCard
        name={1}
        editing={creating || employeeEditing}
        viewOnly={isReadOnly}
        claimSection={employeeInfo}
        onChange={values => {
          setEmployeeInfo(values);
          setUnsavedChanges(true);
        }}
        title="Employee Information"
        openEdit={setEmployeeEditing}
        isSubmitted={isSubmitted}
        missingRequired={missingRequired}
      />
      <ClaimCard
        name={2}
        editing={creating || injuryEditing}
        viewOnly={isReadOnly}
        claimSection={injuryInfo}
        onChange={values => {
          setInjuryInfo(values);
          setUnsavedChanges(true);
        }}
        title="Injury Information"
        openEdit={setInjuryEditing}
        isSubmitted={isSubmitted}
        missingRequired={missingRequired}
      />
      <ClaimCard
        type="wc"
        name={3}
        editing={creating || accidentEditing}
        viewOnly={isReadOnly}
        claimSection={accidentInfo}
        onChange={values => {
          setAccidentInfo(values);
          setUnsavedChanges(true);
        }}
        title="Accident Information"
        openEdit={setAccidentEditing}
        isSubmitted={isSubmitted}
        permissions={permissions}
        missingRequired={missingRequired}
      />
      <ClaimCard
        name={4}
        editing={creating || policyEditing}
        viewOnly={isReadOnly}
        claimSection={policyInfo}
        onChange={values => {
          setPolicyInfo(values);
          setUnsavedChanges(true);
        }}
        title="Policy Information"
        openEdit={setPolicyEditing}
        isSubmitted={isSubmitted}
        availablePolicies={availablePolicies}
        incidentDate={accidentInfo.incidentDate}
        type="WC"
        missingRequired={missingRequired}
      />
      {/* <AttachmentUploadCard
        name={5}
        addAttachments={addAttachments}
        deleteAttachment={deleteAtt}
        isEditing={!isSubmitted}
        addedAttachments={attachments}
        attachmentsToUpload={[]} // not sure if I need this
        ownerType="claim"
        createdBy={myUserId}
      /> */}
      <SaveChangesModal
        onRequestClose={() => setSaveChangesModal(false)}
        isOpen={saveChangesModal}
        submitActions={() => handleCancel(true)}
      />
      <DeleteItemModal
        onRequestClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        submitActions={handleDelete}
        hideCustomTemplate
        what="Claim"
      />
      <Modal
        title="Submit Claim"
        titleClassName="blueHeader"
        isOpen={sumbitModal}
        submitButtonColor="blue"
        submitButtonText="Submit"
        onRequestClose={() => setSubmitModal(false)}
        submitActions={() => handleSubmit(true)}
      >
        <div className={styles.text}>
          Once you submit the claim the data will no longer update within the
          incident or for your insurance.
        </div>
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="WC Claim"
      />
    </HeaderAndFooter>
  );
}
