import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../history';
import {
  createDocument,
  deleteDocument,
  fetchDocumentById,
  fetchDocumentFoldersByType,
  fetchQualificationType,
  updateDocument,
  deleteAttachment
} from '../../api/v4';
import { stateAbbr } from '../../constants/states';
import {
  clearUploadedAttachments,
  addUploadedAttachmentsRequest,
  addUploadedAttachmentsResponse
} from '../../actions/attachments';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import { getAllLocations } from '../../selectors/company';
import { getAccessLevel } from '../../selectors/users';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import customToast from '../../utils/customToast';
import Card from '../../components/Card';
import { IReportDatePicker } from '../../components/inputs/DateTimePicker';
import { Dropdown, EmployeeDropdown, Textbox } from '../../components/inputs';
import FileDownloader from '../../components/FileDownloader';
import FileUploader from '../../components/FileUploader';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import HierarchySelector from '../../components/HierarchySelector';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import { SaveCancelFooter } from '../../components/Footer';
import MissingRequiredModal from '../../components/Modal/missingRequiredModal';

export default function QualificationContainer(props) {
  const { docId } = useParams();
  const dispatch = useDispatch();

  const allGroups = useSelector(getAllLocations);
  const accessLevel = useSelector(getAccessLevel);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);
  const { activeCompany, activeGroup } = useActiveHeirarchy();
  const fromEmployeeProfileId = props.location?.state?.employeeId;

  const [document, setDocument] = useState();
  const [documentData, setDocumentData] = useState();
  const [attachments, setAttachments] = useState([]);
  const [folders, setFolders] = useState([]);
  const [qualificationTypes, setQualificationTypes] = useState([]);
  const [isCreating, setCreating] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const allAttachments = [...attachments, ...addedAttachments];

  useEffect(() => {
    if (docId) {
      fetchDocumentById(docId).then(response => {
        setDocument({
          label: response.label,
          expires: response.expires,
          attachments: response.attachments,
          groupIds: response?.groupIds,
          projectIds: response?.projectIds,
          documentFolderId: response?.documentFolderId,
          isArchived: response?.isArchived
        });
        setDocumentData({ ...response?.documentData });
        setAttachments(response?.attachments);
      });
    } else {
      setCreating(true);
      setDocument({
        companyId: activeCompany?._id
      });
      if (fromEmployeeProfileId)
        setDocumentData({ employeeId: fromEmployeeProfileId });
    }
    fetchDocumentFoldersByType('Qualification').then(response =>
      setFolders(response?.map(df => ({ value: df?._id, label: df?.name })))
    );

    fetchQualificationType().then(setQualificationTypes);
  }, [docId, activeCompany, fromEmployeeProfileId]);

  const handleSubmit = () => {
    const attachmentIds = allAttachments?.map(a => a._id);
    let payload = {
      ...document,
      documentData: { ...documentData },
      documentType: 'Qualification',
      attachments: attachmentIds
    };
    if (isEditing) {
      updateDocument({ ...payload, _id: docId }).then(() => {
        dispatch(clearUploadedAttachments());
        history.goBack();
      });
    } else {
      createDocument(payload).then(() => {
        dispatch(clearUploadedAttachments());
        customToast('Successfully Created!', 'success');
        if (fromEmployeeProfileId)
          history.push('/app/viewEditPersonnel', { populateEmployee: true });
        else history.goBack();
      });
    }
  };

  const handleDelete = () => {
    deleteDocument(docId).then(() => history.goBack());
  };

  const handleDeleteAttachment = attachment => {
    let updatedAttachments = attachments?.filter(
      a => a._id !== attachment?._id
    );
    let updatedAddedAttachments = addedAttachments?.filter(
      a => a._id !== attachment?._id
    );

    dispatch(addUploadedAttachmentsResponse(updatedAddedAttachments));
    deleteAttachment(attachment?._id).then(response => {
      customToast('Successfully Deleted!', 'success');
    });
    setAttachments(updatedAttachments);
  };

  const handleAnswer = (values, isDocumentData = true) => {
    isDocumentData
      ? setDocumentData({ ...documentData, ...values })
      : setDocument({ ...document, ...values });
    setHasUnsavedChanges(true);
  };

  const handleBack = () => {
    dispatch(clearUploadedAttachments());
    if (fromEmployeeProfileId)
      history.push('/app/viewEditPersonnel', { populateEmployee: true });
    else history.goBack();
  };

  const handleArchive = () => {
    updateDocument({
      ...document,
      _id: docId,
      isArchived: !document.isArchived
    }).then(() => {
      if (!document?.isArchived) handleBack();
      else
        fetchDocumentById(docId).then(response => {
          setDocument({
            label: response.label,
            expires: response.expires,
            attachments: response.attachments,
            groupIds: response?.groupIds,
            projectIds: response?.projectIds,
            documentFolderId: response?.documentFolderId,
            isArchived: response?.isArchived
          });
        });
    });
  };

  const canSubmit =
    (document?.groupIds?.length || activeGroup) &&
    documentData?.scheduledDate &&
    document?.expires &&
    documentData?.qualificationType &&
    documentData?.employeeId?.length &&
    documentData?.certificationEntity &&
    documentData?.certificationId;

  const header = (
    <Header
      title="Qualification"
      section={
        !isEditing && !isCreating
          ? 'View Document'
          : isCreating
            ? 'Create Document'
            : 'Edit Document'
      }
      clickBack={() =>
        hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : handleBack()
      }
      needsSaved={hasUnsavedChanges}
      rightButtons={[
        {
          visible: !isCreating && !isEditing && accessLevel !== 100,
          text: 'Edit',
          color: 'blue',
          onClick: () => setEditing(true)
        },
        {
          visible: isEditing,
          text: 'Delete',
          color: 'red',
          onClick: () => handleDelete()
        }
      ]}
      pageActionOptions={
        accessLevel > 400 && !isCreating
          ? [
              {
                label: 'Edit Qualification',
                color: 'blueOutline',
                visible: !isEditing && !document?.isArchived,
                onClick: () => setEditing(true)
              },
              {
                label: 'Delete Qualification',
                color: 'redOutline',
                visible: isEditing || document?.isArchived,
                onClick: () => handleDelete()
              },
              {
                label: `${
                  document?.isArchived ? 'Un-' : ''
                }Archive Qualification`,
                color: 'blueOutline',
                visible: true,
                onClick: () => handleArchive()
              }
            ]
          : null
      }
    />
  );

  const footer =
    isEditing || isCreating ? (
      <SaveCancelFooter
        saveButtonClick={() =>
          !canSubmit ? setMissingModalOpen(true) : handleSubmit()
        }
        saveButtonDisabled={!hasUnsavedChanges && isEditing}
        cancelButtonClick={() =>
          hasUnsavedChanges ? setOpenUnsavedChangesModal(true) : handleBack()
        }
        editing={isEditing}
        onMouseEnter={() => setMissingRequired(!canSubmit)}
      />
    ) : null;

  const getProjectDropdown = groupIds => {
    const selectedGroupProjects = allGroups
      ?.filter(g => groupIds?.includes(g._id))
      .reduce((projects, g) => projects.concat(g.projects), []);
    let projects = selectedGroupProjects?.map(p => ({
      value: p._id,
      label: p.name
    }));
    return projects;
  };

  const qualificationTypeDropdown = qualificationTypes.map((q, index) => {
    return { value: index, label: q.fullName };
  });

  return (
    <>
      <HeaderAndFooter Header={header} Footer={footer}>
        <Card>
          <HierarchySelector
            multi
            groupDisabled={!isCreating && !isEditing}
            projectDisabled={!isCreating && !isEditing}
            onGroupChange={v => {
              if (document?.projectIds) {
                const availableProjects = getProjectDropdown(v);
                const projectIds = availableProjects
                  ?.filter(ap => document?.projectIds?.includes(ap?.value))
                  ?.map(ap => ap?.value);
                handleAnswer({ groupIds: v, projectIds }, false);
              } else {
                handleAnswer({ groupIds: v }, false);
              }
            }}
            onProjectChange={v => handleAnswer({ projectIds: v }, false)}
            groupId={document?.groupIds}
            projectId={document?.projectIds}
            alwaysShowGroupDropdown
            alwaysShowProjectDropdown
            groupTouched={missingRequired && !document?.groupIds?.length}
            noTwoColumn
            groupTooltip={
              <p>
                You can share with one or more Groups / Establishments and that
                will make it available to them as well
              </p>
            }
            displayGroupTooltip
            projectTooltip={
              <p>
                You can share this document with one or more Areas and that will
                make it available to them as well
              </p>
            }
            displayProjectTooltip
          />
          <Dropdown
            name="qualification"
            fieldLabel="Qualification"
            options={qualificationTypeDropdown}
            onChange={v => handleAnswer({ qualificationType: v })}
            isRequired
            currentValue={documentData?.qualificationType}
            searchable
            bareValues={false}
            valueKey="label"
            disabled={!isCreating && !isEditing}
            touched={missingRequired && !documentData?.qualificationType}
          />
          <Dropdown
            options={folders}
            fieldLabel="Add to Folder"
            currentValue={document?.documentFolderId}
            onChange={v => handleAnswer({ documentFolderId: v }, false)}
            disabled={!isCreating && !isEditing}
            searchable
            allowUnset
          />
          <IReportDatePicker
            fieldLabel="Schedule Date"
            onChange={value => handleAnswer({ scheduledDate: value })}
            currentValue={documentData?.scheduledDate}
            isRequired
            name="qualificationScheduleDate"
            disabled={!isCreating && !isEditing}
            touched={missingRequired && !documentData?.scheduledDate}
          />
          <IReportDatePicker
            fieldLabel="Expiration Date"
            onChange={v => handleAnswer({ expires: v }, false)}
            currentValue={document?.expires}
            name="qualificationExpirationDate"
            disabled={!isCreating && !isEditing}
            isRequired
            touched={missingRequired && !document?.expires}
          />
          <Dropdown
            name="statesOfCerts"
            fieldLabel="State of Certification"
            options={stateAbbr}
            className="state"
            placeholder="State"
            currentValue={documentData?.certificateState}
            onChange={value => handleAnswer({ certificateState: value })}
            searchable
            bareValues={false}
            disabled={!isCreating && !isEditing}
          />
          <EmployeeDropdown
            multi
            selectButtons
            name="employees"
            fieldLabel="Personnel Name"
            onChange={values => handleAnswer({ employeeId: values })}
            isRequired
            currentValue={documentData?.employeeId}
            searchable
            disabled={!isCreating && !isEditing}
            touched={missingRequired && !documentData?.employeeId}
            filterChoices={[
              {
                label: 'Include Former Employees',
                value: 'inactive'
              }
            ]}
          />
          <Textbox
            currentValue={documentData?.certificationEntity}
            fieldLabel="Certification Entity"
            onChange={v => handleAnswer({ certificationEntity: v })}
            isRequired
            disabled={!isCreating && !isEditing}
            touched={missingRequired && !documentData?.certificationEntity}
          />
          <Textbox
            currentValue={documentData?.certificationId}
            fieldLabel="Certification ID"
            onChange={v => handleAnswer({ certificationId: v })}
            isRequired
            disabled={!isCreating && !isEditing}
            touched={missingRequired && !documentData?.certificationId}
          />
          <h4> Attachments</h4>
          {allAttachments?.map((attachment, attachmentIndex) => (
            <FileDownloader
              attachment={attachment}
              key={attachmentIndex}
              attachmentIndex={attachmentIndex}
              ownerType="documents"
              deleteAttachment={handleDeleteAttachment}
              disabled={!isCreating && !isEditing}
            />
          ))}
          {isEditing || isCreating ? (
            <FileUploader
              addAttachment={attachment =>
                dispatch(addUploadedAttachmentsRequest(attachment))
              }
              ownerId={docId ?? 'pending'}
              ownerType="documents"
            />
          ) : null}
        </Card>
        <SaveChangesModal
          isOpen={openUnsavedChangesModal}
          onRequestClose={() => setOpenUnsavedChangesModal(false)}
          submitActions={() => handleBack()}
          savingWhat="a Qualification"
        />
        <MissingRequiredModal
          isOpen={missingModalOpen}
          onRequestClose={() => setMissingModalOpen(false)}
          subject="Qualification"
          isEditing={isEditing}
        />
      </HeaderAndFooter>
    </>
  );
}
