import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import classnames from 'classnames/bind';
import Checkbox from './Checkbox';

import styles from './input.module.scss';
const bStyles = classnames.bind(styles);

export class Input extends Component {
  render() {
    const {
      fieldLabel,
      isRequired,
      touched,
      children,
      tooltip,
      displayTooltip,
      className,
      showNA,
      isNA,
      disabled,
      onNAChange,
      inline,
      name,
      labelStyles,
      richText,
      errorMessage,
      showWarning,
      hideLabel
    } = this.props;
    return (
      <div
        className={bStyles({ inline: inline }, 'input', className)}
        data-cy={fieldLabel + ' input'}
      >
        {displayTooltip && (
          <ReactTooltip className={styles.toolTip} id={name}>
            {tooltip || children}
          </ReactTooltip>
        )}
        {fieldLabel && !richText ? (
          <span className={bStyles(labelStyles, 'label')}>
            {isRequired ? <span className={styles.isRequired}>*</span> : ''}
            <label
              data-tooltip-class-name={styles.toolTip}
              data-tooltip-id={name}
              htmlFor={name}
            >
              {hideLabel ? null : fieldLabel}
              {displayTooltip && (
                <img
                  className="label-info"
                  src={require(`../../assets/images/Info Icon.png`)}
                  alt="Info Icon"
                />
              )}
            </label>
          </span>
        ) : fieldLabel && richText ? (
          <div className={bStyles(labelStyles, 'richText')}>
            {isRequired ? <span className={styles.isRequired}>*</span> : ''}
            <div>
              <ReactMarkdown children={fieldLabel} />
            </div>
          </div>
        ) : null}
        {showNA && (
          <Checkbox
            fieldLabel="Not Applicable"
            name={`${name}-na`}
            currentValue={isNA}
            onChange={onNAChange}
            disabled={disabled}
            className={styles.na}
          />
        )}
        {!isNA && this.props.children}
        {(touched || showWarning) && errorMessage ? (
          <span className={styles.inputWarning}>
            <img
              src={require('../../assets/images/caution.png')}
              alt="warning"
              className={styles.caution}
            />
            <div className={styles.warningText}>{errorMessage}</div>
          </span>
        ) : null}
      </div>
    );
  }
}

export class TwoColumn extends Component {
  render() {
    const { className, children, ...props } = this.props;
    return (
      <div className={classnames(className, styles.twoColumn)} {...props}>
        {children}
      </div>
    );
  }
}

export class InputRow extends Component {
  render() {
    const { className, children, right, center, space, ...props } = this.props;
    const classes = {
      row: true,
      right: right,
      center: center,
      space: space
    };
    return (
      <div className={bStyles(className, classes)} {...props}>
        {children}
      </div>
    );
  }
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function nameInput(Input) {
  class nameInput extends Component {
    constructor(props) {
      super(props);
      this.state = { name: props.name || uuidv4() };
    }

    render() {
      let { name, ...props } = this.props;
      name = name || uuidv4();
      return <Input name={this.state.name} {...props} />;
    }
  }
  nameInput.displayName = `nameInput(${getDisplayName(Input)})`;
  return nameInput;
}

export { Button } from './Button';
export { NumberStepper } from './NumberStepper';
export { Dropdown, EmployeeDropdown } from './Dropdown';
export { CalendarDropdown } from './CalendarDropdown';
export { Textbox } from './Textbox';
export { Checkbox } from './Checkbox';
export { FieldRenderer } from './fieldRenderer';
export { ButtonGroup } from './ButtonGroup';
export default Input;
