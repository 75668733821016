import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { DOCUMENT_TYPES_CUSTOMIZABLE } from '../../../constants/constants';
import Button from '../../inputs/Button';
import Checkbox from '../../inputs/Checkbox';
import Dropdown from '../../inputs/Dropdown';
import Modal from '../../Modal';
import Textbox from '../../inputs/Textbox';
import { InputRow } from '../../inputs';
import HoverImage from '../../HoverImage';
import Card from '../../Card';
import { Rearranger } from '../../Rearranger';
import TableQuestion from '../../inputs/TableQuestion';

import styles from './customDocCard.module.scss';

export default function CustomDocumentCard({
  onChange,
  fields,
  isEditing,
  disabled,
  missingRequired,
  handleExpiredChange,
  isConfidential,
  handleConfidentialChange,
  isTitleValid,
  hasInvolvedEmployee,
  handleTitleChange,
  notEditable,
  shouldHaveExpiredDate,
  handleInvolvedEmployeeChange,
  label,
  duplicating
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeOptions, setActiveOptions] = useState([]);
  const [rowIndex, setRowIndex] = useState(0);
  const [selectedFieldType, setSelectedFieldType] = useState('');

  const handleQuestionChange = (index, questionLabel) => {
    let newFields = [...fields];
    let answers = questionLabel.answer;

    newFields[index][questionLabel.field] = answers;

    setActiveOptions([]);
    setRowIndex('');
    setModalOpen(false);
    setSelectedFieldType('');

    onChange(fields);
  };

  const addRowsToTemplate = numberOfRows => {
    let newFields = [...fields];

    for (let i = 1; i <= numberOfRows; i++) {
      newFields.push({
        type: '',
        label: '',
        options: [],
        required: false
      });
    }

    onChange(newFields);
  };
  const setChangeOptions = (options, rowIndex, fieldType) => {
    options = options.map(option => {
      if (option.option) return option;
      if (option && duplicating)
        return {
          option,
          isEditable: true
        };
      return {
        option,
        isEditable: false
      };
    });

    setModalOpen(true);
    setRowIndex(rowIndex);
    setActiveOptions(activeOptions);
    setSelectedFieldType(fieldType.value);
  };

  const renderItem = (item, index) => {
    const notEditable = '_id' in item;
    const buttonOptions = [
      'dropdown',
      'multiselect',
      'radiobuttons',
      'checkbox'
    ];
    const optionsIncludeItem =
      buttonOptions.includes(item.type.value) ||
      buttonOptions.includes(item.type);

    return (
      <>
        <Textbox
          currentValue={item.label}
          onChange={answer =>
            handleQuestionChange(index, { field: 'label', answer })
          }
          fieldLabel="Question"
          type="textarea"
          placeholder="Type your question here."
          disabled={disabled}
          testID={`question${index}`}
          isRequired
          touched={missingRequired}
        />
        <Dropdown
          options={DOCUMENT_TYPES_CUSTOMIZABLE}
          fieldLabel="Type of Input"
          onChange={values =>
            handleQuestionChange(index, {
              field: 'type',
              answer: values
            })
          }
          isRequired
          bareValues={false}
          placeholder="Choose a question type."
          currentValue={item.type}
          disabled={disabled || notEditable}
          testID={`dropdown${index}`}
          touched={missingRequired}
        />
        {item.type.value !== 'blockOfText' && (
          <Checkbox
            fieldLabel="Is Required"
            onChange={v =>
              handleQuestionChange(index, {
                field: 'required',
                answer: v
              })
            }
            currentValue={item.required}
            disabled={disabled || notEditable}
          />
        )}
        {optionsIncludeItem && (
          <Button
            text="View Options"
            color="blue"
            onClick={() => setChangeOptions(item.options, index, item.type)}
            disabled={disabled}
          />
        )}
        {item.type.value === 'table' && (
          <TableQuestion
            options={item.options}
            handleChange={v =>
              handleQuestionChange(index, {
                field: 'options',
                answer: v.options
              })
            }
            disabled={disabled}
          />
        )}
        {optionsIncludeItem && item.options.length === 0 && (
          <div style={{ color: '#c74846', fontWeight: 'bold' }}>
            * Must add at least one option
          </div>
        )}
      </>
    );
  };

  return (
    <Card title="Document Template" showHeader>
      <Textbox
        currentValue={label}
        onChange={v => handleTitleChange(v)}
        fieldLabel="Title"
        placeholder="Title of Document"
        disabled={disabled}
        isRequired
        touched={missingRequired && !label}
        error
        inputClassName={
          label
            ? isTitleValid
              ? styles.greenBackground
              : styles.redBackground
            : null
        }
      />
      <Checkbox
        fieldLabel="Should be able to be expired?"
        onChange={handleExpiredChange}
        currentValue={shouldHaveExpiredDate}
        disabled={disabled}
      />
      <Checkbox
        fieldLabel="Is this a Confidential Document?"
        onChange={handleConfidentialChange}
        currentValue={isConfidential}
        disabled={disabled}
      />
      <Checkbox
        fieldLabel="Has an Involved Employee?"
        onChange={handleInvolvedEmployeeChange}
        currentValue={hasInvolvedEmployee}
        disabled={disabled}
      />
      <Rearranger
        className={styles.container}
        items={fields}
        onChange={onChange}
        renderItem={renderItem}
        disabled={disabled || notEditable}
        onDuplicate={row => {
          const clone = cloneDeep(row);
          delete clone._id;
          return clone;
        }}
      />
      <InputRow className={styles.footer}>
        <Button
          text="Add Row"
          color="blue"
          onClick={() => addRowsToTemplate(1)}
          disabled={disabled}
        />
        <Button
          text="Add Five Rows"
          color="blue"
          onClick={() => addRowsToTemplate(5)}
          disabled={disabled}
        />
      </InputRow>
      <Modal
        title="Change Options"
        titleClassName="blueHeader"
        isOpen={modalOpen}
        submitButtonColor="blue"
        submitButtonText="Save Changes"
        onRequestClose={() => {
          setModalOpen(false);
          setActiveOptions([]);
        }}
        submitActions={() =>
          handleQuestionChange(rowIndex, {
            field: 'options',
            answer: activeOptions
          })
        }
        disableSubmit={
          !activeOptions?.length ||
          activeOptions?.filter(o => !o.option?.trim()?.length)?.length
        }
      >
        <div className={styles.modal}>
          {activeOptions.map((option, index) => (
            <div className={styles.options} key={index}>
              <Textbox
                testID={`addOption${index}`}
                currentValue={option.option}
                onChange={v => {
                  const currentOptions = [...activeOptions];
                  currentOptions[index].option = v;
                  setActiveOptions(currentOptions);
                }}
                placeholder="Type your option here."
                disabled={!option.isEditable && fields[rowIndex]._id}
                className={styles.option}
              />
              {option.isEditable && (
                <HoverImage
                  src={require('../../../assets/images/removePermissions.png')}
                  srcHover={require('../../../assets/images/removePermissonsHover.png')}
                  className={styles.removeImage}
                  alt="remove row"
                  onClick={() =>
                    setActiveOptions([
                      ...activeOptions.slice(0, index),
                      ...activeOptions.slice(index + 1)
                    ])
                  }
                />
              )}
            </div>
          ))}
          <Button
            text="Add Option"
            color="blue"
            onClick={() =>
              setActiveOptions([
                ...activeOptions,
                { option: '', isEditable: true }
              ])
            }
            disabled={
              activeOptions.length === 2 && selectedFieldType === 'radiobuttons'
            }
          />
          {activeOptions.length === 2 &&
            selectedFieldType === 'radiobuttons' && (
              <div style={{ color: '#c74846', fontWeight: 'bold' }}>
                * Only two options are allowed for Radio Buttons
              </div>
            )}
        </div>
      </Modal>
    </Card>
  );
}
