import React, { useState, useEffect } from 'react';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { Tooltip } from 'react-tooltip';
import { updateAnalyticsDashboard } from '../../api/v4';
import InfoBar from '../InfoBar';
import Textbox from '../inputs/Textbox';
import Button from '../inputs/Button';
import FrequencyPicker from '../inputs/FrequencyPicker';
import DateRangePicker from '../inputs/DateRangePicker';
import DateUnitPicker from '../inputs/DateUnitPicker';
import { IReportDatePicker } from '../inputs/DateTimePicker';
import { TwoColumn } from '../inputs';
import classnames from 'classnames/bind';

import styles from './picker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
const bStyles = classnames.bind(styles);

export default function AnalyticsPeriodPicker({
  allowAll,
  showFrequencyPicker,
  showTrendPicker,
  readOnly,
  className,
  onChange,
  period,
  onSave,
  dashboardId,
  savedPeriod,
  error,
  listPicker,
  isBulkPicker
}) {
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [periodNumber, setPeriodNumber] = useState();

  useEffect(() => {
    setCurrentPeriod({ ...(period ?? {}) });
    setPeriodNumber(period?.number);
  }, [period]);

  const save = () => {
    let period = {
      mode: currentPeriod.mode,
      frequency: currentPeriod.frequency
    };

    if (period.mode === 'absolute') {
      period.startDate = currentPeriod.startDate;
      period.endDate = currentPeriod.endDate;
    } else if (period.mode === 'relative') {
      period.unit = currentPeriod.unit;
      period.number = currentPeriod.number;
    }
    if (dashboardId) {
      updateAnalyticsDashboard(dashboardId, { period }).then(() => {
        if (onSave) onSave(period);
      });
    } else {
      if (onSave) onSave(period);
    }
  };

  const itemChange = (i, v) => {
    let updated = { ...currentPeriod };

    if ('number' in updated) updated.number = Number(updated.number);
    updated[i] = v;
    if (onChange) onChange({ ...updated });
  };

  const periodNumberChange = () => itemChange('number', periodNumber);
  const periodUnitChange = v => itemChange('unit', v);
  const startDateChange = d => itemChange('startDate', d?.toString());
  const endDateChange = d => itemChange('endDate', d?.toString());
  const frequencyChange = v => itemChange('frequency', v);
  const modeChange = v => itemChange('mode', v);

  const handleCustomRangeLimit = (type, date) => {
    if (!isBulkPicker || !date) return null;
    if (type.includes('min')) {
      if (type.includes('Start'))
        return new Date(moment(date).subtract(6, 'months'));
      else return new Date(moment(date).add(1, 'day'));
    } else if (type.includes('max')) {
      if (type.includes('Start'))
        return new Date(moment(date).subtract(1, 'day'));
      else return new Date(moment(date).add(6, 'months'));
    }
  };

  const value = currentPeriod || {};

  let startDate = value.startDate;
  let endDate = value.endDate;

  const unsaveableDateRange =
    value.mode === 'absolute'
      ? !startDate || !endDate || moment(startDate).isAfter(endDate)
      : false;
  const unsaveablePeriod =
    (value.mode === 'relative' && (!value.unit || !value.number)) ?? false;
  return (
    <InfoBar
      color="white"
      className={bStyles(styles.picker, className, {
        listPicker: listPicker
      })}
    >
      <TwoColumn className={styles.twoColumn}>
        <DateRangePicker
          className={styles.input}
          onChange={modeChange}
          currentValue={value.mode}
          fieldLabel="Date Range:"
          name="range"
          customRange
          customPeriod={showFrequencyPicker}
          trendData={showTrendPicker}
          allowAll={allowAll}
          touched={error}
          isBulkPicker={isBulkPicker}
        />
        {value.mode === 'absolute' && (
          <div styles={{ display: 'column' }} className={styles.absolute}>
            <div className={styles.period}>
              <IReportDatePicker
                currentValue={startDate}
                onChange={startDateChange}
                name="startDate"
                className={styles.popperPlacement}
                minDate={handleCustomRangeLimit('minStart', endDate)}
                maxDate={handleCustomRangeLimit('maxStart', endDate)}
              />
              <IReportDatePicker
                currentValue={endDate}
                onChange={endDateChange}
                name="endDate"
                className={styles.popperPlacement}
                minDate={handleCustomRangeLimit('minEnd', startDate)}
                maxDate={handleCustomRangeLimit('maxEnd', startDate)}
              />
              {isBulkPicker ? (
                <>
                  <Tooltip id="customRangeTooltip">
                    Due to file size limitations, custom date ranges for bulk
                    exports are limited to 6 months.
                  </Tooltip>
                  <span data-tooltip-id="customRangeTooltip">
                    <img
                      src={require('../../assets/images/Info Icon.png')}
                      alt="Info Icon"
                    />
                  </span>
                </>
              ) : null}
            </div>
            {startDate && endDate && moment(startDate).isAfter(endDate) ? (
              <span className={styles.warning}> Invalid Date Range</span>
            ) : null}
          </div>
        )}
        {value.mode === 'relative' && (
          <div className={styles.period}>
            <span>Past</span>
            <Textbox
              className={`${styles.input} ${styles.time}`}
              currentValue={periodNumber || ''}
              onChange={v => {
                v = v.replace(/[^0-9]/g, '');
                setPeriodNumber(Number(v));
              }}
              enter={periodNumberChange}
              onBlur={periodNumberChange}
              type="text"
            />
            <DateUnitPicker
              className={styles.input}
              onChange={periodUnitChange}
              currentValue={value.unit}
              name="unit"
            />
          </div>
        )}
        {showFrequencyPicker && currentPeriod?.mode !== 'trend' && (
          <FrequencyPicker
            className={styles.input}
            onChange={frequencyChange}
            currentValue={value.frequency}
            fieldLabel="Period:"
            displayTooltip={true}
            name="frequency"
            tooltip="This option only applies to line graphs, and controls the time period each dot represents."
          />
        )}
        {!isEqual(currentPeriod, savedPeriod) && !readOnly && !isBulkPicker && (
          <Button
            color="green"
            onClick={save}
            text="Set as Default"
            className={classnames(styles.save, styles.input)}
            disabled={unsaveableDateRange || unsaveablePeriod}
          />
        )}
      </TwoColumn>
    </InfoBar>
  );
}
