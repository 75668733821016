import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  Label,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import isEqual from 'lodash/isEqual';
import MD5 from 'md5.js';
import moment from 'moment';

import classnames from 'classnames/bind';
import { fetchAnalytics } from '../../api/v4';
import { inflateChartSettings } from '../../utils/analytics';
import { getAccessLevel } from '../../selectors/users';
import Loading from '../Loading';

import styles from './analyticsChart.module.scss';
const bStyles = classnames.bind(styles);

class AnalyticsChart extends Component {
  state = { data: null, trendLabels: {} };

  loadData() {
    if (!this.props.settings) return;

    if (
      this.props.settings?.datasource === 'OSHA Compliance' &&
      !this.props.settings?.subcategory
    ) {
      return;
    }

    // need this check so that it doesn't populate the charts before the period
    // is passed in. It's causing the YTD chart to stay since it takes a longer
    // api call
    if (this.props.fromDashboard && !this?.props?.period?.mode) {
      return;
    }

    if (this.props?.fromDetailed) {
      this.props.updateDashboardData({ data: null, types: [] });
    }
    fetchAnalytics({
      ...inflateChartSettings(this.props.settings, this.props.period),
      detailed: this.props?.fromDetailed
    }).then(data => {
      let labels = this.getCompLabels(data);
      this.setState({ data: data?.results, trendLabels: labels });
      if (this.props?.fromDetailed) {
        this.setState({ data: data?.results });
        this.props.updateDashboardData({ ...data?.detailedData });
      } else {
        this.setState({ data });
      }
    });
  }

  getColorOffset(l) {
    const { datasource, category, filterArgs, series } = this.props.settings;
    let settings = { datasource, category, filterArgs, series };
    let hash = new MD5().update(JSON.stringify(settings)).digest();
    return hash.reduce((a, v) => (a + v) % l);
  }

  darkenColor(col = '#ffffff', amt) {
    col = col.slice(1);

    var num = parseInt(col, 16);
    var r = (num >> 16) - amt;
    var b = ((num >> 8) & 0xff) - amt;
    var g = (num & 0xff) - amt;

    if (r < 0) r = 0;
    if (b < 0) b = 0;
    if (g < 0) g = 0;

    return '#' + (g | (b << 8) | (r << 16)).toString(16);
  }

  componentDidMount() {
    const { mode, startDate, endDate, number, unit } = {
      ...this.props?.period
    };
    if (
      mode === 'absolute' &&
      (!endDate || !startDate || moment(startDate).isAfter(endDate))
    ) {
      return;
    }

    if (mode === 'relative' && (!unit || !number)) {
      return;
    }
    this.setState({ data: null }, this.loadData);
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.settings, prevProps.settings) ||
      !isEqual(this.props.period, prevProps.period)
    ) {
      const { mode, startDate, endDate, number, unit } = {
        ...this.props?.period
      };
      if (
        mode === 'absolute' &&
        (!endDate || !startDate || moment(startDate).isAfter(endDate))
      ) {
        return;
      }

      if (mode === 'relative' && (!unit || !number)) {
        return;
      }
      this.setState({ data: null }, this.loadData);
    }
  }

  render() {
    if (!this.state.data || !this.props.settings) {
      return (
        <Loading
          width={this.props.width}
          height={this.props.height}
          mode="fixedSize"
        />
      );
    }
    if (this.state.data === 'Hours Needed') {
      return (
        <div
          className={bStyles('noHours', {
            [this.props.noDataClassName]: this.props.noDataClassName
          })}
          style={{ width: this.props.width, height: this.props.height }}
        >
          <div>Missing Hours Worked.</div>
          {this.props.accessLevel === 900 ? (
            <div style={{ bottom: '90px' }}>
              Go to Settings &rarr; Company Division.
            </div>
          ) : (
            <div style={{ bottom: '90px' }}>
              Please contact your administrator.
            </div>
          )}
        </div>
      );
    }
    if (this.state.data.length === 0) {
      return (
        <div
          className={bStyles('noData', {
            [this.props.noDataClassName]: this.props.noDataClassName
          })}
          style={{ width: this.props.width, height: this.props.height }}
        >
          <div>No Data</div>
        </div>
      );
    }
    return (
      <div className={this.props.className} onClick={this.props.onClick}>
        {/* 99% Width hack to make the container shrink as well as expand
          ref: https://stackoverflow.com/a/53205850/2379279 */}
        <ResponsiveContainer
          width="99%"
          aspect={this.props?.preview ? 0 : 1.77778}
        >
          {this.renderChart()}
        </ResponsiveContainer>
      </div>
    );
  }

  getCompLabels = () => {
    if (this.props.preview) {
      let start = moment().subtract(1, 'y').format('MM/DD/YYYY');
      let end = moment().format('MM/DD/YYYY');
      let lastStart = moment().subtract(2, 'y').format('MM/DD/YYYY');

      return {
        currentLabel: `${start} - ${end}`,
        prevLabel: `${lastStart} - ${start}`
      };
    } else {
      const { mode, unit, number, startDate, endDate } = this?.props?.period;
      switch (mode) {
        case 'today':
          return { currentLabel: 'Today', prevLabel: 'Yesterday' };
        case 'thisWeek':
          return { currentLabel: 'This Week', prevLabel: 'Last Week' };
        case 'thisMonth':
          return { currentLabel: 'This Month', prevLabel: 'Last Month' };
        case 'thisQuarter':
          return { currentLabel: 'This Quarter', prevLabel: 'Last Quarter' };
        case 'year':
        case 'thisYear':
          return { currentLabel: 'This Year', prevLabel: 'Last Year' };
        case 'relative':
          return {
            currentLabel:
              number && unit ? `Past ${number} ${unit}` : 'This Period',
            prevLabel:
              number && unit
                ? `Past ${number} - ${number * 2} ${unit}`
                : 'Last Period'
          };
        case 'absolute':
          let start = moment(startDate).format('MM/DD/YYYY');
          let lastStart = moment(startDate)
            .subtract(1, 'y')
            .format('MM/DD/YYYY');
          let end = moment(endDate).format('MM/DD/YYYY');
          let lastEnd = moment(endDate).subtract(1, 'y').format('MM/DD/YYYY');
          return {
            currentLabel: `${start} - ${end}`,
            prevLabel: `${lastStart} - ${lastEnd}`
          };
        default:
          break;
      }
    }
  };

  getTopYAxisValue = (data, standards, showTrend) => {
    let topYDataValue = showTrend
      ? data?.reduce((p, c) => (p.current > c.current ? p : c))?.current
      : data?.reduce((p, c) => (parseInt(p.sum) > parseInt(c.sum) ? p : c))
          ?.sum;
    return !standards
      ? topYDataValue
      : standards > topYDataValue
        ? standards
        : topYDataValue;
  };

  getPercentageForStat = data => {
    if (this.props.settings.category.includes('OshaCompliance')) {
      data = data
        .filter(d => d.label === 'Yes')
        .map(d => ({ ...d, sum: d.percentage }));
    }
    return data;
  };

  renderChart() {
    let keys = {};
    let total = 0;
    let data = this.state.data.map(datum => {
      let key = {
        label: datum.label,
        sum: datum.sum,
        last: datum.last,
        current: datum.current,
        percentage: datum.percentage
      };
      total += datum.sum;

      if (datum.series) {
        datum.series.forEach(s => {
          keys[s.label] = 1;
          key[s.label] = s.sum;
        });
      }

      return key;
    });
    let usedKeys = Object.keys(keys);
    let colors = [
      '#800000',
      '#805500',
      '#558000',
      '#008000',
      '#008055',
      '#005580',
      '#000080',
      '#550080',
      '#800055'
    ];
    let offset = this.getColorOffset(colors.length);
    let prepend = this.state.yAxisOptions?.prependUnit || '';
    let label =
      this.state.yAxisOptions?.graphLabel ||
      this.state.yAxisOptions?.label ||
      undefined;
    const { shouldBeHigherThanStandards, settings, width, height, preview } =
      this.props;
    let topYDataValue = this.getTopYAxisValue(
      data,
      settings.standards,
      settings.showTrend
    );
    if (settings.type.includes('pie') || settings.type.includes('bar')) {
      data.sort(
        (a, b) =>
          b.sum - a.sum ||
          b.current - a.current ||
          b.last - a.last ||
          a.label.localeCompare(b.label)
      );

      if (data?.length > 8) {
        const topEight = data.slice(0, 8);

        if (settings.type.includes('trend')) {
          let lastOther = data
            .slice(8)
            .reduce((acc, cur) => acc + parseFloat(cur.last), 0);
          let currentOther = data
            .slice(8)
            .reduce((acc, cur) => acc + parseFloat(cur.current), 0);
          data = [
            ...topEight,
            {
              label: 'Other (Not in Top 8)',
              last: lastOther % 1 !== 0 ? lastOther.toFixed(2) : lastOther,
              current:
                currentOther % 1 !== 0 ? currentOther.toFixed(2) : currentOther
            }
          ];
        } else {
          let other = data
            .slice(8)
            .reduce((acc, cur) => acc + parseFloat(cur.sum), 0);
          data = [
            ...topEight,
            {
              label: 'Other (Not in Top 8)',
              sum: other % 1 !== 0 ? other.toFixed(2) : other
            }
          ];
        }
      }
    }
    switch (settings.type) {
      case 'pie':
        const renderCustomizedLabel = ({
          cx,
          cy,
          midAngle,
          innerRadius,
          outerRadius,
          value,
          index
        }) => {
          const RADIAN = Math.PI / 180;
          const radius = 25 + innerRadius + (outerRadius - innerRadius);
          const x = cx + radius * Math.cos(-midAngle * RADIAN);
          const y = cy + radius * Math.sin(-midAngle * RADIAN);
          return (
            <text
              x={x}
              y={y}
              fill="black"
              textAnchor={x > cx ? 'start' : 'end'}
              dominantBaseline="central"
            >
              {data[index].label} ({value})
            </text>
          );
        };
        return (
          <PieChart width={width} height={height} key={width}>
            <Tooltip
              wrapperStyle={{ zIndex: 1000 }}
              formatter={v =>
                `${prepend}${v} (${((v * 100) / total).toFixed()}%)`
              }
            />
            <Pie
              data={data}
              dataKey="sum"
              nameKey="label"
              label={renderCustomizedLabel}
            >
              {data.map((k, i) => (
                <Cell key={k} fill={colors[(i + offset) % colors.length]} />
              ))}
            </Pie>
          </PieChart>
        );
      case 'line':
        return (
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              wrapperStyle={{ zIndex: 1000 }}
              formatter={v => `${prepend}${v}`}
            />
            <XAxis dataKey="label" xAxisId={0} />
            <YAxis
              tickFormatter={v => `${prepend}${v}`}
              domain={[0, settings.standards]}
            >
              {label && (
                <Label angle={90} position="insideLeft" value={label} />
              )}
            </YAxis>
            {usedKeys.length ? (
              usedKeys.map((k, i) => (
                <Line
                  type="monotone"
                  dataKey={o => o[k] || 0}
                  name={k}
                  key={k}
                  stroke={colors[(i + offset) % colors.length]}
                  xAxisId={0}
                  connectNulls
                />
              ))
            ) : (
              <Line
                type="monotone"
                dataKey="sum"
                stroke={colors[offset % colors.length]}
                xAxisId={0}
                connectNulls
              />
            )}
            <ReferenceLine
              y={settings.standards}
              stroke="red"
              strokeWidth={2}
            />
          </LineChart>
        );
      case 'line-trend':
        return (
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              formatter={v => `${prepend}${v}`}
              wrapperStyle={{ zIndex: 1000 }}
            />
            <Legend />
            <XAxis dataKey="label" xAxisId={0} />
            <YAxis
              tickFormatter={v => `${prepend}${v}`}
              domain={[0, settings.standards]}
            >
              {label && (
                <Label angle={90} position="insideLeft" value={label} />
              )}
            </YAxis>
            <Line
              type="monotone"
              dataKey="current"
              stroke={colors[offset % colors.length]}
              xAxisId={0}
              connectNulls
              name={this.state.trendLabels.currentLabel}
            />

            <Line
              type="monotone"
              dataKey="last"
              stroke={colors[(offset % colors.length) + 10]}
              xAxisId={0}
              connectNulls
              name={this.state.trendLabels.prevLabel}
            />
            <ReferenceLine
              y={settings.standards}
              stroke="red"
              strokeWidth={2}
            />
          </LineChart>
        );
      case 'statistic':
        data = this.getPercentageForStat(data);
        let sum = parseFloat(
          data?.reduce((total, curr) => total + curr.sum, 0).toFixed(2)
        );

        let percentDifference = parseFloat(
          (
            (Math.abs(settings?.standards - sum) / settings?.standards) *
            100
          ).toFixed(2)
        );
        let passesTheStandard = shouldBeHigherThanStandards
          ? sum >= settings?.standards
          : sum <= settings?.standards;

        let sign = passesTheStandard
          ? shouldBeHigherThanStandards
            ? '+'
            : '-'
          : shouldBeHigherThanStandards
            ? '-'
            : '+';
        let standard = `${sign} ${percentDifference}`;
        let sumType =
          this.props.settings.category.includes('CompletionRate') ||
          this.props.settings.category.includes('TaskCompletedOnTime') ||
          this.props.settings.category.includes(
            'CorrectiveActionCompletedOnTime'
          ) ||
          this.props.settings.category.includes('OshaCompliance') ||
          this.props.settings.category.includes('AuditScore')
            ? '%'
            : this.props.settings.category.includes('LagTime')
              ? ' Hours'
              : this.props.settings.category.includes('TimeToClosure')
                ? ' Days'
                : '';
        return (
          <>
            <div
              className={bStyles(styles.statistic, {
                preview
              })}
              style={{
                width: width,
                height: height
              }}
            >
              {`${sum}${sumType}`}
            </div>
            {settings?.standards && (
              <div
                className={bStyles(styles.standards, {
                  preview,
                  good: passesTheStandard,
                  bad: !passesTheStandard
                })}
              >
                {`${standard}%`}
              </div>
            )}
          </>
        );
      case 'bar-trend':
        return (
          <ComposedChart data={data} width={width} height={height} key={width}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" />
            <YAxis
              tickFormatter={v => `${prepend}${v}`}
              domain={[0, parseInt(topYDataValue)]}
              width={70}
            >
              {label && (
                <Label angle={90} position="insideLeft" value={label} />
              )}
            </YAxis>
            <Tooltip
              formatter={v => `${prepend}${v}`}
              wrapperStyle={{ zIndex: 1000 }}
            />
            <Legend />
            <Bar
              dataKey="current"
              fill={colors[offset % colors.length]}
              stroke={this.darkenColor(colors[offset % colors.length], 48)}
              name={this.state.trendLabels.currentLabel}
            />
            <Bar
              dataKey="last"
              fill={colors[(offset % colors.length) + 1]}
              stroke={this.darkenColor(
                colors[(offset % colors.length) + 1],
                48
              )}
              name={this.state.trendLabels.prevLabel}
            />
            <ReferenceLine
              y={settings.standards}
              stroke="red"
              strokeWidth={2}
            />
          </ComposedChart>
        );
      case 'bar':
      default:
        return (
          <BarChart data={data} width={width} height={height} key={width}>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              formatter={v => `${prepend}${v}`}
              wrapperStyle={{ zIndex: 1000 }}
            />
            <XAxis dataKey="label" />
            <YAxis
              tickFormatter={v => `${prepend}${v}`}
              domain={[0, parseInt(topYDataValue)]}
              width={70}
            >
              {label && (
                <Label angle={90} position="insideLeft" value={label} />
              )}
            </YAxis>
            {usedKeys.length ? (
              usedKeys.map((k, i) => (
                <Bar
                  dataKey={k}
                  key={k}
                  fill={colors[(i + offset) % colors.length]}
                  stroke={this.darkenColor(
                    colors[(i + offset) % colors.length],
                    48
                  )}
                  stackId="0"
                />
              ))
            ) : (
              <Bar
                dataKey="sum"
                fill={colors[offset % colors.length]}
                stroke={this.darkenColor(colors[offset % colors.length], 48)}
              />
            )}
            <ReferenceLine
              y={settings.standards}
              stroke="red"
              strokeWidth={2}
            />
          </BarChart>
        );
    }
  }
}
export const mapStateToProps = state => ({
  accessLevel: getAccessLevel(state)
});
// eslint-disable-next-line import/no-anonymous-default-export
export default connect(mapStateToProps)(AnalyticsChart);
