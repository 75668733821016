import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  REACTIVE_REPORT_TYPES,
  PROACTIVE_REPORT_TYPES
} from '../../../constants/constants';
import { states } from '../../../constants/states';
import {
  EMPLOYEMENT_STATUS,
  TYPE_OF_MEDICAL_TREATMENT,
  TYPE_OF_EXPECTED_MEDICAL_TREATMENT,
  RETURN_TO_WORK_INFO,
  LANGUAGES,
  OWNERSHIP_TYPE_CODE,
  VEHICLE_TYPE,
  DRIVER_INJURY_TYPE,
  SINGLE_VEHICLE_ACCIDENT_TYPE
} from '../../../constants/travelerMapping';
import {
  NATURE_OF_INJURY_OPTIONS,
  PART_OF_BODY_OPTIONS,
  INJURY_CAUSE_CODE_OPTIONS,
  YES_NO_UNKNOWN,
  VEHICLE_OWNERSHIP_TYPE_CODE
} from '../../../constants/libertyMutualMapping';
import { getActiveCompany, getAllLocations } from '../../../selectors/company';
import { getAllUsers } from '../../../selectors/users';
import { parseTime, renderTime } from '../../../utils/dateHelpers';
import {
  normalizePhone,
  normalizeSSN,
  notFutureDate
} from '../../../utils/formValidation';
import Card from '../../Card';
import { IReportDatePicker, TimePicker } from '../../inputs/DateTimePicker';
import HierarchySelector from '../../HierarchySelector';
import List from '../../List';
import { Dropdown, EmployeeDropdown, Textbox, TwoColumn } from '../../inputs';
import RadioButtons from '../../inputs/RadioButtons';

import styles from './claimCard.module.scss';

const formatDate = date => (date ? moment(date).format('MM/DD/YYYY') : null);

const getDropdownAnswer = (dropdown, value) =>
  dropdown?.find(d => d.value === value)?.label;

export default function ClaimCard({
  name,
  onChange,
  editing,
  claimSection,
  title,
  openEdit,
  isSubmitted,
  viewOnly,
  missingRequired,
  permissions,
  type,
  locationCodes,
  users
}) {
  return (
    <Card
      showHeader
      title={title}
      name={name}
      rightButton={{
        text: 'Edit',
        visible: !editing && !isSubmitted && !viewOnly,
        color: 'blue',
        onClick: () => openEdit(true)
      }}
    >
      {title === 'Employee Information' ? (
        <EmployeeCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
        />
      ) : title === 'Injury Information' ? (
        <InjuryCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
        />
      ) : title === 'OSHA Information' ? (
        <OshaCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
        />
      ) : title === 'Accident Information' ? (
        <AccidentCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
          type={type}
          locationCodes={locationCodes}
          users={users}
          permissions={permissions}
        />
      ) : title === 'Witness Information' ? (
        <WitnessCard
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
          permissions={permissions}
        />
      ) : title === 'Vehicle Information' ? (
        <VehicleInfomation
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
        />
      ) : title === 'Driver Information' ? (
        <DriverInfomation
          onChange={onChange}
          claimSection={claimSection}
          editing={editing}
          missingRequired={missingRequired}
        />
      ) : (
        <OverviewCard claimSection={claimSection} />
      )}
    </Card>
  );
}

function EmployeeCard({ claimSection, onChange, editing, missingRequired }) {
  const users = useSelector(getAllUsers);

  return (
    <>
      {editing ? (
        <div>
          <EmployeeDropdown
            fieldLabel="Injured Employee Name"
            currentValue={claimSection?.employeeId}
            onChange={value => onChange({ ...claimSection, employeeId: value })}
            searchable
            name="employeeInformation"
            isRequired
            disabled
            touched={missingRequired && !claimSection?.employeeId}
          />
          <TwoColumn>
            <Dropdown
              options={[
                { label: 'Male', value: 1 },
                { label: 'Female', value: 2 }
              ]}
              currentValue={claimSection?.employeeGender}
              onChange={value =>
                onChange({ ...claimSection, employeeGender: value })
              }
              fieldLabel="Gender"
              isRequired
              touched={missingRequired && !claimSection?.employeeGender}
            />
            <Textbox
              currentValue={claimSection?.employeeSSN}
              fieldLabel="Social Security Number"
              onChange={value =>
                onChange({ ...claimSection, employeeSSN: normalizeSSN(value) })
              }
              placeholder="XXX-XX-XXXX"
              isRequired
              tooltip="Full SSN will not be saved in our system, just last four. Don't add until you are ready to submit the claim."
              displayTooltip
              touched={missingRequired && !claimSection?.employeeSSN}
            />
            <Textbox
              currentValue={claimSection?.streetAddress}
              fieldLabel="Street Address"
              onChange={value =>
                onChange({ ...claimSection, streetAddress: value })
              }
              isRequired
              touched={missingRequired && !claimSection?.streetAddress}
            />
            <Textbox
              currentValue={claimSection?.city}
              fieldLabel="City"
              onChange={value => onChange({ ...claimSection, city: value })}
              isRequired
              touched={missingRequired && !claimSection?.city}
            />
            <Dropdown
              fieldLabel="State"
              options={states}
              currentValue={claimSection?.state}
              onChange={value => onChange({ ...claimSection, state: value })}
              isRequired
              touched={missingRequired && !claimSection?.state}
            />
            <Textbox
              fieldLabel="Zip Code"
              currentValue={claimSection?.zip}
              onChange={value => onChange({ ...claimSection, zip: value })}
              isRequired
              error={
                claimSection.zip?.length !== 5
                  ? 'Zip Code must be 5 numbers'
                  : null
              }
              touched={!claimSection.zip && missingRequired}
              type="number"
            />
            <Textbox
              currentValue={claimSection?.employeePhone}
              fieldLabel="Phone Number"
              onChange={value =>
                onChange({
                  ...claimSection,
                  employeePhone: normalizePhone(value)
                })
              }
              placeholder="XXX-XXX-XXXX"
              isRequired
              touched={missingRequired && !claimSection?.employeePhone}
            />
            <Textbox
              currentValue={claimSection?.employeeEmail}
              fieldLabel="Email"
              onChange={value =>
                onChange({ ...claimSection, employeeEmail: value })
              }
              placeholder="test@test.com"
              isRequired
              touched={missingRequired && !claimSection?.employeeEmail}
            />
            <Dropdown
              options={LANGUAGES}
              currentValue={claimSection?.employeeLanguage}
              fieldLabel="Language"
              onChange={value =>
                onChange({
                  ...claimSection,
                  employeeLanguage: value
                })
              }
              isRequired
              touched={missingRequired && !claimSection?.employeeLanguage}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <IReportDatePicker
                fieldLabel="Hire Date"
                currentValue={claimSection?.employeeDateOfHire}
                onChange={value =>
                  onChange({ ...claimSection, employeeDateOfHire: value })
                }
                isRequired
                name="hireDate"
                touched={missingRequired && !claimSection?.employeeDateOfHire}
                warning={notFutureDate(claimSection?.employeeDateOfHire)}
              />
              <IReportDatePicker
                fieldLabel="Date of Birth"
                currentValue={claimSection?.employeeDateOfBirth}
                onChange={value =>
                  onChange({ ...claimSection, employeeDateOfBirth: value })
                }
                name="birthDate"
              />
            </div>
            <Textbox
              fieldLabel="Occupation"
              currentValue={claimSection?.employeeOccupation}
              onChange={value =>
                onChange({ ...claimSection, employeeOccupation: value })
              }
              isRequired
              touched={!claimSection?.employeeOccupation && missingRequired}
              error={
                claimSection.employeeOccupation?.length > 35 ||
                claimSection.employeeOccupation?.length < 2
                  ? 'Occupation must be between 2 and 35 characters'
                  : null
              }
            />
            <EmployeeDropdown
              fieldLabel="Supervisor"
              currentValue={claimSection?.supervisorId}
              onChange={value =>
                onChange({ ...claimSection, supervisorId: value })
              }
              searchable
              name="employeeInformation"
              isRequired
              touched={missingRequired && !claimSection?.supervisorId}
              permissions={[400, 500, 900]}
            />
            <Dropdown
              fieldLabel="Employment Status"
              options={EMPLOYEMENT_STATUS}
              isRequired
              onChange={value =>
                onChange({ ...claimSection, employmentStatus: value })
              }
              currentValue={claimSection?.employmentStatus}
              touched={missingRequired && !claimSection?.employmentStatus}
            />
            {/* <Textbox
              fieldLabel="Number of Days Worked Per Week"
              currentValue={claimSection?.numberOfDaysWorkedPerWeek}
              onChange={value =>
                onChange({ ...claimSection, numberOfDaysWorkedPerWeek: value })
              }
              type="number"
              // touched={
              //   !claimSection.numberOfDaysWorkedPerWeek && missingRequired
              // }
              errorMessage={
                claimSection.numberOfDaysWorkedPerWeek > 7 ||
                claimSection.numberOfDaysWorkedPerWeek < 1
                  ? 'Number of Days must be between 1 and 7'
                  : null
              }
            />
            <Textbox
              fieldLabel="Number of Hours Worked Per Week"
              currentValue={claimSection?.numberOfHoursWorkedPerWeek}
              onChange={value =>
                onChange({ ...claimSection, numberOfHoursWorkedPerWeek: value })
              }
              type="number"
              // touched={
              //   !claimSection.numberOfHoursWorkedPerWeek && missingRequired
              // }
              errorMessage={
                claimSection.numberOfHoursWorkedPerWeek > 168 ||
                claimSection.numberOfHoursWorkedPerWeek < 1
                  ? 'Number of Hours must be between 1 and 168'
                  : null
              }
            /> */}
          </TwoColumn>
        </div>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Injured Employee Name</div>
          <div>{`${claimSection?.employeeFirstName} ${claimSection?.employeeLastName}`}</div>
          <div className={styles.claimLabel}>Gender</div>
          <div>
            {claimSection?.employeeGender === 1
              ? 'Male'
              : claimSection?.employeeGender === 2
                ? 'Female'
                : 'N/A'}
          </div>
          <div className={styles.claimLabel}>Social Security Number</div>
          <div>{claimSection?.employeeSSN}</div>
          <div className={styles.claimLabel}>Employee Address</div>
          <div className={styles.stackedText}>
            <div>{claimSection?.streetAddress}</div>
            <div>{`${claimSection?.city}, ${claimSection?.state} ${claimSection?.zip}`}</div>
          </div>
          <div className={styles.claimLabel}>Phone Number</div>
          <div>{claimSection?.employeePhone}</div>
          <div className={styles.claimLabel}>Email</div>
          <div>{claimSection?.employeeEmail}</div>
          <div className={styles.claimLabel}>Language</div>
          <div>
            {getDropdownAnswer(LANGUAGES, claimSection.employeeLanguage)}
          </div>
          <div className={styles.claimLabel}>Date of Hire</div>
          <div>{formatDate(claimSection?.employeeDateOfHire)}</div>
          <div className={styles.claimLabel}>Date of Birth</div>
          <div>{formatDate(claimSection?.employeeDateOfBirth)}</div>
          <div className={styles.claimLabel}>Occupation</div>
          <div>{claimSection?.employeeOccupation}</div>
          <div className={styles.claimLabel}>Supervisor</div>
          <div>
            {getDropdownAnswer(
              users.map(u => ({
                label: `${u.firstName} ${u.lastName}`,
                value: u._id
              })),
              claimSection.supervisorId
            )}
          </div>
          <div className={styles.claimLabel}>Employement Status</div>
          <div>
            {getDropdownAnswer(
              EMPLOYEMENT_STATUS,
              claimSection.employmentStatus
            )}
          </div>
          {/* <div className={styles.claimLabel}>
            Number of Days Worked Per Week
          </div>
          <div>{claimSection?.numberOfDaysWorkedPerWeek}</div>
          <div className={styles.claimLabel}>
            Number of Hours Worked Per Day
          </div>
          <div>{claimSection?.numberOfHoursWorkedPerWeek}</div> */}
        </TwoColumn>
      )}
    </>
  );
}

function InjuryCard({ claimSection, onChange, editing, missingRequired }) {
  return (
    <>
      {editing ? (
        <div>
          <TwoColumn>
            <Dropdown
              searchable
              fieldLabel="Body Part Affected"
              options={PART_OF_BODY_OPTIONS}
              currentValue={parseInt(claimSection?.bodyPartAffected)}
              alphabetize
              onChange={values =>
                onChange({ ...claimSection, bodyPartAffected: values })
              }
              isRequired
              touched={missingRequired && !claimSection?.bodyPartAffected}
            />
            <Dropdown
              searchable
              fieldLabel="Nature Of Injury"
              options={
                claimSection.bodyPartAffected
                  ? NATURE_OF_INJURY_OPTIONS.filter(o =>
                      o.applicableBodyParts.includes(
                        parseInt(claimSection?.bodyPartAffected)
                      )
                    )
                  : NATURE_OF_INJURY_OPTIONS
              }
              currentValue={claimSection?.natureOfInjury}
              alphabetize
              onChange={values =>
                onChange({ ...claimSection, natureOfInjury: values })
              }
              isRequired
              touched={missingRequired && !claimSection?.natureOfInjury}
            />
            <Dropdown
              searchable
              fieldLabel="Cause of Injury"
              options={INJURY_CAUSE_CODE_OPTIONS}
              currentValue={parseInt(claimSection?.sourceOfInjury)}
              alphabetize
              onChange={values =>
                onChange({ ...claimSection, sourceOfInjury: values })
              }
              touched={missingRequired && !claimSection?.sourceOfInjury}
              isRequired
            />
            <Dropdown
              searchable
              fieldLabel="Type of Medical Treatment"
              options={TYPE_OF_MEDICAL_TREATMENT}
              currentValue={parseInt(claimSection?.typeOfMedicalTreatment)}
              alphabetize
              onChange={values =>
                onChange({ ...claimSection, typeOfMedicalTreatment: values })
              }
              isRequired
              touched={missingRequired && !claimSection?.typeOfMedicalTreatment}
            />
            {/* <IReportDatePicker
              fieldLabel="First Day of Treatment"
              currentValue={claimSection?.firstDayOfTreatment}
              onChange={value =>
                onChange({ ...claimSection, firstDayOfTreatment: value })
              }
              name="firstDayOfTreatment"
            /> 
            <Textbox
              fieldLabel="Treatment Description"
              currentValue={claimSection.oshaTreatmentDesc}
              onChange={values =>
                onChange({ ...claimSection, oshaTreatmentDesc: values })
              }
              errorMessage={
                claimSection?.oshaTreatmentDesc?.length > 30 ||
                claimSection?.oshaTreatmentDesc?.length < 1
                  ? ' Description must be between 1 and 30 Characters'
                  : null
              }
            /> */}
            <RadioButtons
              currentValue={claimSection?.futureMedicalTreatment}
              fieldLabel="Is future medical treatment needed?"
              options={YES_NO_UNKNOWN}
              onChange={values =>
                onChange({ ...claimSection, futureMedicalTreatment: values })
              }
              valueKey="travelersValue"
              isRequired
            />
            {claimSection?.futureMedicalTreatment === 'Y' ? (
              <Dropdown
                searchable
                fieldLabel="Type of Future Medical Treatment"
                options={TYPE_OF_EXPECTED_MEDICAL_TREATMENT}
                currentValue={claimSection?.typeOfExpectedMedicalTreatment}
                alphabetize
                onChange={values =>
                  onChange({
                    ...claimSection,
                    typeOfExpectedMedicalTreatment: values
                  })
                }
                isRequired
                touched={
                  missingRequired &&
                  !claimSection?.typeOfExpectedMedicalTreatment
                }
              />
            ) : null}
          </TwoColumn>
          <TwoColumn>
            <Dropdown
              searchable
              fieldLabel="Return to Work Details"
              options={RETURN_TO_WORK_INFO}
              currentValue={claimSection?.returnToWorkDuties}
              alphabetize
              onChange={values =>
                onChange({
                  ...claimSection,
                  returnToWorkDuties: values
                })
              }
              isRequired
              touched={missingRequired && !claimSection?.returnToWorkDuties}
            />
            {claimSection?.returnToWorkDuties === 'FATAL' ? (
              <IReportDatePicker
                fieldLabel="Date of Fatality"
                currentValue={claimSection?.employeeDeathDate}
                onChange={value =>
                  onChange({ ...claimSection, employeeDeathDate: value })
                }
                name="dateOfFatality"
                isRequired
                touched={missingRequired && !claimSection?.employeeDeathDate}
              />
            ) : null}
            {claimSection?.returnToWorkDuties ===
            'NOTRETURNEDTOWORKWITHDATE' ? (
              <IReportDatePicker
                fieldLabel="Anticipated Return to Work Date"
                currentValue={claimSection?.anticipatedReturnToWorkDate}
                onChange={value =>
                  onChange({
                    ...claimSection,
                    anticipatedReturnToWorkDate: value
                  })
                }
                name="dateOfAnticipatedReturnToWork"
                isRequired
              />
            ) : null}
            {['RETURNEDTOWORKLOSTTIME', 'RETURNEDTOWORKNOLOSTTIME'].includes(
              claimSection?.returnToWorkDuties
            ) ? (
              <RadioButtons
                currentValue={claimSection?.isOnLightModifiedDuty}
                fieldLabel="Is employee on light or modified duty?"
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]}
                onChange={values =>
                  onChange({ ...claimSection, isOnLightModifiedDuty: values })
                }
                isRequired
              />
            ) : null}
            {claimSection?.returnToWorkDuties === 'RETURNEDTOWORKLOSTTIME' ? (
              <IReportDatePicker
                fieldLabel="Return To Work Date"
                currentValue={claimSection?.returnToWorkDate}
                onChange={value =>
                  onChange({
                    ...claimSection,
                    returnToWorkDate: value
                  })
                }
                name="dateOfReturnToWork"
                isRequired
              />
            ) : null}
          </TwoColumn>
        </div>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Body Part Affected</div>
          <div>
            {getDropdownAnswer(
              PART_OF_BODY_OPTIONS,
              parseInt(claimSection?.bodyPartAffected)
            )}
          </div>
          <div className={styles.claimLabel}>Nature Of Injury</div>
          <div>
            {getDropdownAnswer(
              NATURE_OF_INJURY_OPTIONS,
              claimSection.natureOfInjury
            )}
          </div>
          <div className={styles.claimLabel}>Injury Cause</div>
          <div>
            {getDropdownAnswer(
              INJURY_CAUSE_CODE_OPTIONS,
              claimSection.sourceOfInjury
            )}
          </div>
          <div className={styles.claimLabel}>Type of Medical Treatment</div>
          <div>
            {getDropdownAnswer(
              TYPE_OF_MEDICAL_TREATMENT,
              claimSection.typeOfMedicalTreatment
            )}
          </div>
          {/* <div className={styles.claimLabel}>First day of treatment</div>
          <div>{formatDate(claimSection.firstDayOfTreatment)}</div>
          <div className={styles.claimLabel}>Treatment Description</div>
          <div>{claimSection.oshaTreatmentDesc}</div> */}
          <div className={styles.claimLabel}>
            Is future medical treatment needed?
          </div>
          <div>
            {getDropdownAnswer(
              YES_NO_UNKNOWN.map(y => ({
                label: y.label,
                value: y.travelersValue
              })),
              claimSection.futureMedicalTreatment
            )}
          </div>
          {claimSection.futureMedicalTreatment === 'Y' ? (
            <>
              <div className={styles.claimLabel}>
                Type of Future Medical Treatment
              </div>
              <div>
                {getDropdownAnswer(
                  TYPE_OF_EXPECTED_MEDICAL_TREATMENT,
                  claimSection.typeOfExpectedMedicalTreatment
                )}
              </div>
            </>
          ) : null}
          <div className={styles.claimLabel}>Return to Work Details</div>
          <div>
            {getDropdownAnswer(
              RETURN_TO_WORK_INFO,
              claimSection.returnToWorkDuties
            )}
          </div>
          {claimSection.returnToWorkDuties === 'FATAL' ? (
            <>
              <div className={styles.claimLabel}>Date of Fatality</div>
              <div>{formatDate(claimSection.anticipatedReturnToWorkDate)}</div>
            </>
          ) : claimSection.returnToWorkDuties ===
            'NOTRETURNEDTOWORKWITHDATE' ? (
            <>
              <div className={styles.claimLabel}>
                Is employee on light or modified duty
              </div>
              <div>{claimSection.isOnLightModifiedDuty ? 'Yes' : 'No'}</div>
            </>
          ) : ['RETURNEDTOWORKLOSTTIME', 'RETURNEDTOWORKNOLOSTTIME'].includes(
              claimSection.returnToWorkDuties
            ) ? (
            <>
              <div className={styles.claimLabel}>First day of treatment</div>
              <div>{formatDate(claimSection.firstDayOfTreatment)}</div>
            </>
          ) : claimSection.returnToWorkDuties === 'RETURNEDTOWORKLOSTTIME' ? (
            <>
              <div className={styles.claimLabel}>Return to Work Date</div>
              <div>{formatDate(claimSection.returnToWorkDate)}</div>{' '}
            </>
          ) : null}
        </TwoColumn>
      )}
    </>
  );
}

function OshaCard({ claimSection, onChange, editing, missingRequired }) {
  const [hours, minutes] = parseTime(claimSection.oshaTimeWorkerStarted);
  return (
    <>
      {editing ? (
        <>
          <RadioButtons
            currentValue={claimSection?.isOshaRecordable}
            fieldLabel="Is this claim OSHA Recordable?"
            options={YES_NO_UNKNOWN}
            onChange={values =>
              onChange({ ...claimSection, isOshaRecordable: values })
            }
            valueKey="travelersValue"
            isRequired
          />
          {claimSection?.isOshaRecordable === 'Y' ? (
            <div>
              <TwoColumn>
                <RadioButtons
                  currentValue={claimSection?.isPrivacyCase}
                  fieldLabel="Is this injury considered an OSHA privacy case?"
                  options={YES_NO_UNKNOWN}
                  onChange={values =>
                    onChange({ ...claimSection, isPrivacyCase: values })
                  }
                  valueKey="travelersValue"
                  isRequired
                />
                <RadioButtons
                  currentValue={claimSection?.isSharpsInjury}
                  fieldLabel="Is this a potentially contaminated needlestick/sharps injury?"
                  options={YES_NO_UNKNOWN}
                  onChange={values =>
                    onChange({ ...claimSection, isSharpsInjury: values })
                  }
                  valueKey="travelersValue"
                />
                {claimSection?.isSharpsInjury === 'Y' ? (
                  <Textbox
                    fieldLabel="If this is a sharps injury, what is the type, brand and manufacturer of the device involved"
                    currentValue={claimSection.sharpsDeviceType}
                    onChange={values =>
                      onChange({ ...claimSection, sharpsDeviceType: values })
                    }
                    error={
                      claimSection?.sharpsDeviceType?.length > 127 ||
                      claimSection?.sharpsDeviceType?.length < 1
                        ? 'Device type must be between 1 and 127 Characters'
                        : null
                    }
                  />
                ) : null}
                <Textbox
                  fieldLabel="What was the employee doing just before the injury occurred?"
                  currentValue={claimSection.oshaActivityDesc}
                  onChange={values =>
                    onChange({ ...claimSection, oshaActivityDesc: values })
                  }
                  error={
                    claimSection?.oshaActivityDesc?.length > 127 ||
                    claimSection?.oshaActivityDesc?.length < 1
                      ? 'Activity Description must be between 1 and 127 Characters'
                      : null
                  }
                />
                <Textbox
                  fieldLabel="Where specifically did the injury occur?"
                  currentValue={claimSection.oshaLocationDesc}
                  onChange={values =>
                    onChange({ ...claimSection, oshaLocationDesc: values })
                  }
                  error={
                    claimSection?.oshaLocationDesc?.length > 127 ||
                    claimSection?.oshaLocationDesc?.length < 1
                      ? 'Location Description must be between 1 and 127 Characters'
                      : null
                  }
                />
                <TimePicker
                  fieldLabel="Time employee started work"
                  currentValue={claimSection.oshaTimeWorkerStarted}
                  onChange={(hours, minutes) =>
                    onChange({
                      ...claimSection,
                      oshaTimeWorkerStarted: renderTime(hours, minutes, true)
                    })
                  }
                  hours={hours}
                  minutes={minutes}
                />
              </TwoColumn>
            </div>
          ) : null}
        </>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>
            Is this claim OSHA Recordable?
          </div>
          <div>
            {getDropdownAnswer(
              YES_NO_UNKNOWN.map(y => ({
                label: y.label,
                value: y.travelersValue
              })),
              claimSection.isOshaRecordable
            )}
          </div>
          <div className={styles.claimLabel}>
            Is this injury considered an OSHA privacy case?
          </div>
          <div>
            {getDropdownAnswer(
              YES_NO_UNKNOWN.map(y => ({
                label: y.label,
                value: y.travelersValue
              })),
              claimSection.isPrivacyCase
            )}
          </div>
          <div className={styles.claimLabel}>
            Is this a potentially contaminated needlestick/sharps injury?
          </div>
          <div>
            {getDropdownAnswer(
              YES_NO_UNKNOWN.map(y => ({
                label: y.label,
                value: y.travelersValue
              })),
              claimSection.isSharpsInjury
            )}
          </div>
          <div className={styles.claimLabel}>
            If this is a sharps injury, what is the type, brand and manufacturer
            of the device involved?
          </div>
          <div>{claimSection?.sharpsDeviceType}</div>
          <div className={styles.claimLabel}>
            What was the employee doing just before the injury occurred?
          </div>
          <div>{claimSection.oshaActivityDesc}</div>
          <div className={styles.claimLabel}>
            Where specifically did the injury occur?
          </div>
          <div>{claimSection.oshaLocationDesc}</div>
          <div className={styles.claimLabel}>Time employee started work?</div>
          <div>{claimSection.oshaTimeWorkerStarted}</div>
        </TwoColumn>
      )}
    </>
  );
}

function AccidentCard({
  claimSection,
  onChange,
  editing,
  missingRequired,
  type,
  locationCodes,
  permissions
}) {
  const allGroups = useSelector(getAllLocations);

  const findGroupName = groupId =>
    allGroups?.find(g => g._id === groupId)?.name;

  const findProjectName = (groupId, projectId) =>
    allGroups
      ?.find(g => g._id === groupId)
      ?.projects?.find(p => p._id === projectId)?.name;

  const codes = locationCodes?.map(code => ({
    value: code._id,
    label: `${code.organizationCode} - ${code.organizationText}`
  }));

  return (
    <>
      {editing ? (
        <div>
          <HierarchySelector
            groupId={claimSection.groupId}
            projectId={claimSection.projectId}
            onGroupChange={value =>
              onChange({ ...claimSection, groupId: value })
            }
            onProjectChange={value =>
              onChange({ ...claimSection, projectId: value })
            }
          />
          <TwoColumn>
            <IReportDatePicker
              fieldLabel="Incident Date"
              pickTime
              currentValue={claimSection.incidentDate}
              onChange={values =>
                onChange({ ...claimSection, incidentDate: values })
              }
              isRequired
              name="incidentDate"
              touched={!claimSection?.incidentDate && missingRequired}
              error={notFutureDate(claimSection?.incidentDate)}
            />
            <EmployeeDropdown
              fieldLabel="Who is the primary contact for this claim?"
              currentValue={claimSection?.claimNotificationOther}
              onChange={value =>
                onChange({ ...claimSection, claimNotificationOther: value })
              }
              searchable
              name="claimNotificationOther"
              isRequired
              touched={missingRequired && !claimSection.claimNotificationOther}
              employees={permissions}
            />
            <Textbox
              fieldLabel="Incident Description"
              currentValue={claimSection.incidentDescription}
              onChange={values =>
                onChange({ ...claimSection, incidentDescription: values })
              }
              isRequired
              touched={!claimSection?.incidentDescription && missingRequired}
              type="textarea"
            />
            <RadioButtons
              currentValue={claimSection?.isCompanyAddress}
              fieldLabel="Did loss occur at company address?"
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' }
              ]}
              onChange={values =>
                onChange({ ...claimSection, isCompanyAddress: values })
              }
              isRequired
              touched={missingRequired && !claimSection.isCompanyAddress}
            />
            <Dropdown
              searchable
              options={codes}
              currentValue={claimSection.incidentLocationCounty}
              fieldLabel="Location Code"
              onChange={values =>
                onChange({ ...claimSection, incidentLocationCounty: values })
              }
              isRequired
              touched={missingRequired && !claimSection.incidentLocationCounty}
            />
            {claimSection?.isCompanyAddress === 'no' ? (
              <>
                <Textbox
                  currentValue={claimSection?.streetAddress}
                  fieldLabel="Incident Street Address"
                  onChange={value =>
                    onChange({ ...claimSection, streetAddress: value })
                  }
                  touched={!claimSection?.streetAddress && missingRequired}
                  isRequired
                />
                <Textbox
                  currentValue={claimSection?.city}
                  fieldLabel="Incident City"
                  onChange={value => onChange({ ...claimSection, city: value })}
                  touched={!claimSection?.city && missingRequired}
                  isRequired
                />
                <Dropdown
                  fieldLabel="Incident State"
                  options={states}
                  currentValue={claimSection?.state}
                  touched={!claimSection?.state && missingRequired}
                  onChange={value =>
                    onChange({ ...claimSection, state: value })
                  }
                  isRequired
                />
                <Textbox
                  fieldLabel="Incident Zip Code"
                  currentValue={claimSection?.zip}
                  onChange={value => onChange({ ...claimSection, zip: value })}
                  isRequired
                  error={
                    claimSection.zip?.length !== 5
                      ? 'Zip Code must be 5 numbers'
                      : null
                  }
                  touched={!claimSection.zip?.length > 0 && missingRequired}
                  type="number"
                />
              </>
            ) : null}
            {type === 'auto' ? (
              <>
                <RadioButtons
                  options={YES_NO_UNKNOWN.filter(o => o.label !== 'Unknown')}
                  currentValue={claimSection.hasInjuredPassengers}
                  fieldLabel="Are there injured passengers?"
                  onChange={value => {
                    onChange({
                      ...claimSection,
                      hasInjuredPassengers: value
                    });
                  }}
                  isRequired
                  touched={
                    missingRequired && !claimSection.hasInjuredPassengers
                  }
                />
                <RadioButtons
                  options={[
                    { label: 'Single Vehicle Accident', value: 'single' },
                    {
                      label: 'Multiple Vehicle Accident',
                      value: 'multiple'
                    }
                  ]}
                  currentValue={claimSection.vehiclesInvolved}
                  fieldLabel="Type of accident?"
                  onChange={value => {
                    onChange({
                      ...claimSection,
                      vehiclesInvolved: value
                    });
                  }}
                  isRequired
                  touched={missingRequired && !claimSection.vehiclesInvolved}
                />
                {claimSection.vehiclesInvolved === 'single' ? (
                  <Dropdown
                    fieldLabel="Type of single car accident?"
                    options={SINGLE_VEHICLE_ACCIDENT_TYPE}
                    currentValue={claimSection?.eventDescriptionCode}
                    touched={
                      !claimSection?.eventDescriptionCode && missingRequired
                    }
                    onChange={value =>
                      onChange({
                        ...claimSection,
                        eventDescriptionCode: value
                      })
                    }
                    isRequired
                  />
                ) : null}
                {claimSection.vehiclesInvolved === 'multiple' ? (
                  <Textbox
                    fieldLabel="Number of cars involved"
                    currentValue={claimSection?.numberOfVehiclesInvolved}
                    onChange={value =>
                      onChange({
                        ...claimSection,
                        numberOfVehiclesInvolved: value
                      })
                    }
                    isRequired
                    touched={
                      !claimSection.numberOfVehiclesInvolved?.length > 0 &&
                      missingRequired
                    }
                    type="number"
                    min="2"
                    max="4"
                    displayTooltip
                    tooltip="If there are more than 4 cars involved, just use 4 as number involved"
                  />
                ) : null}
              </>
            ) : null}
          </TwoColumn>
        </div>
      ) : (
        <>
          <TwoColumn className={styles.claimCard}>
            <div className={styles.claimLabel}>Group/Est.</div>
            <div>{findGroupName(claimSection.groupId)}</div>
            <div className={styles.claimLabel}>Area</div>
            <div>
              {findProjectName(claimSection.groupId, claimSection.projectId)}
            </div>
            <div className={styles.claimLabel}>Incident Date</div>
            <div>{formatDate(claimSection.incidentDate)}</div>
            <div className={styles.claimLabel}>
              Who is the primary contact for this claim?
            </div>
            <div>
              {getDropdownAnswer(
                permissions.map(u => ({
                  label: `${u.firstName} ${u.lastName}`,
                  value: u._id
                })),
                claimSection?.claimNotificationOther
              )}
            </div>
            <div className={styles.claimLabel}>Incident Description</div>
            <div>{claimSection.incidentDescription}</div>
            <div className={styles.claimLabel}>
              Did loss occur at company address?
            </div>
            <div>
              {getDropdownAnswer(
                [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' }
                ],
                claimSection.isCompanyAddress
              )}
            </div>
            <div className={styles.claimLabel}>Location Code</div>
            <div>
              {getDropdownAnswer(codes, claimSection.incidentLocationCounty)}
            </div>
            {claimSection.isCompanyAddress === 'no' ? (
              <>
                <div className={styles.claimLabel}>Incident Address</div>
                <div className={styles.stackedText}>
                  <div>{claimSection?.streetAddress}</div>
                  <div>{`${claimSection?.city}, ${claimSection?.state} ${
                    claimSection?.zip
                  }`}</div>
                </div>
              </>
            ) : null}
          </TwoColumn>
          {type === 'auto' ? (
            <TwoColumn className={styles.claimCard}>
              <div className={styles.claimLabel}>
                Are there injured passengers?
              </div>
              <div>
                {getDropdownAnswer(
                  YES_NO_UNKNOWN,
                  claimSection.hasInjuredPassengers
                )}
              </div>
              <div className={styles.claimLabel}>Type of accident?</div>
              <div>
                {getDropdownAnswer(
                  [
                    { label: 'Single Vehicle Accident', value: 'single' },
                    {
                      label: 'Multiple Vehicle Accident',
                      value: 'multiple'
                    }
                  ],
                  claimSection.vehiclesInvolved
                )}
              </div>
              {claimSection?.vehiclesInvolved === 'single' ? (
                <>
                  <div className={styles.claimLabel}>
                    Type of single care accident?
                  </div>
                  <div>
                    {getDropdownAnswer(
                      SINGLE_VEHICLE_ACCIDENT_TYPE,
                      claimSection.eventDescriptionCode
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.claimLabel}>
                    Number of vehicles involved
                  </div>
                  <div>{claimSection.numberOfVehiclesInvolved}</div>
                </>
              )}
            </TwoColumn>
          ) : null}
        </>
      )}
    </>
  );
}

function WitnessCard({ claimSection, onChange, editing, missingRequired }) {
  const handlePedestrianChange = (index, value) => {
    let thirdPartyPedestrians = claimSection?.thirdPartyPedestrians;
    thirdPartyPedestrians[index] = {
      ...thirdPartyPedestrians[index],
      ...value
    };
    onChange({ ...claimSection, thirdPartyPedestrians });
  };
  return (
    <>
      {editing ? (
        <div>
          <RadioButtons
            currentValue={claimSection?.areThereWitnesses}
            fieldLabel="Are there any witnesses?"
            options={[
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' }
            ]}
            onChange={values =>
              onChange({ ...claimSection, areThereWitnesses: values })
            }
            isRequired
          />
          {claimSection.areThereWitnesses === 'yes' ? (
            <>
              <Textbox
                fieldLabel="How many witnesses are involved?"
                type="number"
                currentValue={
                  claimSection?.numberOfThirdPartyPedestriansInvolved
                }
                onChange={value =>
                  onChange({
                    ...claimSection,
                    numberOfThirdPartyPedestriansInvolved:
                      value > 3 ? 3 : value,
                    thirdPartyPedestrians:
                      value && value > 3
                        ? Array(parseInt(3)).fill({
                            firstName: '',
                            lastName: '',
                            phoneNumber: ''
                          })
                        : value
                          ? Array(parseInt(value)).fill({
                              firstName: '',
                              lastName: '',
                              phoneNumber: ''
                            })
                          : []
                  })
                }
                isRequired
                touched={
                  missingRequired &&
                  !claimSection?.numberOfThirdPartyPedestriansInvolved
                }
                tooltip="Max Number of witnesses allowed is 3"
                displayTooltip
                max="3"
              />
              {claimSection?.thirdPartyPedestrians?.map((pedestrian, i) => (
                <div key={i}>
                  <h3
                    className={styles.thirdPartyHeader}
                  >{`Witness #${i + 1}`}</h3>
                  <TwoColumn>
                    <Textbox
                      fieldLabel="First Name"
                      currentValue={pedestrian?.firstName}
                      onChange={value =>
                        handlePedestrianChange(i, {
                          firstName: value
                        })
                      }
                      isRequired
                      touched={missingRequired && !pedestrian.firstName}
                      error={
                        pedestrian?.firstName?.length > 9 ||
                        pedestrian?.firstName?.length < 1
                          ? 'First Name must be between 1 and 9 Characters'
                          : null
                      }
                    />
                    <Textbox
                      fieldLabel="Last Name"
                      currentValue={pedestrian?.lastName}
                      onChange={value =>
                        handlePedestrianChange(i, {
                          lastName: value
                        })
                      }
                      isRequired
                      touched={missingRequired && !pedestrian?.lastName}
                      error={
                        pedestrian?.firstName?.length > 15 ||
                        pedestrian?.firstName?.length < 1
                          ? 'Last Name must be between 1 and 15 Characters'
                          : null
                      }
                    />
                    <Textbox
                      currentValue={pedestrian?.phoneNumber}
                      fieldLabel="Phone Number"
                      onChange={value =>
                        handlePedestrianChange(i, {
                          phoneNumber: normalizePhone(value)
                        })
                      }
                      placeholder="XXX-XXX-XXXX"
                      isRequired
                      touched={missingRequired && !pedestrian?.phoneNumber}
                    />
                  </TwoColumn>
                </div>
              ))}
            </>
          ) : null}
        </div>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Are there any witnesses?</div>
          <div>
            {getDropdownAnswer(
              [
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' }
              ],
              claimSection.areThereWitnesses
            )}
          </div>
          {claimSection.areThereWitnesses === 'yes' ? (
            <>
              <div className={styles.claimLabel}>
                How many witnesses are involved?
              </div>
              <div>{claimSection?.numberOfThirdPartyPedestriansInvolved}</div>
              {claimSection?.thirdPartyPedestrians?.map((pedestrian, i) => (
                <>
                  <div
                    className={styles.claimLabel}
                  >{`Witness #${i + 1} Name`}</div>
                  <div>
                    {`${pedestrian?.firstName} ${pedestrian?.lastName}`}
                  </div>
                  <div
                    className={styles.claimLabel}
                  >{`Witness #${i + 1} Phone`}</div>
                  <div>{pedestrian.phoneNumber}</div>
                </>
              ))}
            </>
          ) : null}
        </TwoColumn>
      )}
    </>
  );
}

function VehicleInfomation({
  claimSection,
  onChange,
  editing,
  missingRequired
}) {
  return (
    <>
      {editing ? (
        <TwoColumn>
          <RadioButtons
            options={YES_NO_UNKNOWN.filter(o => o.label !== 'Unknown')}
            currentValue={claimSection.reportingClaimAsRecordOnlyIndicator}
            fieldLabel="Are you reporting this claim as RECORD ONLY?"
            displayTooltip
            tooltip="An indicator the policy holder is reporting the incident for reference or 'record only'"
            onChange={value => {
              onChange({
                ...claimSection,
                reportingClaimAsRecordOnlyIndicator: value
              });
            }}
          />
          <RadioButtons
            options={YES_NO_UNKNOWN.filter(o => o.label !== 'Unknown')}
            currentValue={claimSection.primaryVehicleDamaged}
            fieldLabel="Was the primary vehicle damaged?"
            onChange={value => {
              onChange({
                ...claimSection,
                primaryVehicleDamaged: value
              });
            }}
            isRequired
            touched={!claimSection?.primaryVehicleDamaged && missingRequired}
          />
          <RadioButtons
            options={YES_NO_UNKNOWN.filter(o => o.label !== 'Unknown')}
            currentValue={claimSection.primaryVehicleDrivable}
            fieldLabel="Is the primary vehicle drivable?"
            onChange={value => {
              onChange({
                ...claimSection,
                primaryVehicleDrivable: value
              });
            }}
            isRequired
            touched={!claimSection?.primaryVehicleDrivable && missingRequired}
          />
          <Dropdown
            options={OWNERSHIP_TYPE_CODE}
            currentValue={claimSection.vehicleOwnershipTypeCode}
            fieldLabel="Vehicle Ownership Type"
            onChange={value => {
              onChange({
                ...claimSection,
                vehicleOwnershipTypeCode: value
              });
            }}
            isRequired
            touched={!claimSection?.vehicleOwnershipTypeCode && missingRequired}
          />
          <Dropdown
            options={VEHICLE_TYPE}
            currentValue={claimSection.typeOfVehicle}
            fieldLabel="Vehicle Type"
            onChange={value => {
              onChange({
                ...claimSection,
                typeOfVehicle: value
              });
            }}
            isRequired
            touched={!claimSection?.typeOfVehicle && missingRequired}
          />
          <Textbox
            fieldLabel="Year"
            currentValue={claimSection.year}
            onChange={values => onChange({ ...claimSection, year: values })}
            isRequired
            type="number"
            placeholder="eg 2022"
            touched={!claimSection?.year && missingRequired}
          />
          <Textbox
            fieldLabel="Make"
            currentValue={claimSection.make}
            onChange={values => onChange({ ...claimSection, make: values })}
            isRequired
            touched={!claimSection?.make && missingRequired}
            errorMessage={
              claimSection?.make?.length < 1 || claimSection?.make?.length > 40
                ? 'Make must be between 1 and 40 characters'
                : null
            }
          />
          <Textbox
            fieldLabel="Model"
            currentValue={claimSection.model}
            onChange={values => onChange({ ...claimSection, model: values })}
            isRequired
            touched={!claimSection?.model && missingRequired}
            errorMessage={
              claimSection?.model?.length < 1 ||
              claimSection?.model?.length > 40
                ? 'Model must be between 1 and 40 characters'
                : null
            }
          />
          <Textbox
            fieldLabel="VIN"
            currentValue={claimSection.VIN}
            onChange={values => onChange({ ...claimSection, VIN: values })}
          />
        </TwoColumn>
      ) : (
        <TwoColumn>
          <div className={styles.claimLabel}>
            Are you reporting this claim as RECORD ONLY?
          </div>
          <div>
            {getDropdownAnswer(
              YES_NO_UNKNOWN,
              claimSection?.reportingClaimAsRecordOnlyIndicator
            )}
          </div>
          <div className={styles.claimLabel}>
            Is the primary vehicle damaged?
          </div>
          <div>
            {getDropdownAnswer(
              YES_NO_UNKNOWN,
              claimSection?.primaryVehicleDamaged
            )}
          </div>
          <div className={styles.claimLabel}>
            Is the primary vehicle drivable?
          </div>
          <div>
            {getDropdownAnswer(
              YES_NO_UNKNOWN,
              claimSection?.primaryVehicleDrivable
            )}
          </div>
          <div className={styles.claimLabel}>Vehicle Ownership Type</div>
          <div>
            {getDropdownAnswer(
              VEHICLE_OWNERSHIP_TYPE_CODE,
              claimSection?.vehicleOwnershipTypeCode
            )}
          </div>
          <div className={styles.claimLabel}>Vehicle Type</div>
          <div>
            {getDropdownAnswer(VEHICLE_TYPE, claimSection?.typeOfVehicle)}
          </div>
          <div className={styles.claimLabel}>Year</div>
          <div>{claimSection.year}</div>
          <div className={styles.claimLabel}>Make</div>
          <div>{claimSection.make}</div>
          <div className={styles.claimLabel}>Model</div>
          <div>{claimSection.model}</div>
          <div className={styles.claimLabel}>VIN</div>
          <div>{claimSection.VIN}</div>
        </TwoColumn>
      )}
    </>
  );
}

function DriverInfomation({
  claimSection,
  onChange,
  editing,
  missingRequired
}) {
  return (
    <>
      {editing ? (
        <TwoColumn>
          <RadioButtons
            options={[
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' }
            ]}
            currentValue={claimSection.sufferedBodilyInjuryIndicator}
            fieldLabel="Is Driver Injured?"
            onChange={value => {
              onChange({
                ...claimSection,
                sufferedBodilyInjuryIndicator: value
              });
            }}
            isRequired
            touched={
              !claimSection.sufferedBodilyInjuryIndicator && missingRequired
            }
          />
          <Dropdown
            options={DRIVER_INJURY_TYPE}
            currentValue={claimSection.extentOfInjuryCode}
            fieldLabel="Type of Driver Injury"
            onChange={value => {
              onChange({
                ...claimSection,
                extentOfInjuryCode: value
              });
            }}
          />
          <EmployeeDropdown
            fieldLabel="Driver Name"
            currentValue={claimSection?.employeeId}
            onChange={value => onChange({ ...claimSection, employeeId: value })}
            searchable
            name="employeeInformation"
            disabled
          />
        </TwoColumn>
      ) : (
        <TwoColumn className={styles.claimCard}>
          <div className={styles.claimLabel}>Driver First Name:</div>
          <div>{claimSection?.employeeFirstName}</div>
          <div className={styles.claimLabel}>Driver Last Name:</div>
          <div>{claimSection?.employeeLastName}</div>
          <div className={styles.claimLabel}>Driver Phone Number:</div>
          <div>{claimSection?.employeePhone}</div>
        </TwoColumn>
      )}
    </>
  );
}

function OverviewCard({ claimSection }) {
  const company = useSelector(getActiveCompany);
  const reportTypes = [
    ...REACTIVE_REPORT_TYPES,
    ...PROACTIVE_REPORT_TYPES.filter(r => r.value !== 8 && r.value !== 10)
  ];
  const columns = [
    {
      key: 'groupId',
      label: 'Group/Est',
      datatype: 'group'
    },
    {
      key: 'reportTemplateIds',
      label: 'Report Types',
      accessor: r => r.incidentId.reportTemplateIds.map(v => parseInt(v, 10)),
      datatype: 'svgs',
      disableSortBy: true,
      enum: company?.showAircraft
        ? reportTypes.filter(r => !r.label !== 'Spill')
        : reportTypes.filter(r => !r.isAircraft)
    },
    {
      key: 'incidentNumber',
      label: 'Incident Number',
      accessor: r => r.incidentId.incidentNumber
    },
    {
      key: 'incidentDate',
      label: 'Incident Date',
      datatype: 'date',
      accessor: r => r.incidentDate
    },
    {
      key: 'claimNumber',
      label: 'Claim Number'
    },
    { key: 'lastUpdatedAt', datatype: 'date' }
  ];
  return (
    <>
      <TwoColumn className={styles.claimCard}>
        <div className={styles.claimLabel}>Claim Number</div>
        <div>{claimSection?.claimNumber}</div>
        <div className={styles.claimLabel}>iReportSource Number</div>
        <div>{claimSection?.insuredReportNumber}</div>
        <div className={styles.claimLabel}>Claim Sent</div>
        <div>{formatDate(claimSection?.submissionDate)}</div>
        <div className={styles.claimLabel}>Employee Other Claims</div>
      </TwoColumn>
      <List
        data={claimSection.previousClaims}
        settings={columns}
        dataIsHash
        saveKey="previousClaims"
      />
    </>
  );
}
