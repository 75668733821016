import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import history from '../../history';
import config from '../../config/config';
import { AUTOSAVE_TIME } from '../../constants/constants';
import {
  createInitialReport,
  deleteInitialReport,
  fetchCustomReportQuestions,
  findIncidentQuestionsByReportType,
  printReport,
  updateAttachment,
  updateReportTypes
} from '../../api/v4';
import { isImage } from '../../utils/attachmentSelector';
import { useSocket } from '../../utils/withSocket';
import {
  isInitialReportSectionComplete,
  userHasNoConfidentialAccess
} from '../../utils/incidentHelper';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import customToast from '../../utils/customToast';
import { getSingleProjectDropdown } from '../../utils/employeeDGPHelper';
import {
  createIncidentRequest,
  fetchIncidentBasicsResponse,
  saveIncidentBasicsRequest,
  setActiveIncidentResponse,
  updateIncidentBasicsRequest,
  setActiveIncidentRequest
} from '../../actions/incidents';
import {
  addUploadedAttachmentsRequest,
  addUploadedAttachmentsResponse,
  deleteAttachmentRequest
} from '../../actions/attachments';
import { getAllUsers, getLoggedInUser } from '../../selectors/users';
import {
  getActiveIncidentSelector,
  getIncidentBasicsSelector
} from '../../selectors/incidents';
import { getAddedAttachmentsSelector } from '../../selectors/attachments';
import {
  getActiveReportsSelector,
  getActiveReportTemplatesSelector
} from '../../selectors/reports';
import Assignee from '../../components/Assignee';
import Diagram from '../../components/Diagram';
import DiagramCard from '../../components/reportCards/DiagramCard';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import IncidentSection from '../../components/reportCards/IncidentSection';
import LeftNav from '../../components/LeftNav';
import Modal from '../../components/Modal';
import PhotoEvidence from '../../components/reportCards/PhotoEvidence';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import {
  SaveCancelFooter,
  SubmitSaveForLaterCancelFooter
} from '../../components/Footer';
import SignatureCard from '../../components/SignatureCard';
import ViewOnly from '../../components/ViewOnly';

import styles from './initialReport.modules.scss';

export default function InitialReportContainer() {
  const location = useLocation();
  const dispatch = useDispatch();
  const socket = useSocket();
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const newReportTypes = location?.state?.newReportTypes;

  const [answers, setAnswers] = useState({});
  const [rowId, setRowId] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [diagramCategory, setDiagramCategory] = useState({ name: '', url: '' });
  const [didSubmit, setDidSubmit] = useState(false);
  const [signature, setSignature] = useState(undefined);
  const [isEditing, setEditing] = useState(false);
  const [isCreating, setCreating] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [updatedGroup, setUpdatedGroup] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const [savedInitialReport, setSavedReport] = useState(null);
  const [autosave, setAutosave] = useState(false);
  const [newReportBasics, setNewReportBasics] = useState([]);
  const [openModalType, setOpenModalType] = useState(null);
  const [newReportAnswers, setNewReportAnswers] = useState({});
  const [basicSections, setBasicSections] = useState([]);
  // const [newReportTemplateIds, setNewReportTemplateIds] = useState([]);
  const [markConfidential, setMarkedConfidential] = useState(false);
  const [missingRequired, setMissingRequired] = useState(false);
  const [additionalSignatures, setAdditionalSignatures] = useState([]);
  const answerRef = useRef(answers);

  const loggedInUser = useSelector(getLoggedInUser);
  const incidentBasics = useSelector(getIncidentBasicsSelector);
  const addedAttachments = useSelector(getAddedAttachmentsSelector);
  const activeIncident = useSelector(getActiveIncidentSelector);
  const activeReport = useSelector(getActiveReportsSelector);
  const reportTemplates = useSelector(getActiveReportTemplatesSelector);
  const users = useSelector(getAllUsers);
  const isSigning = history.location?.state?.isSigning !== undefined;
  const hasSignatures = activeIncident?.additionalSignatures?.some(
    s => s.signature
  );

  const createIncident = incident => {
    dispatch(createIncidentRequest(incident));
    setCreating(false);
  };
  const updateIncidentBasics = (
    id,
    answers,
    signature,
    additionalSignatures,
    isSigning,
    reportTemplateIds
  ) =>
    dispatch(
      updateIncidentBasicsRequest({
        id,
        answers,
        signature,
        additionalSignatures,
        isSigning,
        reportTemplateIds
      })
    );
  const addAttachment = attachment =>
    dispatch(addUploadedAttachmentsRequest(attachment));
  const deleteAttachment = attachment =>
    dispatch(deleteAttachmentRequest(attachment));
  const addUploadedAttachments = values =>
    dispatch(addUploadedAttachmentsResponse(values));
  const saveIncidentBasics = payload =>
    dispatch(saveIncidentBasicsRequest(payload));
  const setActiveIncident = payload =>
    dispatch(setActiveIncidentResponse(payload));
  const setActiveIncidentBasics = payload =>
    dispatch(fetchIncidentBasicsResponse(payload));

  const fetchNewReportBasics = useCallback(
    async (templateIds, newSections = []) => {
      let reportBasics = await findIncidentQuestionsByReportType(templateIds);

      let sections = [];

      let customQuestions = await fetchCustomReportQuestions(
        templateIds,
        'Report Basics'
      );

      if (customQuestions.length > 0) {
        let customQuestionFields = [];

        customQuestions.forEach(custom =>
          customQuestionFields.push(...custom.fields)
        );

        reportBasics.forEach(rb => {
          if (Array.isArray(rb)) {
            rb.forEach(r => sections.push(r));
          } else {
            sections.push(rb);
          }
        });

        sections = [
          ...sections,
          {
            label: 'Custom Questions',
            fields: customQuestionFields
          }
        ];
      } else {
        reportBasics.forEach(rb => {
          if (Array.isArray(rb)) {
            rb.forEach(r => sections.push(r));
          } else {
            sections.push(rb);
          }
        });
      }

      if (newReportTypes) {
        setNewReportBasics(sections);
      } else {
        setBasicSections(newSections.concat(sections));
      }
    },
    [newReportTypes]
  );

  const noConfidentialAccess = userHasNoConfidentialAccess(
    activeIncident,
    loggedInUser
  );

  const isCreatedBy =
    loggedInUser._id === activeIncident?.createdByUserId &&
    (loggedInUser.accessLevel === 100 || noConfidentialAccess);

  useEffect(() => {
    if (newReportTypes) {
      fetchNewReportBasics(newReportTypes);
      setEditing(true);
      setHasChanges(true);
    }
    if (activeIncident) {
      if (Object.keys(answerRef.current).length !== 0) return;
      setAnswers({
        ...activeIncident.answers,
        locationId: activeIncident.locationId ?? activeIncident.groupId
      });
      setSignature(activeIncident.signature);
      setCreating(activeIncident?.rowId?.length);
      setRowId(activeIncident?.rowId);
      setHasChanges(!activeIncident?.reportComponents?.length);
      setAdditionalSignatures(activeIncident?.additionalSignatures);
    } else {
      setCreating(true);
      if (activeProject)
        setAnswers({
          locationId: activeGroup._id,
          projectId: activeProject._id
        });
      else if (activeGroup) setAnswers({ locationId: activeGroup._id });
    }

    if (newReportTypes?.includes('17')) {
      let updated = cloneDeep(incidentBasics?.sections);
      let index = updated[0]?.fields.findIndex(
        f => f._id?.toString() === config.staticIds.confidentialReport
      );
      updated[0].fields[index].hide = false;
      setBasicSections(updated);
    } else {
      setBasicSections(incidentBasics?.sections);
    }
  }, [
    activeIncident,
    activeGroup,
    activeProject,
    newReportTypes,
    incidentBasics,
    fetchNewReportBasics
  ]);

  useEffect(() => {
    answerRef.current = answers;
  }, [answers]);

  useEffect(() => {
    return () => {
      // Anything in here is fired on component unmount.
      if (autosave) clearTimeout(autosave);
    };
  }, [autosave]);

  const requiredFieldsToSave = answers =>
    answers[config.staticIds.incidentDescription]?.length > 3 &&
    (answers?.locationId?.length || activeIncident?.locationId?.length);

  const handleAutoSave = () => {
    if (!autosave) {
      const timeout = setTimeout(() => {
        if (requiredFieldsToSave(answerRef.current)) {
          const fields = {
            groupId: answerRef.current?.locationId,
            companyId: activeCompany._id,
            answers: { ...answerRef.current },
            reportTemplateIds: reportTemplates,
            signature: signature,
            attachments: addedAttachments
          };
          if (rowId || activeIncident) {
            if (!socketConnected) {
              socket.emit('subscribe', rowId ?? activeIncident?.rowId);
              setSocketConnected(true);
            }
            socket.emit('saveInitial', {
              ...(savedInitialReport ?? activeIncident),
              ...fields
            });
            customToast('Autosaved Successfully!', 'success');
          } else {
            createInitialReport(fields).then(r => {
              setSavedReport(r);
              setRowId(r._id);
              socket.emit('subscribe', r._id);
              const allAttachments = fields.signature
                ? [fields.signature, ...fields.attachments]
                : fields.attachments;
              allAttachments.forEach(attachment => {
                updateAttachment({
                  ...attachment,
                  ownerType: 'incident',
                  ownerId: r._id
                });
              });
            });
            customToast('Initial Report Draft Autosaved!', 'success');
          }
          setAutosave(false);
          setHasChanges(false);
        }
      }, AUTOSAVE_TIME);
      setAutosave(timeout);
    }
  };

  const handleSpecialAnswers = (
    answersObject,
    answers,
    label,
    onCreation = true
  ) => {
    let updatedAnswers = { ...answers };
    let basicsToLookAt = onCreation ? [...basicSections] : [...newReportBasics];
    const templateIds = onCreation ? reportTemplates : newReportTypes;
    if (templateIds?.includes(17) || templateIds?.includes('17')) {
      let basicsIndex = basicsToLookAt.findIndex(
        b => b.templateNumber === '17'
      );
      let updatedBasics = [...basicsToLookAt];
      if (label === 'Type of Violence/Harassment') {
        let otherIndex = basicsToLookAt?.[basicsIndex]?.fields?.findIndex(
          f => f.label === 'Other'
        );
        const needToShow = Object.values(answersObject)[0]?.some(
          a => a.label === 'Other'
        );
        updatedBasics[basicsIndex].fields[otherIndex].hide = !needToShow;

        if (!needToShow) {
          delete updatedAnswers[
            updatedBasics[basicsIndex].fields[otherIndex]._id
          ];
        }
      }

      if (label === 'Were the police called?') {
        let timeIndex = basicsToLookAt?.[basicsIndex]?.fields?.findIndex(
          f => f.label === 'Time police were called?'
        );
        let arrivedIndex = basicsToLookAt?.[basicsIndex]?.fields?.findIndex(
          f => f.label === 'Time police arrived?'
        );
        const needToHide = Object.values(answersObject)[0]?.label === 'No';
        updatedBasics[basicsIndex].fields[timeIndex].hide = needToHide;
        updatedBasics[basicsIndex].fields[arrivedIndex].hide = needToHide;

        if (needToHide) {
          delete updatedAnswers[
            updatedBasics[basicsIndex].fields[timeIndex]._id
          ];
          delete updatedAnswers[
            updatedBasics[basicsIndex].fields[arrivedIndex]._id
          ];
        }
      }

      if (label === 'Were emergency personnel called?') {
        let timeIndex = basicsToLookAt?.[basicsIndex]?.fields?.findIndex(
          f => f.label === 'Time emergency personnel were called'
        );
        let arrivedIndex = basicsToLookAt?.[basicsIndex]?.fields?.findIndex(
          f => f.label === 'Time emergency personnel arrived?'
        );
        const needToHide = Object.values(answersObject)[0]?.label === 'No';
        updatedBasics[basicsIndex].fields[timeIndex].hide = needToHide;
        updatedBasics[basicsIndex].fields[arrivedIndex].hide = needToHide;

        if (needToHide) {
          delete updatedAnswers[
            updatedBasics[basicsIndex].fields[timeIndex]._id
          ];
          delete updatedAnswers[
            updatedBasics[basicsIndex].fields[timeIndex]._id
          ];
        }
      }

      /* This was going to add report types based on question answers when filling out the initital report.
       We decided users will select the report type at time of creation or when adding new report types to limit confusion
      keeping this in case we need to go back to this */

      // if (label === 'Was an employee injured?') {
      //   let index = updatedBasics?.findIndex(
      //     b => b.label === 'Employee Injury Basics'
      //   );
      //   if (Object.values(answersObject)[0]?.label === 'Yes') {
      //     fetchNewReportBasics(['2'], basicSections);
      //     setNewReportTemplateIds([...newReportTemplateIds, '2']);
      //   } else if (index > -1) {
      //     let section = updatedBasics.splice(index, 1)?.[0];
      //     setBasicSections(updatedBasics);
      //     for (let key in updatedAnswers) {
      //       if (section.fields?.some(f => f._id === key))
      //         delete updatedAnswers[key];
      //     }
      //   }
      // }

      // if (label === 'Was a non-employee injured?') {
      //   let index = updatedBasics?.findIndex(
      //     b => b.label === 'Third Party Injury Basics'
      //   );
      //   if (Object.values(answersObject)[0]?.label === 'Yes') {
      //     fetchNewReportBasics(['5'], basicSections);
      //     setNewReportTemplateIds([...newReportTemplateIds, '5']);
      //   } else if (index > -1) {
      //     let section = updatedBasics.splice(index, 1)?.[0];
      //     setBasicSections(updatedBasics);
      //     for (let key in updatedAnswers) {
      //       if (section.fields?.some(f => f._id === key))
      //         delete updatedAnswers[key];
      //     }
      //   }
      // }

      if (label === 'Is this a Confidential Report?') {
        const needToHide = Object.values(answersObject)[0]?.label === 'Yes';
        let supervisorIndex = basicSections?.[0]?.fields?.findIndex(
          f =>
            f.label ===
            'Who is the supervisor filling out the Supervisor Summary?'
        );
        updatedBasics[0].fields[supervisorIndex].hide = needToHide;
        if (isEditing) setMarkedConfidential(needToHide);

        if (needToHide) {
          // this remvoes the supervisor answer
          delete updatedAnswers[config.staticIds.incidentSupervisor];
        }
      }
    }

    if (templateIds?.includes(18) || templateIds?.includes('18')) {
      let basicsIndex = basicsToLookAt.findIndex(
        b => b.templateNumber === '18'
      );
      let updatedBasics = [...basicsToLookAt];
      if (label === 'Environment Impacted') {
        let waterIndex = basicsToLookAt?.[basicsIndex]?.fields?.findIndex(
          f =>
            f.label ===
            'Specify name of waterway and what is the length of area of waterway affected'
        );
        let landIndex = basicsToLookAt?.[basicsIndex]?.fields?.findIndex(
          f =>
            f.label ===
            'Specify ground surface area in square feet or yards and depth of soil'
        );

        const hideWater = !Object.values(answersObject)[0]?.some(
          a => a.label === 'Water'
        );
        updatedBasics[basicsIndex].fields[waterIndex].hide = hideWater;
        if (hideWater)
          delete updatedAnswers[
            updatedBasics[basicsIndex].fields[waterIndex]._id
          ];

        const hideLand = !Object.values(answersObject)[0]?.some(
          a => a.label === 'Land'
        );
        updatedBasics[basicsIndex].fields[landIndex].hide = hideLand;
        if (hideLand)
          delete updatedAnswers[
            updatedBasics[basicsIndex].fields[landIndex]._id
          ];

        //setBasicSections(updatedBasics);
      }
      /* This was going to add report types based on question answers when filling out the initital report.
       We decided users will select the report type at time of creation or when adding new report types to limit confusion
      keeping this in case we need to go back to this */

      // if (label === 'Were any injuries sustained?') {
      //   let answers = Object.values(answersObject)[0];
      //   const froiIndex = updatedBasics?.findIndex(
      //     b => b.label === 'Employee Injury Basics'
      //   );
      //   const partyIndex = updatedBasics?.findIndex(
      //     b => b.label === 'Third Party Injury Basics'
      //   );
      //   if (answers?.some(v => v.label === 'Yes - Employee')) {
      //     if (froiIndex < 0) {
      //       fetchNewReportBasics(['2'], basicSections);
      //       setNewReportTemplateIds([...newReportTemplateIds, '2']);
      //     }
      //   } else if (froiIndex > -1) {
      //     let section = updatedBasics.splice(froiIndex, 1)?.[0];
      //     setBasicSections(updatedBasics);
      //     for (let key in updatedAnswers) {
      //       if (section.fields?.some(f => f._id === key))
      //         delete updatedAnswers[key];
      //     }
      //   }

      //   if (answers?.some(v => v.label === 'Yes - Third Party')) {
      //     if (partyIndex < 0) {
      //       fetchNewReportBasics(['5'], basicSections);
      //       setNewReportTemplateIds([...newReportTemplateIds, '5']);
      //     }
      //   } else if (partyIndex > -1) {
      //     let section = updatedBasics.splice(partyIndex, 1)?.[0];
      //     setBasicSections(updatedBasics);
      //     for (let key in updatedAnswers) {
      //       if (section.fields?.some(f => f._id === key))
      //         delete updatedAnswers[key];
      //     }
      //   }
      // }

      // if (label === 'Was any property damaged?') {
      //   let index = updatedBasics?.findIndex(
      //     b => b.label === 'Property Damage Basics'
      //   );
      //   if (Object.values(answersObject)[0]?.label === 'Yes') {
      //     fetchNewReportBasics(['3'], basicSections);
      //     setNewReportTemplateIds([...newReportTemplateIds, '3']);
      //   } else if (index > -1) {
      //     let section = updatedBasics.splice(index, 1)?.[0];
      //     setBasicSections(updatedBasics);
      //     for (let key in updatedAnswers) {
      //       if (section.fields?.some(f => f._id === key))
      //         delete updatedAnswers[key];
      //     }
      //   }
      // }
    }
    return updatedAnswers;
  };
  const handleSelectedAnswer = (answersObject, label) => {
    let updatedAnswers = { ...answers, ...answersObject };
    updatedAnswers = handleSpecialAnswers(
      answersObject,
      updatedAnswers,
      label,
      !newReportTypes?.length
    );
    if (answersObject.locationId) {
      const personnelQuestions = basicSections
        ?.flatMap(s =>
          s.fields.filter(
            f => f.type === 'multiselectPersonnel' || f.type === 'supervisor'
          )
        )
        .map(f => f._id);
      const personnelAnswers = Object.keys(answers).filter(a =>
        personnelQuestions.includes(a)
      );
      if (personnelAnswers?.length) {
        let usersHaveBeenRemoved = false;
        for (const answer of personnelAnswers) {
          if (answers[answer].toString() !== 'Not Applicable') {
            let currentPersonnel = answers[answer];
            if (!Array.isArray(currentPersonnel))
              currentPersonnel = [currentPersonnel];
            const groupUsers = users.filter(u =>
              u.groupIds?.includes(answersObject.locationId)
            );
            const personnelInGroup = currentPersonnel.filter(p =>
              groupUsers?.some(u => u._id === p)
            );
            if (personnelInGroup.toString() !== answers[answer].toString())
              usersHaveBeenRemoved = true;
            updatedAnswers[answer] = personnelInGroup;
          }
          if (usersHaveBeenRemoved) setOpenModalType('usersRemoved');
        }
      }

      if (isEditing) setUpdatedGroup(true);
    }

    setAnswers(updatedAnswers);
    setHasChanges(true);
    if (!isEditing) handleAutoSave();
  };
  const handleNewReportAnswer = (answersObject, label) => {
    let updatedAnswers = { ...newReportAnswers, ...answersObject };

    updatedAnswers = handleSpecialAnswers(
      answersObject,
      updatedAnswers,
      label,
      false
    );

    setNewReportAnswers(updatedAnswers);
    setHasChanges(true);
  };

  const submitActions = () => {
    setDidSubmit(true);
    if (isEditing || isSigning) {
      updateIncidentBasics(
        activeIncident._id,
        answers,
        signature,
        additionalSignatures,
        isSigning,
        activeIncident.reportTemplateIds
      );
      setDidSubmit(false);
      setUpdatedGroup(false);
      setHasChanges(false);
      setOpenModalType(null);
      setEditing(false);
      return;
    }
    createIncident({
      answers,
      signature,
      rowId
      // needed this when we were adding new report types during creation
      // reportTemplateIds: [...reportTemplateIds, ...newReportTemplateIds]
    });
    setOpenModalType(null);
  };

  const saveBasics = () => {
    saveIncidentBasics({
      answers,
      signature,
      rowId
    });
  };

  const handlePhotoUpload = async e => {
    const files = e.target.files;
    if (!files.length) return;
    for (let i = 0; i < files.length; i++) {
      if (!isImage(files[i])) {
        alert(`File must be one of png, jpeg, or jpg type`);
        return;
      }
    }
    let data = new FormData();
    for (const file of Object.entries(files)) {
      data.append('attachments', file[1], file[1].name);
    }
    await addAttachment({
      data,
      ownerId: '1234',
      ownerType: 'incident',
      isPhotoEvidence: true
    });
  };

  const updatePhotoEvidence = photo => {
    const updated = addedAttachments.map(currentPhoto => {
      if (currentPhoto._id === photo._id) {
        return photo;
      }
      return currentPhoto;
    });
    addUploadedAttachments(updated);
  };

  const disableSubmit = () => {
    let disableSubmit = false;
    if (!answers?.locationId) return true;
    let availableProjects = getSingleProjectDropdown(
      activeCompany,
      answers?.locationId
    );
    if (!answers.projectId && !answers.project && availableProjects?.length)
      return true;

    for (const section of basicSections) {
      if (!isInitialReportSectionComplete(section, answers)) {
        disableSubmit = true;
      }
    }

    if (
      isSigning &&
      !activeIncident?.additionalSignatures?.find(
        s => s.user === loggedInUser._id
      ).signature
    )
      disableSubmit = true;

    return disableSubmit || !signature;
  };

  const disableNewReportSubmit = () => {
    let disableSubmit = false;
    if (!newReportBasics) return true;
    for (const section of newReportBasics) {
      if (!isInitialReportSectionComplete(section, newReportAnswers)) {
        disableSubmit = true;
      }
    }

    return disableSubmit;
  };

  const handleGoBack = () => {
    if (isEditing) {
      setEditing(false);
      setOpenModalType(null);
      setHasChanges(false);
      const loadIncident = payload =>
        dispatch(setActiveIncidentRequest(payload));
      loadIncident({
        _id: activeIncident._id,
        returnPath: `/app/Incidents`
      });
    }

    if (
      (activeIncident && loggedInUser.accessLevel === 100) ||
      noConfidentialAccess
    ) {
      history.push('/app/dashboard');
      return;
    }
    history.goBack();
  };

  const handleDeleteBasics = () => {
    deleteInitialReport(rowId).then(() => {
      history.push('/app/dashboard');
    });
  };

  const reopenReport = () => {
    setOpenModalType(null);
    setEditing(true);
  };

  const handlePrintInitialReport = () => {
    printReport(activeIncident?._id, {
      initialReport: true,
      photoEvidence: true,
      diagrams: true
    }).then(urls => {
      urls.map(report => window.open(report, '_blank'));
    });
  };

  const addNewReports = () => {
    let updated = {
      answers: { ...answers, ...newReportAnswers },
      newReportTypes: [...activeIncident?.reportTemplateIds, ...newReportTypes]
    };

    updateReportTypes(activeIncident._id, updated).then(r => {
      setActiveIncident(r);
      customToast('Incident Updated Successfully!', 'success');

      // come back and figure out a better way for this
      const noConfidentialAccess = userHasNoConfidentialAccess(r, loggedInUser);
      history.replace({
        state: { ...location?.state, newReportTypes: undefined }
      });
      if (loggedInUser.accessLevel === 100 || noConfidentialAccess) {
        setEditing(false);
        setHasChanges(false);
        setOpenModalType();
      } else {
        setActiveIncidentBasics({
          ...incidentBasics,
          sections: basicSections.concat(newReportBasics)
        });
        history.goBack();
      }
    });
  };

  const renderAdditionalSignatures = () => {
    const hideSignatureField = field => {
      if (
        !field.signature &&
        // only show other completed signatures when signing
        ((field.user !== loggedInUser._id && isSigning) ||
          // only allow one user to sign and only show one card if only one signature is required
          (hasSignatures && !activeCompany.allAdditionalSignaturesMustSign))
      )
        return true;
    };

    return activeIncident?.additionalSignatures?.map((s, i) => {
      if (hideSignatureField(s)) return null;
      return (
        <>
          <SignatureCard
            wide
            header="Additional Signature"
            dropdownName="Employee"
            userId={s.user}
            userDisabled
            disabled={s.user !== loggedInUser._id || !isSigning}
            currentValue={additionalSignatures?.[i]?.signature}
            onChange={v => {
              let signature = [...activeIncident?.additionalSignatures];
              signature[i].signature = v;
              setAdditionalSignatures(signature);
            }}
          />
        </>
      );
    });
  };

  let leftNav = basicSections?.length
    ? basicSections
        .filter(x => x)
        .map((section, i) => ({
          label: section.label,
          complete: isInitialReportSectionComplete(section, answers),
          id: i
        }))
    : [];

  if (newReportBasics) {
    leftNav = leftNav.concat(
      newReportBasics
        ?.filter(x => x)
        ?.map((section, i) => ({
          label: section.label,
          complete: isInitialReportSectionComplete(section, newReportAnswers),
          id: section.label
        }))
    );
  }

  if (!newReportTypes && leftNav?.length) {
    leftNav.push({ label: 'Diagrams', id: 'diagrams' });
    leftNav.push({ label: 'Signature', id: 'signature' });
  }

  const photoEvidence = addedAttachments =>
    addedAttachments.filter(
      attachment => attachment.isPhotoEvidence && !attachment?.isRemoved
    );
  const footer =
    isEditing || isSigning ? (
      <SaveCancelFooter
        saveButtonDisabled={
          didSubmit ||
          (newReportTypes ? disableNewReportSubmit() : disableSubmit())
        }
        saveButtonClick={() =>
          markConfidential
            ? setOpenModalType('markedConfidential')
            : newReportTypes
              ? addNewReports()
              : updatedGroup
                ? setOpenModalType('updatedGroup')
                : submitActions()
        }
        cancelButtonClick={() =>
          newReportTypes
            ? setOpenModalType('updatedReportTypes')
            : hasChanges
              ? setOpenModalType('unsavedChanges')
              : handleGoBack()
        }
        editing={isEditing || isSigning}
      />
    ) : (
      <SubmitSaveForLaterCancelFooter
        saveButtonClick={() =>
          !requiredFieldsToSave(answers)
            ? setOpenModalType('saveMissingFields')
            : setOpenModalType('save')
        }
        saveButtonDisabled={didSubmit}
        saveButtonText="Save as Draft"
        cancelButtonClick={() =>
          hasChanges ? setOpenModalType('unsavedChanges') : handleGoBack()
        }
        cancelButtonDisabled={didSubmit}
        showDeleteInsteadOfCancel={rowId}
        deleteButtonClick={() => setOpenModalType('delete')}
        submitButtonClick={() =>
          !requiredFieldsToSave(answers) || disableSubmit()
            ? setOpenModalType('submitMissingFields')
            : setOpenModalType('submit')
        }
        submitButtonDisabled={didSubmit}
      />
    );

  const reporter = (
    <Assignee
      text="Reported By"
      user={activeIncident?.createdByUserId ?? loggedInUser._id}
      options={
        isCreatedBy
          ? [
              {
                label: 'Print Initial Report',
                visible: true,
                onClick: () => handlePrintInitialReport()
              }
            ]
          : null
      }
    />
  );

  const left =
    isCreating || isEditing ? (
      <LeftNav
        items={leftNav}
        onAddEvidence={e => {
          handlePhotoUpload(e);
          e.target.value = '';
        }}
        showAddEvidence={!isEditing}
      />
    ) : null;

  const headerCenter = !activeReport?.disabled &&
    activeIncident &&
    !isCreating &&
    !isEditing && (
      <ViewOnly
        canUnlock={
          !isEditing &&
          loggedInUser?.accessLevel === 900 &&
          !activeIncident.isArchived
        }
        incident={activeIncident}
        loggedInUser={loggedInUser}
        onClick={() => setOpenModalType('unlockAndReopenReport')}
        activeReport={activeReport}
      />
    );

  const header = (
    <Header
      title="Initial Report"
      needsSaved={hasChanges}
      clickBack={() =>
        newReportTypes
          ? setOpenModalType('updatedReportTypes')
          : hasChanges
            ? setOpenModalType('unsavedChanges')
            : handleGoBack()
      }
      center={headerCenter}
      right={reporter}
    />
  );

  return !imageUrl ? (
    <>
      <HeaderAndFooter
        Header={header}
        Footer={footer}
        Left={left}
        showFooter={isCreating || isEditing || isSigning}
      >
        {basicSections?.map((basic, index) => (
          <IncidentSection
            name={index}
            reportSectionHeader={basic.label}
            fields={basic.fields}
            key={index}
            handleSelectedAnswer={handleSelectedAnswer}
            answers={answers}
            isIncidentBasics={index === 0}
            activeIncident={activeIncident}
            isCreating={isCreating}
            isEditing={isEditing}
            isAdmin={loggedInUser.accessLevel >= 900}
            disableSection={newReportTypes}
            alreadyHaveConfidential={activeIncident?.reportTemplateIds?.includes(
              '17'
            )}
            missingRequired={missingRequired}
          />
        ))}
        {newReportBasics?.map((basic, index) => (
          <IncidentSection
            name={basic?.label}
            reportSectionHeader={basic.label}
            fields={basic.fields}
            key={index}
            handleSelectedAnswer={handleNewReportAnswer}
            answers={newReportAnswers}
            activeIncident={activeIncident}
            isCreating={isCreating}
            isEditing={isEditing}
            isAdmin={loggedInUser.accessLevel >= 900}
          />
        ))}
        {newReportTypes ? null : (
          <>
            {photoEvidence(addedAttachments).map((photo, index) => (
              <PhotoEvidence
                photo={photo}
                key={incidentBasics.sections.length + index}
                name={incidentBasics.sections.length + index}
                updatePhotoEvidence={values =>
                  updatePhotoEvidence({ ...photo, ...values })
                }
                canRemoveEdit={isCreating}
              />
            ))}
            <DiagramCard
              reportSectionHeader="Diagrams"
              deleteAttachment={deleteAttachment}
              handleDiagramSelection={values => {
                setImageUrl(values.imageUrl);
                setDiagramCategory(values.diagramCategory);
              }}
              name="diagrams"
              canRemoveEdit={isCreating}
              hideNA={true}
              showAircraft={activeCompany.showAircraft}
              addedAttachments={addedAttachments}
            />
            <SignatureCard
              wide
              header="Signature"
              name="signature"
              onChange={signature => {
                setSignature(signature);
                setHasChanges(true);
                handleAutoSave();
              }}
              disabled={(!isCreating && !isEditing) || signature}
              currentValue={signature}
              missingRequired={missingRequired}
            />
          </>
        )}
        {renderAdditionalSignatures()}
      </HeaderAndFooter>
      <Modal
        title="Unlock and Re-Open Report"
        titleClassName="blueHeader"
        isOpen={openModalType === 'unlockAndReopenReport'}
        submitButtonColor="blue"
        submitButtonText="Re-open Report"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={reopenReport}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          Unlocking this report will allow you –and users with edit permission–
          to make changes and additions to the report.{' '}
          <span style={{ color: '#c74846', fontWeight: 'bold' }}>
            This also means that the report will no longer be considered
            completed until it is filled out, saved, and marked as complete once
            more. Re-Opening this report will also remove the currently assigned
            user.
          </span>{' '}
          You can reassign this report to the same or a different user as a new
          task.
        </div>
      </Modal>
      <Modal
        title="Save Draft"
        titleClassName="blueHeader"
        isOpen={openModalType === 'save'}
        submitButtonColor="blue"
        submitButtonText="Save"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={saveBasics}
      >
        <div className={styles.modalText}>
          <span style={{ fontWeight: 'bold' }}>
            This report is incomplete, and will be saved as a Draft.
          </span>{' '}
          Go to <span style={{ fontWeight: 'bold' }}>My Saved Drafts</span> on
          your Dashboard to complete and submit the report.
        </div>
      </Modal>
      <Modal
        title="Missing Fields to Save Draft"
        titleClassName="redHeader"
        isOpen={openModalType === 'saveMissingFields'}
        submitButtonColor="red"
        submitButtonText="Go Back"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={() => setOpenModalType(null)}
        hideButtons
      >
        <div className={styles.modalText}>
          You must fill out{' '}
          <span style={{ fontWeight: 'bold' }}>Group/Establishment</span> and{' '}
          <span style={{ fontWeight: 'bold' }}>
            Describe in Detail what happened or what you observed
          </span>{' '}
          in order to save as a draft.
        </div>
      </Modal>
      <Modal
        title="Submit Report"
        titleClassName="blueHeader"
        isOpen={openModalType === 'submit'}
        submitButtonColor="blue"
        submitButtonText="Submit"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={submitActions}
        testID="createReport"
        disableSubmit={didSubmit}
        disableCancel={didSubmit}
      >
        <div className={styles.modalText}>
          This report will now be submitted, and your supervisor notified.
        </div>
      </Modal>
      <Modal
        title="Missing fields to Submit Report"
        titleClassName="redHeader"
        isOpen={openModalType === 'submitMissingFields'}
        submitButtonColor="red"
        submitButtonText="Go Back"
        onRequestClose={() => {
          setOpenModalType(null);
          setMissingRequired(true);
        }}
        submitActions={() => setOpenModalType(null)}
        testID="createReport"
        hideButtons
      >
        <div className={styles.modalText}>
          You must answer all required questions{' '}
          <span style={{ color: 'red' }}>(*)</span> to submit this report.
        </div>
      </Modal>
      <Modal
        title="Change Group/Establishment"
        titleClassName="blueHeader"
        isOpen={openModalType === 'updatedGroup'}
        submitButtonColor="blue"
        submitButtonText="Submit"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={submitActions}
      >
        <div className="reportSectionContainer-emailAssigneeText">
          Changing the Group/Establishment of this incident will update the
          following:
          <p style={{ fontWeight: 'bold' }}>
            Change the incident owner to the default incident owner for the
            Group/Establishment you have selected.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            Remove all current user permissions to see this incident and add
            default permissions for the Group/Establishment you have selected.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            Remove all current Custom Tasks and Report Assignments.
          </p>
          Are you sure you want to continue?
        </div>
      </Modal>
      <Modal
        title="Delete Saved Drafts"
        titleClassName="redHeader"
        isOpen={openModalType === 'delete'}
        submitButtonColor="red"
        submitButtonText="Delete"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={handleDeleteBasics}
      >
        <div className={styles.modalText}>
          <span style={{ fontWeight: 'bold' }}>
            Are you sure you want to delete your Saved Draft?
          </span>{' '}
          You will not be able to come back to this draft and will have to start
          again.
        </div>
      </Modal>
      <SaveChangesModal
        isOpen={openModalType === 'unsavedChanges'}
        onRequestClose={() => setOpenModalType(null)}
        submitActions={() => handleGoBack()}
        savingWhat="an incident"
      />
      <Modal
        title="Cancel Report Changes"
        titleClassName="redHeader"
        isOpen={openModalType === 'updatedReportTypes'}
        submitButtonColor="red"
        submitButtonText="Discard"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={() =>
          history.push(`/app/incidentContainer/${activeIncident._id}`)
        }
      >
        <div className={styles.modalText}>
          Are you sure you want to cancel? If canceled the new report(s) will
          not be added to {activeIncident?.incidentNumber}
        </div>
      </Modal>
      <Modal
        title="Mark as Confidential"
        titleClassName="blueHeader"
        isOpen={openModalType === 'markedConfidential'}
        submitButtonColor="blue"
        submitButtonText="Submit"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={() =>
          newReportTypes ? addNewReports() : submitActions()
        }
      >
        <div className="reportSectionContainer-emailAssigneeText">
          Marking this incident as confidential will update the following:
          <p style={{ fontWeight: 'bold' }}>
            Change the incident owner to the account owner if current incident
            owner cannot view confidential reports
          </p>
          <p style={{ fontWeight: 'bold' }}>
            Remove all current user permissions for this incident and give
            permissions to any admin with confidential access
          </p>
          <p style={{ fontWeight: 'bold' }}>
            Remove all current Custom Tasks and Report Assignments.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            Remove the supervisor filling out the supervisor summary
          </p>
          Are you sure you want to continue?
        </div>
      </Modal>
      <Modal
        title="Selected Users Changed"
        titleClassName="redHeader"
        isOpen={openModalType === 'usersRemoved'}
        hideCancelButton
        submitButtonColor="white"
        submitButtonText="Close"
        onRequestClose={() => setOpenModalType(null)}
        submitActions={() => setOpenModalType(null)}
      >
        One or more employees were removed from a completed field because they
        do not have access to the selected Group/Establishment.
      </Modal>
    </>
  ) : (
    <div className="initialReportContainer">
      <div>
        {diagramCategory.name !== '' && (
          <Diagram
            imageUrl={imageUrl}
            addAttachment={addAttachment}
            resetDiagrams={() => {
              setImageUrl('');
              setDiagramCategory({ name: '', url: '' });
            }}
            category={diagramCategory.name}
            loggedInUser={loggedInUser}
          />
        )}
      </div>
    </div>
  );
}
