import { all, call, put, select } from 'redux-saga/effects';
import history from '../history';
import {
  addCompanyLocationResponse,
  addCompanyResponse,
  deleteCompanyLocationResponse,
  deleteCompanyResponse,
  fetchUserCompaniesResponse,
  setActiveCompany,
  setActiveLocation,
  updateCompanyLocationResponse,
  updateCompanyResponse,
  setActiveProject
} from '../actions/company';
import { getAddedAttachmentsSelector } from '../selectors/attachments';
import {
  getActiveCompany,
  getUserCompaniesSelector,
  getActiveProject,
  getActiveLocationId
} from '../selectors/company';
import customToast from '../utils/customToast';

export function* setIncidentOwner(apiV4, { payload }) {
  try {
    const { locationId, employeeId } = payload;
    const userCompanies = yield select(getUserCompaniesSelector);
    yield call(apiV4.setIncidentOwner, locationId, employeeId);

    userCompanies.forEach(company => {
      if (company._id === locationId)
        company.defaultIncidentOwner = { userId: employeeId };

      company.groups.forEach(group => {
        if (group._id === locationId)
          group.defaultIncidentOwner = { userId: employeeId };
      });
    });

    yield put(fetchUserCompaniesResponse([...userCompanies]));
  } catch (e) {
    customToast(e, 'error');
  }
}

export function* addCompany(apiV4, { payload }) {
  try {
    const companyLogo = yield select(getAddedAttachmentsSelector);

    let newLogo = [];

    if (companyLogo?.length > 0) {
      const temp = yield all(
        companyLogo.map(attachment =>
          call(apiV4.updateAttachmentLogo, {
            ...attachment,
            ownerId: payload._id
          })
        )
      );

      newLogo = temp;
    }

    payload = {
      ...payload,
      logoUrl: newLogo?.length > 0 ? newLogo[0].source_url : ''
    };

    const response = yield call(apiV4.addCompany, payload);

    yield put(addCompanyResponse(response));
    yield put(setActiveCompany(response));
    yield put(setActiveLocation(0));
    customToast('Saved Successfully!', 'success');
  } catch (e) {
    customToast(e?.data, 'error');
  }
}

export function* updateCompany(apiV4, { payload }) {
  try {
    const companyLogo = yield select(getAddedAttachmentsSelector);

    let newLogo = [];
    const updatedHours =
      payload.groupHours && payload.areaHours
        ? 'Both'
        : payload.groupHours
          ? 'Group'
          : payload.areaHours
            ? 'Area'
            : null;

    if (updatedHours) {
      const hours = [
        ...(payload.groupHours?.hours ?? []),
        ...(payload.areaHours?.hours ?? [])
      ];
      const year = payload.groupHours?.year ?? payload.areaHours?.year;
      yield call(apiV4.setHoursWorked, year, hours, updatedHours);
    }

    if (companyLogo?.length > 0) {
      // flatten the list in case of nested attachments
      //(side affect of uploaded multiple attachments at once)
      const flattenedAttachmentList = companyLogo.reduce(
        (acc, x) => acc.concat(x),
        []
      );

      const temp = yield all(
        flattenedAttachmentList.map(attachment =>
          call(apiV4.updateAttachment, {
            ...attachment,
            ownerId: payload._id
          })
        )
      );

      newLogo = temp;
    }

    payload = {
      ...payload,
      logoUrl: newLogo?.length > 0 ? newLogo[0].source_url : payload.logoUrl
    };

    let response = yield call(apiV4.updateCompany, payload);

    yield put(
      updateCompanyResponse({ ...response, locations: response.groups })
    );
    yield put(setActiveCompany({ ...response, locations: response.groups }));
    customToast('Saved Successfully!', 'success');
  } catch (e) {
    customToast(e.data, 'error');
  }
}

export function* deleteCompany(apiV4, { payload }) {
  try {
    yield call(apiV4.deleteCompany, payload);

    yield put(deleteCompanyResponse(payload));
    history.push('/home');

    customToast('Deleted Successfully!', 'success');
  } catch (e) {
    customToast('e', 'error');
  }
}

export function* addCompanyLocation(apiV4, { payload }) {
  const activeCompany = yield select(getActiveCompany);

  try {
    payload = { ...payload, companyId: activeCompany._id };

    const response = yield call(apiV4.createGroup, payload);
    const updatedCompany = {
      ...activeCompany,
      locations: [...activeCompany.groups, response],
      groups: [...activeCompany.groups, response]
    };

    yield put(updateCompanyResponse(updatedCompany));
    yield put(setActiveCompany(updatedCompany));

    yield put(setActiveLocation(response));
    yield put(addCompanyLocationResponse(activeCompany.groups));

    customToast('Saved Successfully!', 'success');
    customToast(
      'In order to get notifications, users must opt-in to the new group in notification settings',
      'success'
    );
  } catch (e) {
    yield put(setActiveCompany(activeCompany));
    customToast(e.data, 'error');
  }
}

export function* updateCompanyLocation(apiV4, { payload }) {
  let activeCompany = yield select(getActiveCompany);
  const activeGroup = yield select(getActiveLocationId);
  const setActiveGroup = payload.setActiveGroup ?? true;

  try {
    activeCompany = { ...activeCompany };

    const updatedLocation = activeCompany.groups.findIndex(
      location => location._id === payload._id
    );

    payload = { ...payload, companyId: activeCompany._id };

    const response = yield call(apiV4.updateGroup, payload);
    activeCompany.locations[updatedLocation] = {
      ...response,
      projects: activeCompany.locations[updatedLocation].projects
    };
    activeCompany.groups[updatedLocation] = {
      ...response,
      projects: activeCompany.locations[updatedLocation].projects
    };

    yield put(updateCompanyResponse(activeCompany));
    yield put(setActiveCompany(activeCompany));
    if (activeGroup?._id === response._id || setActiveGroup) {
      yield put(
        setActiveLocation({
          ...response,
          projects: activeCompany.locations[updatedLocation].projects
        })
      );
    }
    yield put(
      updateCompanyLocationResponse({
        ...response,
        projects: activeCompany.locations[updatedLocation].projects
      })
    );
    customToast('Saved Successfully!', 'success');
  } catch (e) {
    yield put(setActiveLocation(0));
    yield put(setActiveCompany(activeCompany));
    customToast(e.data, 'error');
  }
}

export function* deleteCompanyLocation(apiV4, { payload }) {
  try {
    const activeCompany = yield select(getActiveCompany);
    const activeGroup = yield select(getActiveLocationId);

    yield call(apiV4.deleteGroup, payload.id);

    const deletedLocation = activeCompany?.groups?.findIndex(
      location => location._id === payload.id
    );

    let updated = {
      ...activeCompany,
      groups: [
        ...activeCompany.locations.slice(0, deletedLocation),
        ...activeCompany.locations.slice(deletedLocation + 1)
      ],
      locations: [
        ...activeCompany.locations.slice(0, deletedLocation),
        ...activeCompany.locations.slice(deletedLocation + 1)
      ],
      allLocations: [
        ...activeCompany.locations.slice(0, deletedLocation),
        ...activeCompany.locations.slice(deletedLocation + 1)
      ]
    };

    yield put(updateCompanyResponse(updated));
    yield put(setActiveCompany(updated));
    yield put(deleteCompanyLocationResponse(payload));
    customToast('Deleted Successfully!', 'success');
    if (activeGroup?._id === payload.id) {
      yield put(setActiveLocation(0));
      yield put(setActiveProject());
    }
  } catch (e) {
    customToast(e, 'error');
  }
}

export function* addGroupProject(apiV4, { payload }) {
  const userCompanies = yield select(getUserCompaniesSelector);

  try {
    const response = yield call(apiV4.addGroupProject, payload);
    const company = userCompanies.find(c => c._id === payload.companyId);
    const group = company.groups.find(g => g._id === payload.groupId);

    if (group.projects?.length > 0) {
      group.projects = [...group.projects, response];
    } else {
      group.projects = [response];
    }
    yield put(updateCompanyResponse({ ...company }));
    yield put(setActiveCompany({ ...company }));
    customToast('Saved Successfully!', 'success');
  } catch (e) {
    customToast(e.data, 'error');
  }
}

export function* updateGroupProject(apiV4, { payload }) {
  const userCompanies = yield select(getUserCompaniesSelector);
  const activeProject = yield select(getActiveProject);

  try {
    const response = yield call(apiV4.updateGroupProject, payload);

    if (activeProject) {
      yield put(setActiveProject({ ...response }));
    }

    const company = userCompanies.find(c => c._id === payload.companyId);
    const group = company.groups.find(g => g._id === payload.groupId);
    const projectIdx = group.projects.findIndex(p => p._id === response._id);

    let projectList = group.projects;
    projectList[projectIdx] = response;
    group.projects = projectList;

    yield put(setActiveCompany({ ...company }));
    customToast('Saved Successfully!', 'success');
  } catch (e) {
    customToast(e.data, 'error');
  }
}

export function* deleteGroupProject(apiV4, { payload }) {
  try {
    const userCompanies = yield select(getUserCompaniesSelector);
    const activeProject = yield select(getActiveProject);

    yield call(apiV4.deleteGroupProject, payload._id);

    const company = userCompanies.find(c => c._id === payload.companyId);
    const group = company.groups.find(g => g._id === payload.groupId);

    group.projects = group.projects.filter(p => p._id !== payload._id);

    yield put(updateCompanyResponse({ ...company }));
    yield put(setActiveCompany({ ...company }));
    if (activeProject?._id === payload?._id) yield put(setActiveProject());
    customToast('Deleted Successfully!', 'success');
  } catch (e) {
    customToast(e, 'error');
  }
}
