import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../history';
import {
  fetchClaims,
  getLocationCodes,
  importLocationCodes
} from '../../api/v4';
import { clearUploadedAttachments } from '../../actions/attachments';
import { setActiveIncidentRequest } from '../../actions/incidents';
import {
  getAttachmentLoadingSelector,
  getAddedAttachmentsSelector
} from '../../selectors/attachments';
import { getAccessLevel } from '../../selectors/users';
import useActiveHeirarchy from '../../utils/useActiveHeirarchy';
import customToast from '../../utils/customToast';
import CSVUploader from '../../components/CSVUploader';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import List from '../../components/List';
import Modal from '../../components/Modal';
import TypeSelection from '../../components/TypeSelection';

export default function Claims() {
  const dispatch = useDispatch();
  const loadIncident = incidentId =>
    dispatch(
      setActiveIncidentRequest({
        leavePage: false,
        _id: incidentId
      })
    );
  const clearAttachments = () => dispatch(clearUploadedAttachments());
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const accessLevel = useSelector(getAccessLevel);
  const attachmentLoading = useSelector(getAttachmentLoadingSelector);
  const attachments = useSelector(getAddedAttachmentsSelector);
  const [claims, setClaims] = useState();
  const [locationCodes, setLocationCodes] = useState();
  const [listType, setListType] = useState('Claims');
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [messages, setMessages] = useState();
  useEffect(() => {
    fetchClaims().then(setClaims);
    getLocationCodes().then(setLocationCodes);
  }, []);

  const header = (
    <Header
      title="Claims"
      pageActionOptions={[
        {
          color: 'blueOutline',
          label: 'Permissions',
          onClick: () => history.push('/app/permissions/Claims'),
          visible: accessLevel === 900
        },
        {
          color: 'blueOutline',
          label: 'Upload Location Codes',
          onClick: () => setUploadModalOpen(true),
          visible: true
        }
      ]}
    />
  );

  const columns = [
    { key: 'createdBy', datatype: 'users' },
    { key: 'employeeId', datatype: 'users', label: 'Employee' },
    {
      key: 'type',
      accessor: r => {
        return r.type === 'au' ? 'Auto' : 'WC';
      },
      enum: ['WC', 'Auto']
    },
    {
      key: 'status',
      accessor: r => {
        if (r.isSubmitted) {
          return 'Claim Submitted';
        } else {
          return 'In Progress';
        }
      },
      enum: ['In Progress', 'Claim Submitted']
    },
    { key: 'incidentDate', datatype: 'date' },
    { key: 'claimNumber' },
    { key: 'insuredReportNumber', label: 'iReportSource Number' },
    { key: 'lastUpdatedAt', datatype: 'date' }
  ];

  if (!activeProject) {
    columns.unshift({
      key: 'projectId',
      label: 'Area',
      datatype: 'project'
    });
  }

  if (!activeGroup) {
    columns.unshift({
      key: 'groupId',
      label: 'Group/Est.',
      datatype: 'group'
    });
  }

  const locationCodeColumns = [
    { key: 'organizationCode' },
    { key: 'organizationText' },
    { key: 'streetAddress' },
    { key: 'city' },
    { key: 'state' },
    { key: 'zipcode' }
  ];

  const uploadLocationCodes = () => {
    try {
      if (!attachments[0]) {
        customToast('File Required!', 'error');
        return;
      }

      importLocationCodes(attachments[0]?.source_url).then(
        data => {
          customToast('Imported Successfully!', 'success');
          getLocationCodes().then(setLocationCodes);
        },
        error => {
          setMessages(error.data);
          setErrorModalOpen(true);
        }
      );
      setUploadModalOpen(false);
      clearAttachments();
    } catch (error) {
      if (error.status !== 400) throw error;

      let messages = error.data;
      if (!Array.isArray(messages)) messages = [messages];
      setMessages(messages);
    }
  };

  return (
    <HeaderAndFooter Header={header}>
      <TypeSelection
        selected={listType}
        selectedArray={['Claims', 'Location Codes']}
        onClick={setListType}
      />
      {listType === 'Claims' ? (
        <List
          saveKey="claims"
          data={claims}
          dataIsHash
          settings={columns}
          rowClick={row => {
            loadIncident(row.incidentId);
            history.push(
              row.type === 'au'
                ? `/app/claims/auto/${activeCompany?.provider === 'libertyMutual' ? 'lib' : 'travelers'}/${row._id}`
                : row.type === 'gl'
                  ? `/app/claims/damage/${activeCompany?.provider === 'libertyMutual' ? 'lib' : 'travelers'}/${row._id}`
                  : `/app/claims/wc/${activeCompany?.provider === 'libertyMutual' ? 'lib' : 'travelers'}/${row._id}`
            );
          }}
        />
      ) : (
        <List
          saveKey="locationCodes"
          data={locationCodes}
          dataIsHash
          settings={locationCodeColumns}
          rowClick={row => {
            console.log(row);
          }}
        />
      )}
      <Modal
        title="Upload Travelers Location Codes"
        titleClassName="blueHeader"
        isOpen={uploadModalOpen}
        className="modalSmall"
        onRequestClose={() => setUploadModalOpen(false)}
        submitButtonColor="blue"
        submitActions={uploadLocationCodes}
        disableSubmit={attachmentLoading}
      >
        <CSVUploader documentType="EmployeesUpload" className="dropzone" />
      </Modal>
      <Modal
        title="Issues with Location Code Upload"
        titleClassName="redHeader"
        isOpen={errorModalOpen}
        className="modalMedium"
        onRequestClose={() => setErrorModalOpen(false)}
        hideCancelButton
        showXButton
        submitActions={() => setErrorModalOpen(false)}
        submitButtonColor="red"
        submitButtonText="Close"
      >
        <ul>
          {messages?.map((issue, index) => (
            <li key={index}>{issue}</li>
          ))}
        </ul>
      </Modal>
    </HeaderAndFooter>
  );
}
