import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  createClaim,
  updateClaim,
  deleteClaim,
  fetchClaimById,
  fetchEmployee,
  printClaim
} from '../../../api/v4';
import config from '../../../config/config';
import history from '../../../history';
import { setActiveIncidentRequest } from '../../../actions/incidents';
import { getActiveIncidentSelector } from '../../../selectors/incidents';
import { getActiveOshaLogSelector } from '../../../selectors/oshaLogs';
import { getMyUserId } from '../../../selectors/users';
import { useActiveHeirarchy } from '../../../utils/useActiveHeirarchy';
import customToast from '../../../utils/customToast';
import ClaimCard from '../../../components/claims/Cards/travelers';
import DeleteItemModal from '../../../components/Modal/deleteItemModal';
import HeaderAndFooter from '../../../components/HeaderAndFooter';
import Header from '../../../components/Header';
import InstructionCard from '../../../components/claims/InstructionCard';
import MissingRequiredModal from '../../../components/Modal/missingRequiredModal';
import Modal from '../../../components/Modal';
import LeftNav from '../../../components/LeftNav';
import { SubmitSaveForLaterCancelFooter } from '../../../components/Footer';
import SaveChangesModal from '../../../components/Modal/saveChangesModal';

import styles from '../claimCreate.module.scss';

export default function TravelersAutoClaim() {
  const activeIncident = useSelector(getActiveIncidentSelector);
  const { activeCompany, activeGroup, activeProject } = useActiveHeirarchy();
  const activeOshaLog = useSelector(getActiveOshaLogSelector);
  const myUserId = useSelector(getMyUserId);
  const employeeId =
    activeIncident?.answers?.[config.staticIds.autoDriverQuestion];
  let { claimId } = useParams();
  const dispatch = useDispatch();

  const [id, setId] = useState(null);
  const [missingRequired, setMissingRequired] = useState(false);
  const [missingModalOpen, setMissingModalOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [accidentInfo, setAccidentInfo] = useState({});
  const [driverInfo, setDriverInfo] = useState([]);
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [overviewInfo, setOverviewInfo] = useState({});
  const [creating, setCreating] = useState(true);
  const [accidentEditing, setAccidentEditing] = useState(false);
  const [driverEditing, setDriverEditing] = useState(false);
  const [vehicleEditing, setVehicleEditing] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveChangesModal, setSaveChangesModal] = useState(false);
  const [sumbitModal, setSubmitModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [incidentId, setIncidentId] = useState();

  useEffect(() => {
    if (!claimId) {
      setCreating(true);
      if (employeeId)
        fetchEmployee(employeeId?.[0]?.value ?? employeeId?.[0]).then(
          employee => {
            setDriverInfo({
              employeeId: employeeId,
              employeeFirstName: employee?.firstName,
              employeeLastName: employee?.lastName
            });
          }
        );
      if (activeProject) {
        setAccidentInfo({
          projectId: activeProject._id,
          groupId: activeGroup._id
        });
        setPermissions(activeCompany.claimPermissions);
      } else if (activeGroup) {
        setAccidentInfo({ groupId: activeGroup._id });
        setPermissions(activeCompany.claimPermissions);
      } else if (activeCompany) {
        setPermissions(activeCompany.claimPermissions);
      }

      const answers = activeIncident?.answers;
      if (answers) {
        let incidentLocation =
          answers[config.staticIds.incidentLocation] &&
          !answers?.[config.staticIds.incidentLocation].includes(
            'Not Applicable'
          )
            ? answers?.[config.staticIds.incidentLocation]?.split(', ')
            : '';

        if (!incidentLocation) {
          const location = activeCompany.groups.find(
            g => g._id === activeIncident.groupId
          );
          incidentLocation = [
            location.streetAddress,
            location.city,
            location.state,
            location.zip
          ];
        }

        setAccidentInfo({
          incidentDate: answers[config.staticIds.timeIncidentOccured],
          streetAddress: incidentLocation?.[0],
          city: incidentLocation?.[1],
          state: incidentLocation?.[2],
          zip: incidentLocation?.[3] === 'USA' ? null : incidentLocation?.[3],
          groupId: activeIncident.groupId,
          projectId: activeIncident.projectId,
          claimNotificationOther: myUserId,
          incidentDescription: answers[config.staticIds.incidentDescription]
        });
      }
    } else {
      fetchClaimById(claimId).then(setClaimInfo);
    }
  }, [
    activeIncident,
    activeOshaLog,
    claimId,
    activeCompany,
    activeGroup,
    activeProject,
    employeeId,
    myUserId
  ]);

  const setClaimInfo = claim => {
    setId(claim._id);
    setIsSubmitted(claim.isSubmitted);
    setIncidentId(claim.incidentId);
    setOverviewInfo({
      claimNumber: claim.claimNumber,
      previousClaims: claim.previousClaims,
      submissionDate: claim.submissionDate,
      insuredReportNumber: claim.insuredReportNumber
    });
    setAccidentInfo({
      groupId: claim.groupId,
      projectId: claim.projectId,
      incidentDate: claim.incidentDate,
      streetAddress: claim.incidentLocation?.incidentStreetAddress,
      city: claim.incidentLocation?.incidentCity,
      state: claim.incidentLocation?.incidentState,
      zip: claim.incidentLocation?.incidentZip,
      isCompanyAddress: claim.isCompanyAddress
    });
  };

  const handleSubmit = isSubmitted => {
    const object = {
      type: 'auto',
      ...accidentInfo,
      ...driverInfo,
      ...vehicleInfo,
      incidentId: activeIncident._id,
      oshaFormId: activeOshaLog?.[0]?._id,
      permissions: activeCompany.claimPermissions,
      isSubmitted
    };

    if (!id) {
      createClaim(object).then(claim => {
        setClaimInfo(claim);
        setId(claim._id);
        setSubmitModal(false);
        setUnsavedChanges(false);
        setCreating(false);
        if (claim.isSubmitted) {
          customToast('Submitted Successfully!', 'success');
        } else {
          customToast('Saved Successfully!', 'success');
        }
      });
    } else {
      updateClaim({
        _id: id,
        ...object
      }).then(claim => {
        setEditingFalse();
        setClaimInfo(claim);
        setSubmitModal(false);
        if (claim.isSubmitted) {
          customToast('Submitted Successfully!', 'success');
        } else {
          customToast('Saved Successfully!', 'success');
        }
      });
    }
  };

  const setEditingFalse = () => {
    setAccidentEditing(false);
    setDriverEditing(false);
    setVehicleEditing(false);
    setUnsavedChanges(false);
  };

  const handleDelete = () => {
    deleteClaim(id).then(() => history.goBack());
  };

  const handleCancel = goBack => {
    if (goBack) {
      history.goBack();
      return;
    }
    if (id && (accidentEditing || driverEditing || vehicleEditing)) {
      setEditingFalse();
    } else if (unsavedChanges && !saveChangesModal) {
      setSaveChangesModal(true);
    } else {
      history.goBack();
    }
  };

  const print = () => {
    printClaim(id).then(response => window.open(response, '_blank'));
  };

  const accidentInfoComplete =
    !(!accidentInfo.groupId ||
    !accidentInfo.incidentDate ||
    !accidentInfo.claimNotificationOther ||
    accidentInfo.isCompanyAddress === 'no'
      ? !accidentInfo.streetAddress ||
        !accidentInfo.city ||
        !accidentInfo.state ||
        !accidentInfo.zip
      : !accidentInfo.isCompanyAddress) &&
    !!accidentInfo.hasInjuredPassengers &&
    !!accidentInfo.vehiclesInvolved &&
    !!accidentInfo.incidentDescription;

  const vehicleInfoComplete = !(
    !vehicleInfo.primaryVehicleDamaged ||
    !vehicleInfo.primaryVehicleDrivable ||
    !vehicleInfo.vehicleOwnershipTypeCode ||
    !vehicleInfo.typeOfVehicle ||
    !vehicleInfo.year ||
    !vehicleInfo.make ||
    !vehicleInfo.model
  );

  const driverInfoComplete = !!driverInfo.sufferedBodilyInjuryIndicator;

  const canSubmit =
    accidentInfoComplete && driverInfoComplete && vehicleInfoComplete;

  const isReadOnly = permissions?.find(p => p.userId === myUserId)?.readOnly;

  const header = (
    <Header
      title={activeIncident?.incidentNumber}
      section="Auto Claim"
      pageActionOptions={[
        {
          label: 'Permissions',
          visible: !creating && !isReadOnly,
          color: 'blueOutline',
          onClick: () => history.push(`/app/permissions/Claim/${id}`)
        },
        {
          label: 'Return to Incident',
          visible: true,
          color: 'blueOutline',
          onClick: () =>
            dispatch(setActiveIncidentRequest(incidentId ?? activeIncident._id))
        },
        {
          label: 'Download Claim',
          visible: isSubmitted,
          color: 'greenOutline',
          onClick: print
        },
        {
          label: 'Delete Claim',
          visible: !creating && isSubmitted && !isReadOnly,
          color: 'redOutline',
          onClick: handleDelete
        }
      ]}
      clickBack={() =>
        unsavedChanges ? setSaveChangesModal(true) : history.goBack()
      }
      needsSaved={unsavedChanges}
    />
  );

  const footer = (
    <SubmitSaveForLaterCancelFooter
      submitButtonClick={() =>
        canSubmit ? setSubmitModal(true) : setMissingModalOpen(true)
      }
      saveButtonClick={() => handleSubmit(false)}
      cancelButtonClick={handleCancel}
      onMouseEnter={() => setMissingRequired(true)}
    />
  );

  const left = (
    <LeftNav
      items={[
        {
          value: 0,
          label: isSubmitted ? 'Overview' : 'Instructions',
          complete: true
        },
        {
          value: 1,
          label: 'Accident Information',
          complete: accidentInfoComplete
        },
        {
          value: 2,
          label: 'Vehicle Information',
          complete: vehicleInfoComplete
        },
        {
          value: 3,
          label: 'Driver Information',
          complete: driverInfoComplete
        }
      ]}
    />
  );

  return (
    <HeaderAndFooter
      Header={header}
      Footer={footer}
      Left={left}
      showFooter={!isSubmitted && !isReadOnly}
    >
      {isSubmitted ? (
        <ClaimCard
          name={0}
          claimSection={overviewInfo}
          title="Overview"
          viewOnly
        />
      ) : (
        <InstructionCard name={0} />
      )}
      <ClaimCard
        name={1}
        editing={creating || accidentEditing}
        viewOnly={isReadOnly}
        claimSection={accidentInfo}
        onChange={values => {
          setAccidentInfo(values);
          setUnsavedChanges(true);
        }}
        title="Accident Information"
        openEdit={setAccidentEditing}
        isSubmitted={isSubmitted}
        missingRequired={missingRequired}
        type="auto"
      />
      <ClaimCard
        name={2}
        editing={creating || vehicleEditing}
        viewOnly={isReadOnly}
        claimSection={vehicleInfo}
        onChange={values => {
          setVehicleInfo(values);
          setUnsavedChanges(true);
        }}
        title="Vehicle Information"
        openEdit={setVehicleEditing}
        isSubmitted={isSubmitted}
        missingRequired={missingRequired}
      />
      <ClaimCard
        name={3}
        editing={creating || driverEditing}
        viewOnly={isReadOnly}
        claimSection={driverInfo}
        onChange={values => {
          setDriverInfo(values);
          setUnsavedChanges(true);
        }}
        title="Driver Information"
        openEdit={setDriverInfo}
        isSubmitted={isSubmitted}
        missingRequired={missingRequired}
      />
      <SaveChangesModal
        onRequestClose={() => setSaveChangesModal(false)}
        isOpen={saveChangesModal}
        submitActions={() => handleCancel(true)}
      />
      <DeleteItemModal
        onRequestClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        submitActions={handleDelete}
        hideCustomTemplate
        what="Claim"
      />
      <Modal
        title="Submit Claim"
        titleClassName="blueHeader"
        isOpen={sumbitModal}
        submitButtonColor="blue"
        submitButtonText="Submit"
        onRequestClose={() => setSubmitModal(false)}
        submitActions={() => handleSubmit(true)}
      >
        <div className={styles.text}>
          Once you submit the claim the data will no longer update within the
          incident or for your insurance.
        </div>
      </Modal>
      <MissingRequiredModal
        isOpen={missingModalOpen}
        onRequestClose={() => setMissingModalOpen(false)}
        subject="WC Claim"
      />
    </HeaderAndFooter>
  );
}
