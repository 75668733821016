import React, { Component } from 'react';

import { EmployeeDropdown } from '../inputs/Dropdown';
import Card from '../Card';
import Signature from '../inputs/Signature';

import styles from './signatureCard.module.scss';

export default class SignatureCard extends Component {
  render() {
    const {
      name,
      header,
      permissions,
      dropdownName,
      onUserChange,
      userId,
      userDisabled,
      disabled,
      currentValue,
      testID,
      wide,
      touched,
      employees,
      missingRequired,
      customSignatureText,
      ...props
    } = this.props;

    return (
      <Card showHeader title={header} name={name} wide={wide}>
        <div className={styles.signatureCard}>
          {dropdownName && (
            <EmployeeDropdown
              testID={testID}
              fieldLabel={dropdownName}
              permissions={permissions}
              onChange={onUserChange}
              currentValue={userId}
              disabled={userDisabled}
              searchable
              name="approver"
              touched={missingRequired && !userId}
              employees={employees}
            />
          )}
          {currentValue || !disabled ? (
            <div className="input">
              {customSignatureText ? (
                <label className={styles.label}>{customSignatureText}</label>
              ) : null}
              <Signature
                currentValue={currentValue}
                disabled={disabled}
                touched={missingRequired && !currentValue}
                {...props}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Card>
    );
  }
}
