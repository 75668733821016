import React, { Component } from 'react';
import { EmployeeDropdown, FieldRenderer } from '../../inputs';
import { IReportDatePicker } from '../../inputs/DateTimePicker';
import FileDownloader from '../../FileDownloader';
import FileUploader from '../../FileUploader';
import HierarchySelector from '../../HierarchySelector';
import { Dropdown } from '../../inputs';

import './index.css';

const findAnswer = (fields, questionId) => {
  const fieldIndex = fields.findIndex(field => field._id === questionId);
  if (fieldIndex > -1) {
    return fields[fieldIndex].answer;
  }

  return '';
};

export default class CustomDocumentGenerator extends Component {
  handleAnswer = (field, answer) => {
    field.answer = answer;

    const fields = this.props.fields;
    fields[field._id] = field;
    this.props.answerQuestion(fields);
  };

  render() {
    const {
      fields,
      disabled,
      activeCustomDoc,
      handleExpires,
      addAttachment,
      deleteAttachment,
      activeCompanyId,
      loading,
      hasAccess,
      attachments,
      setDocument,
      missingRequired,
      folders
    } = this.props;

    return (
      <div className="customDocumentGenerator">
        <h2 className="customDocumentGenerator-header">
          {activeCustomDoc?.label}
        </h2>
        {activeCustomDoc?.shouldHaveExpiredDate || activeCustomDoc?.expires ? (
          <IReportDatePicker
            fieldLabel="Expires"
            onChange={handleExpires}
            currentValue={activeCustomDoc?.expires}
            disabled={disabled}
            name="expirationDate"
          />
        ) : null}
        <HierarchySelector
          multi
          groupDisabled={disabled}
          projectDisabled={disabled}
          groupId={activeCustomDoc.groupIds}
          projectId={activeCustomDoc.projectIds}
          onGroupChange={values => setDocument({ groupIds: values })}
          onProjectChange={values => setDocument({ projectIds: values })}
          alwaysShowGroupDropdown
          alwaysShowProjectDropdown
          groupTouched={missingRequired && !activeCustomDoc?.groupIds?.length}
        />
        <Dropdown
          options={folders}
          fieldLabel="Add to Folder:"
          currentValue={activeCustomDoc?.documentFolderId}
          onChange={v => setDocument({ documentFolderId: v })}
          disabled={disabled}
          searchable
          allowUnset
        />
        {activeCustomDoc.hasInvolvedEmployee ? (
          <EmployeeDropdown
            searchable
            group={
              activeCustomDoc.groupIds?.length > 0
                ? activeCustomDoc.groupIds
                : null
            }
            currentValue={activeCustomDoc.involvedEmployee}
            onChange={values => setDocument({ involvedEmployee: values })}
            fieldLabel="Involved Employee:"
            disabled={disabled}
            isRequired
            touched={missingRequired && !activeCustomDoc.involvedEmployee}
            filterChoices={[
              {
                label: 'Include Former Employees',
                value: 'inactive'
              }
            ]}
          />
        ) : null}
        {fields &&
          fields
            .filter(f => !f?.legacy)
            .map((field, index) => (
              <FieldRenderer
                key={index}
                fieldLabel={field.label}
                isRequired={field.required}
                name={field.label}
                currentValue={findAnswer(fields, field._id)}
                onChange={v => this.handleAnswer(field, v)}
                disabled={disabled}
                options={field.options}
                type={field.type}
                bareValues={false}
                alphabetize
                ownerType="customDocument"
                missingRequired={missingRequired}
              />
            ))}
        <div>
          <h4>Attachments</h4>
          {attachments?.map((attachment, attachmentIndex) => (
            <FileDownloader
              attachment={attachment}
              key={attachmentIndex}
              attachmentIndex={attachmentIndex}
              deleteAttachment={deleteAttachment}
              disabled={!hasAccess || disabled}
              ownerType="documents"
            />
          ))}
        </div>
        {!disabled && (
          <FileUploader
            activeCompanyId={activeCompanyId}
            addAttachment={addAttachment}
            loading={loading}
            ownerId={activeCustomDoc?._id ?? 'pending'}
            ownerType="documents"
          />
        )}
      </div>
    );
  }
}
