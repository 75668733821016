import React, { useState, useEffect } from 'react';
import { fetchPendingApprovals, updateAdminApprovals } from '../../api/v4';
import history from '../../history';
import Header from '../../components/Header';
import HeaderAndFooter from '../../components/HeaderAndFooter';
import Card from '../../components/Card';
import RadioButtons from '../../components/inputs/RadioButtons';
import SaveChangesModal from '../../components/Modal/saveChangesModal';
import { SaveCancelFooter } from '../../components/Footer';

import styles from './adminApproval.module.scss';

export default function AdminApproval() {
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [unsavedChangesModalOpen, setHasUnsavedChangesModalOpen] =
    useState(false);

  useEffect(() => {
    fetchPendingApprovals().then(r => setPendingApprovals(r ?? []));
  }, []);

  const handleApproveOrDeny = (v, i) => {
    const approvals = [...pendingApprovals];
    const approval = { ...approvals[i], isApproved: v };
    approvals.splice(i, 1, approval);
    setHasUnsavedChanges(true);
    setPendingApprovals(approvals);
  };

  const header = (
    <Header
      title="Pending Approval(s)"
      clickBack={() =>
        hasUnsavedChanges
          ? setHasUnsavedChangesModalOpen(hasUnsavedChanges)
          : history.goBack()
      }
      needsSaved={hasUnsavedChanges}
    />
  );

  const footer = (
    <SaveCancelFooter
      saveButtonClick={() =>
        updateAdminApprovals({ pendingApprovals }).then(() => history.goBack())
      }
      cancelButtonClick={() =>
        hasUnsavedChanges
          ? setHasUnsavedChangesModalOpen(hasUnsavedChanges)
          : history.goBack()
      }
      saveButtonText="Submit"
      saveButtonDisabled={
        pendingApprovals?.length
          ? pendingApprovals?.every(
              approval => approval?.isApproved === undefined
            )
          : true
      }
    />
  );

  return (
    <>
      <HeaderAndFooter Header={header} Footer={footer}>
        <Card
          className={styles.instructionsCard}
          showHeader
          title="Instructions"
          name="adminApprovalsInstructions"
        >
          <p>
            You do not need to approve or deny all pending Admins right now. You
            can return to this list and complete it later.
          </p>
          <b>Important: </b>Once an Admin's status is approved or denied, they
          will be removed from this list.
        </Card>
        <Card
          showHeader
          title="Admin(s) Awaiting Approval"
          name="adminApprovals"
        >
          {pendingApprovals?.map((a, i) => {
            return (
              <RadioButtons
                fieldLabel={`${a.name} - ${a.companies}`}
                options={[
                  {
                    label: 'Approve',
                    value: true
                  },
                  {
                    label: 'Deny',
                    value: false
                  }
                ]}
                currentValue={pendingApprovals?.[i]?.isApproved}
                onChange={v => handleApproveOrDeny(v, i)}
              />
            );
          })}
        </Card>
      </HeaderAndFooter>
      <SaveChangesModal
        isOpen={unsavedChangesModalOpen}
        onRequestClose={() => setHasUnsavedChangesModalOpen(false)}
        submitActions={() => history.goBack()}
        savingWhat="Admin Approval(s)"
      />
    </>
  );
}
