import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateDefaultPassword } from '../../../api/v4';
import { updatePasswordRequest } from '../../../actions/user';
import { commonName, canSubmitPassword } from '../../../utils/formValidation';
import { USER_ROLES } from '../../../constants/constants';
import { getLoggedInUser } from '../../../selectors/users';
import Button from '../../../components/inputs/Button';
import Card from '../../../components/Card';
import ChangePasswordForm from '../../../forms/ChangePasswordForm';
import ChangeDefaultPasswordForm from '../../../forms/ChangeDefaultPasswordForm';
import { InputRow, TwoColumn } from '../../../components/inputs';
import Modal from '../../../components/Modal';
import Textbox from '../../../components/inputs/Textbox';
import UserBadge from '../../../components/UserBadge';

import styles from './userprofile.module.scss';

export default function UserProfile({
  isEditing,
  firstName,
  lastName,
  username,
  email,
  phoneNumber,
  setFirstName,
  setLastName,
  setEmail,
  setPhoneNumber,
  setUsername,
  userProfileImage,
  updateUserProfileImage,
  usernameExists,
  missingRequired
}) {
  const activeUser = useSelector(getLoggedInUser);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [openDefaultPasswordModal, setOpenDefaultPasswordModal] =
    useState(false);
  const [pwMissingRequired, setPwMissingRequired] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const handleChangePassword = values => {
    dispatch(updatePasswordRequest(values));
    handleCloseModal();
  };

  const handleChangeDefaultPassword = values => {
    updateDefaultPassword(values).then(r => handleCloseModal());
  };

  const handleCloseModal = () => {
    setNewPassword(null);
    setConfirmPassword(null);
    setPwMissingRequired(false);
    setOpenModal(false);
    setOpenDefaultPasswordModal(false);
  };

  return (
    <Card title="User Info" showHeader>
      <TwoColumn>
        <InputRow className={styles.userAccessLevel}>
          {USER_ROLES?.find(role => role.value === activeUser.accessLevel)
            ?.label ?? undefined}
          {activeUser.isAccountOwner ? ' (Account Owner)' : undefined}
        </InputRow>
        <InputRow className={styles.right}>
          {activeUser.isAccountOwner && (
            <Button
              color="blue"
              text="Change Users Default Password"
              onClick={() => setOpenDefaultPasswordModal(true)}
            />
          )}
          <Button
            testID="changePassword"
            color="blue"
            text="Change Password"
            onClick={() => setOpenModal(true)}
          />
        </InputRow>
      </TwoColumn>
      <TwoColumn className={styles.twoColumn}>
        <UserBadge
          userId={activeUser?._id}
          size="xx-large"
          showImage
          onClick={() => (isEditing ? inputRef.current.click() : null)}
          data={userProfileImage}
        />
        <input
          type="file"
          name="myfile"
          onChange={updateUserProfileImage}
          className={styles.fileInput}
          ref={inputRef}
        />
        <div>
          <InputRow className={styles.inputRow}>
            <Textbox
              fieldLabel="First Name:"
              className={styles.nameBox}
              currentValue={firstName}
              disabled={!isEditing}
              onChange={setFirstName}
              touched={(!firstName && missingRequired) || commonName(firstName)}
              error
              isRequired
              errorMessage={commonName(firstName) ? 'Invalid Characters' : null}
            />
            <Textbox
              fieldLabel="Last Name:"
              className={styles.nameBox}
              currentValue={lastName}
              disabled={!isEditing}
              onChange={setLastName}
              touched={(!lastName && missingRequired) || commonName(lastName)}
              error
              isRequired
              errorMessage={commonName(lastName) ? 'Invalid Characters' : null}
            />
          </InputRow>
          <Textbox
            fieldLabel="Username:"
            className={styles.nameBox}
            currentValue={username}
            disabled={!isEditing}
            onChange={setUsername}
            touched={(!username?.trim() && missingRequired) || usernameExists}
            errorMessage={usernameExists ? 'Username already in use' : ''}
            error
            isRequired
          />
          <Textbox
            fieldLabel="Contact Info (at least one):"
            currentValue={email}
            placeholder="Email"
            disabled={!isEditing}
            onChange={setEmail}
            isRequired
          />
          <Textbox
            currentValue={phoneNumber}
            placeholder="Phone Number"
            disabled={!isEditing}
            onChange={v => setPhoneNumber(v)}
            isRequired
          />
        </div>
      </TwoColumn>

      {openModal && (
        <Modal
          testID="changePassword"
          title="Change Password"
          titleClassName="blueHeader"
          isOpen={openModal}
          submitButtonColor="green"
          onRequestClose={handleCloseModal}
          onMouseEnter={() =>
            setPwMissingRequired(
              !canSubmitPassword(newPassword, confirmPassword)
            )
          }
          submitTooltip={
            !canSubmitPassword(newPassword, confirmPassword)
              ? 'Invalid Password'
              : null
          }
          submitActions={() =>
            canSubmitPassword(newPassword, confirmPassword)
              ? handleChangePassword({
                  new_password: newPassword,
                  confirm_password: confirmPassword
                })
              : null
          }
        >
          <ChangePasswordForm
            newPassword={newPassword}
            confirmPassword={confirmPassword}
            setNewPassword={setNewPassword}
            setConfirmPassword={setConfirmPassword}
            missingRequired={pwMissingRequired}
          />
        </Modal>
      )}
      {openDefaultPasswordModal && (
        <Modal
          title="Change Users Default Password"
          titleClassName="blueHeader"
          isOpen={openDefaultPasswordModal}
          submitButtonColor="green"
          onRequestClose={handleCloseModal}
          onMouseEnter={() =>
            setPwMissingRequired(
              !newPassword?.length || newPassword !== confirmPassword
            )
          }
          submitTooltip={pwMissingRequired ? 'Invalid Password' : null}
          submitActions={() =>
            newPassword?.length && newPassword === confirmPassword
              ? handleChangeDefaultPassword({
                  new_default_password: newPassword,
                  confirm_default_password: confirmPassword
                })
              : null
          }
        >
          <ChangeDefaultPasswordForm
            newPassword={newPassword}
            confirmPassword={confirmPassword}
            setNewPassword={setNewPassword}
            setConfirmPassword={setConfirmPassword}
            missingRequired={pwMissingRequired}
          />
        </Modal>
      )}
    </Card>
  );
}
