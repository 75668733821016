export const EMPLOYEMENT_STATUS = [
  { label: 'Apprentice/Full Time', value: 'AF' },
  { label: 'Apprentice/Part Time', value: 'AP' },
  { label: 'Disabled', value: 'DS' },
  { label: 'Full Time', value: 'FT', ireportOption: 1 },
  { label: 'Not Employed', value: 'NE' },
  { label: 'On Strike', value: 'OS' },
  { label: 'Part Time', value: 'PT', ireportOption: 2 },
  { label: 'Per Diem', value: 'PD' },
  { label: 'Piece Worker', value: 'PW' },
  { label: 'Retired', value: 'RT' },
  { label: 'Seasonal', value: 'SL', ireportOption: 14 },
  { label: 'Terminated', value: 'TM' },
  { label: 'Volunteer', value: 'VO' },
  { label: 'Other', value: 'UK' }
];

export const INJURY_LOCATIONS = [
  { label: 'Index Finger', value: 'I' },
  { label: 'Middle Finger', value: 'M' },
  { label: 'Ring Finger', value: 'R' },
  { label: 'Little Finger', value: 'L' },
  { label: '1st Toe', value: '1' },
  { label: '2nd Toe', value: '2' },
  { label: '3rd Toe', value: '3' },
  { label: '4th Toe', value: '4' }
];

export const TYPE_OF_MEDICAL_TREATMENT = [
  { label: 'No medical treatment', value: 0, ireportOption: 0 },
  {
    label: 'First Aid - minor onsite remedies by employer',
    value: 1,
    ireportOption: 1
  },
  {
    label: 'MD office/Walk-In Clinic - minor treatment, possible diagnostics',
    value: 2,
    ireportOption: 2
  },
  {
    label: 'Emergency room - treated and released',
    value: 3,
    ireportOption: 3
  },
  {
    label: 'Hospitalization greater than 24 hours',
    value: 4
  },
  { label: 'Unknown', value: 9 }
];

export const TYPE_OF_EXPECTED_MEDICAL_TREATMENT = [
  { label: 'Medical provider or clinic visit', value: 'M' },
  { label: 'Emergency Room', value: 'E' },
  { label: 'Hospitalization or Surgery', value: 'H' },
  { label: 'Other (Telemedicine, Virtual, etc.)', value: 'O' },
  { label: 'Unknown', value: 'U' }
];

export const LANGUAGES = [
  { label: 'English', value: '040' },
  { label: 'Spanish', value: '147' }
];

export const RETURN_TO_WORK_INFO = [
  { label: 'Non-Fatal', value: 'NONFATAL' },
  { label: 'Fatal', value: 'FATAL' },
  {
    label: 'Not Returned to Work with Return Date',
    value: 'NOTRETURNEDTOWORKWITHDATE'
  },
  {
    label: 'Not Returned to Work without Return Date',
    value: 'NOTRETURNEDTOWORKWITHOUTDATE'
  },
  { label: 'Returned to Work with Lost Time', value: 'RETURNEDTOWORKLOSTTIME' },
  {
    label: 'Returned to Work with No Lost Time',
    value: 'RETURNEDTOWORKNOLOSTTIME'
  }
];

export const OWNERSHIP_TYPE_CODE = [
  { label: 'Owned', value: 'O' },
  { label: 'Rented or Borrowed', value: 'N' },
  { label: 'Customer Vehicle', value: 'M' },
  { label: 'Unknown', value: 'U' }
];

export const VEHICLE_TYPE = [
  {
    label: 'Automobile (Passenger Vehicle, Pick-up, SUV, Van, etc)',
    value: 'A'
  },
  {
    label: 'Commerical Heavy Equipment (Bus, Dump Truck, Crane, Back Hoe, etc)',
    value: 'B'
  },
  { label: 'Special Auto (Motorcycle, Winnebago, Snowmobile)', value: 'C' },
  { label: 'Not Listed', value: 'D' },
  { label: 'Unknown', value: 'U' }
];

export const DRIVER_INJURY_TYPE = [
  { label: 'Fatality', value: '00' },
  { label: 'Head Injury', value: '07' },
  { label: 'Fracture', value: '28' },
  { label: 'Other', value: '59' }
];

export const SINGLE_VEHICLE_ACCIDENT_TYPE = [
  { label: 'Accident involving Animal', value: 'A' },
  { label: 'Accident with a Pedestrian or Bicyclist', value: '2' },
  { label: 'Accident with an Object', value: 'O' },
  { label: 'Damage from Flooding', value: 'E' },
  { label: 'Hit and Run Accident', value: '7' },
  { label: 'Damage from Fire', value: '5' },
  { label: 'Theft of Motor Vehicle or Motor Vehicle Parts', value: '6' },
  { label: 'Rollover Accident', value: '9' },
  { label: 'Vandalism', value: 'K' },
  { label: 'Weather (Non Hail)', value: 'L' },
  { label: 'Hail Damage', value: 'G' },
  { label: 'All Other Damages', value: '3' }
];
