import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { getActiveCompany } from '../../selectors/company';
import { loadTask, sendReminder } from '../../actions/tasks';
import { getRemindedAssignments } from '../../selectors/tasks';
import { loadSafetyWalk } from '../../actions/safetyWalks';
import {
  setActiveIncidentRequest,
  fetchIncidentBasicsRequest
} from '../../actions/incidents';
import classnames from 'classnames/bind';
import Loading from '../Loading';
import UserBadge from '../UserBadge';
import Card from '../Card';
import Button from '../inputs/Button';
import history from '../../history';
import Pagination from '../Pagination';
import { useCardData } from './useCardData';
import { noBubble } from '../../utils/events';
import { TRAINING_TYPES } from '../../constants/constants';

import styles from './headerCards.module.scss';
const bStyles = classnames.bind(styles);
const perPage = 4;

let ToDoCard = ({
  card,
  className,
  company,
  loadTask,
  loadIncident,
  loadSafetyWalk,
  sendReminder,
  right,
  reminders,
  remindedAssignments,
  fetchIncidentBasics
}) => {
  const [page, setPage] = useState(0);
  const data = useCardData(card);

  const sliced = data?.slice(page * perPage, (page + 1) * perPage);
  return (
    <Card
      className={classnames(styles.card, className)}
      title={card?.name}
      showHeader
      wide
    >
      {data === undefined ? (
        <Loading />
      ) : data.length === 0 ? (
        <div className={styles.empty}>No items on To-Do List</div>
      ) : (
        sliced.map(task => {
          let pastDue = false;
          const dueDate = moment(task.dueDate);
          if (task?.dueDate) {
            pastDue = moment().isAfter(dueDate);
          }
          let group = company?.groups?.find(
            g => g._id.toString() === task.groupId
          );
          let project = group?.projects?.find(
            p => p._id.toString() === task.projectId
          );
          let name = group?.name;
          if (project) {
            name += ` - ${project.name}`;
          }
          let classes = {
            task: true,
            pastDue
          };
          let type;
          let onClick;
          let labels = [];
          switch (task.type) {
            case 'report':
              onClick = () => loadIncident(task.ownerId);
              type = 'Report Component';
              labels.push(<span key={labels.length}>{task.label}</span>);
              break;
            case 'qa':
              onClick = () =>
                history.push(`/app/audits/quality/perform/${task._id}`);
              type = 'Quality Audit';
              labels.push(
                <span className={styles.long}>{task.description}</span>
              );
              labels.push(<span key={labels.length}>{task.label}</span>);
              break;
            case 'loto':
              onClick = () =>
                history.push(`/app/audits/loto/perform/${task._id}`);
              type = 'Lockout/Tagout';
              labels.push(
                <span className={styles.long}>{task.description}</span>
              );
              labels.push(<span key={labels.length}>{task.label}</span>);
              break;
            case 'ra':
              onClick = () =>
                history.push(`/app/audits/safety/perform/${task._id}`);
              type = 'Safety Audit';
              labels.push(
                <span className={styles.long}>{task.description}</span>
              );
              labels.push(<span key={labels.length}>{task.label}</span>);
              break;
            case 'swAction':
              onClick = () => loadSafetyWalk(task._id);
              labels.push(<span key={labels.length}>{task.description}</span>);
              type = 'Review Hazards';
              break;
            case 'raAction':
              onClick = () =>
                history.push(`/app/audits/safety/summary/${task._id}`);
              labels.push(
                <span
                  key={labels.length}
                  className={styles.long}
                  title={task.description}
                >
                  {task.description}
                </span>
              );
              type = 'Review Unacceptable Items';
              break;
            case 'trainingAssignment':
              type = 'Assigned Training';
              onClick = () => history.push(`/app/training/perform/${task._id}`);
              labels.push(<span key={labels.length}>{task.label}</span>);
              labels.push(
                <span
                  key={labels.length}
                  className={styles.long}
                  title={
                    TRAINING_TYPES.find(t => t.value === task.trainingType)
                      ?.label ?? 'Digital'
                  }
                >
                  {TRAINING_TYPES.find(t => t.value === task.trainingType)
                    ?.label ?? 'Digital'}
                </span>
              );
              break;
            case 'trainingCourse':
              type = 'Training Course';
              onClick = () =>
                history.push(`/app/trainingCourses/view/${task._id}`);
              labels.push(<span key={labels.length}>{task.label}</span>);
              labels.push(
                <span
                  key={labels.length}
                  className={styles.long}
                  title={task.label}
                >
                  {`${task.numTrainings} Training${
                    task.numTrainings > 1 ? 's' : ''
                  }`}
                </span>
              );
              break;
            case 'trainingApproval':
              type = 'Approve Training';
              onClick = () =>
                history.push({
                  pathname: `/app/training/approve/${task._id}`,
                  state: {
                    isApproving: true
                  }
                });
              labels.push(<span key={labels.length}>{task.label}</span>);
              break;
            case 'trainingAssigned':
              type = 'Training';
              onClick = () => history.push(`/app/training/approve/${task._id}`);
              labels.push(<span key={labels.length}>{task.label}</span>);
              break;
            case 'adminTraining':
              type = 'Administer In Person Training';
              onClick = () =>
                history.push(`/app/training/administer/${task._id}`);
              labels.push(<span key={labels.length}>{task.label}</span>);
              break;
            case 'attendTraining':
              type = 'Attend In Person Training';
              onClick = () =>
                history.push(`/app/training/perform/${task.parentId}`);
              labels.push(<span key={labels.length}>{task.label}</span>);
              break;
            case 'recurrenceApproval':
              type = 'Approve';
              onClick = () => {
                task.incidentId
                  ? loadIncident({
                      _id: task.incidentId,
                      selectedSection: 'Return to Work',
                      selectedTab: 'Closure'
                    })
                  : history.push(`/app/recurrenceContainer/${task._id}`);
              };
              labels.push(
                <span key={labels.length}>
                  {task.label} form:{' '}
                  <UserBadge userId={task.createdBy} nameOnly /> submitted a new
                  form
                </span>
              );
              break;
            case 'transSuperApproval':
            case 'transHrApproval':
              type = 'Approve';
              onClick = () =>
                task.incidentId
                  ? loadIncident({
                      _id: task.incidentId,
                      selectedSection: 'Return to Work',
                      selectedTab: 'Closure'
                    })
                  : history.push(`/app/transitionContainer/${task._id}`);
              labels.push(
                <span key={labels.length}>
                  {task.label} form:{' '}
                  <UserBadge userId={task.createdBy} nameOnly /> submitted a new
                  form
                </span>
              );
              break;
            case 'savedDraft':
              type = 'Continue Draft';
              onClick = () => fetchIncidentBasics(task);
              labels.push(<span className={styles.long}>{task.label}</span>);
              break;
            case 'covidTest':
              type = 'Covid Test';
              onClick = () =>
                history.push(`/app/covidTracking/employeeStatus/${task._id}`);
              labels.push(<span className={styles.long}>{task.label}</span>);
              break;
            case 'witnessStatements':
              type = 'Witness Statement';
              onClick = () =>
                history.push(`/app/witnessStatements/${task._id}`);
              labels.push(<span className={styles.long}>{task.label}</span>);
              break;
            case 'observations':
              type = 'Behavior Observation';
              onClick = () =>
                history.push(`/app/behaviorObservation/container/${task._id}`);
              labels.push(<span className={styles.long}>{task.label}</span>);
              break;
            case 'correctiveAction':
              onClick = () => loadTask(task._id);
              type = 'Corrective Action';
              labels.push(<span key={labels.length}>{task.label}</span>);
              break;
            case 'requiredSignature':
              onClick = () =>
                loadIncident({
                  _id: task._id,
                  returnPath: '/app/dashboard',
                  isSigning: true
                });
              type = 'Signature Required';
              labels.push(<span className={styles.long}>{task.label}</span>);
              break;
            case 'assignedRecurrence':
              onClick = () =>
                history.push(
                  `/app/${
                    task.label.includes('Recurrence')
                      ? 'recurrenceContainer'
                      : 'transitionContainer'
                  }/${task._id}`,
                  {
                    isAssignment: true
                  }
                );
              type = 'Document';
              labels.push(<span className={styles.long}>{task.label}</span>);
              break;
            case 'pendingAdmins':
              type = 'Approve';
              labels.push(<span className={styles.long}>{task.label}</span>);
              onClick = () => history.push('/app/adminApprovals');
              break;
            default:
              onClick = () => loadTask(task._id);
              type = 'Task';
              labels.push(<span key={labels.length}>{task.label}</span>);
              break;
          }
          labels.unshift(
            <span key={labels.length} className={styles.taskType}>
              {type}:
            </span>
          );
          if (name) {
            labels.push(
              <span key={labels.length} className={styles.taskLocation}>
                {name}
              </span>
            );
          }

          const today = new moment();
          let sentReminder = remindedAssignments?.[task._id]
            ? moment(remindedAssignments?.[task._id]).isSame(today, 'day')
            : false;
          let length = task.assignedTo?.length;
          let assignees = task.assignedTo;
          if (length > 5) {
            assignees = assignees.slice(0, 4);
            length = length - 4;
          } else {
            length = undefined;
          }

          return (
            <div className={bStyles(classes)} onClick={onClick} key={task._id}>
              <div className={styles.left}>
                <div className={styles.labels}>{labels}</div>
                {task.assignedTo?.length && (
                  <div className={styles.assignees}>
                    <span className={styles.aText}>Assignees:</span>
                    {assignees.map(a => (
                      <UserBadge key={a} userId={a} showImage hoverName />
                    ))}
                    {length && (
                      <span className={styles.overflow}>+{length} more</span>
                    )}
                  </div>
                )}
              </div>
              {right ? (
                <div className={styles.right}>
                  <div className={styles.dueDate}>
                    <span>{moment().isAfter(dueDate) ? 'Late' : 'Due'}</span>
                    <span>{dueDate.format('MM/DD/YYYY')}</span>
                  </div>
                  {reminders ? (
                    <Button
                      text={sentReminder ? 'Reminder Sent' : 'Send Reminder'}
                      color="blue"
                      disabled={sentReminder}
                      onClick={e => {
                        noBubble(e);
                        sendReminder({
                          assignmentId: task._id,
                          assignmentType: task.type
                        });
                      }}
                      inputClassName={styles.leftSpace}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ) : null}
            </div>
          );
        })
      )}
      <Pagination
        page={page}
        setPage={setPage}
        perPage={perPage}
        count={data?.length}
        className={styles.pagination}
      />
    </Card>
  );
};

const mapStateToProps = state => ({
  company: getActiveCompany(state),
  remindedAssignments: getRemindedAssignments(state)
});

const mapDispatchToProps = dispatch => ({
  loadTask: taskId => dispatch(loadTask(taskId)),
  loadIncident: incidentId => dispatch(setActiveIncidentRequest(incidentId)),
  loadSafetyWalk: swId => dispatch(loadSafetyWalk(swId)),
  sendReminder: taskInfo => dispatch(sendReminder(taskInfo)),
  fetchIncidentBasics: initialReport =>
    dispatch(fetchIncidentBasicsRequest(initialReport))
});

ToDoCard = connect(mapStateToProps, mapDispatchToProps)(ToDoCard);
export { ToDoCard };
export default ToDoCard;
